#other_allowance {
    position: relative;
}

#other_allowance span {
    background-color: #eaecef;
    padding-right: 10px;
}

#other_allowance:after {
    content:"";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.5em;
    border-top: 1px solid black;
    z-index: -1;
}
.custom_display_none{
    display: none;
}
.custom_display_block{
    display: block;
}
/*:after {*/
/*    content:"";*/
/*    display: inline-block;*/
/*    height: 0.5em;*/
/*    vertical-align: bottom;*/
/*    width: 100%;*/
/*    margin-right: -100%;*/
/*    margin-left: 10px;*/
/*    border-top: 1px solid black;*/
/*}*/