.project-file-upload-div{
    height: 154px;
}
.project-file-preview {
    /* border-radius: 5px; */
    /* border: 1px solid #ddd; */
    /* padding: 8px; */
    width: 100%;
    margin-bottom: 5px;
    /* display: flex; */
}
.project-file-drop-zone .file-preview-thumbnails {
    cursor: default;
}
.krajee-default.file-preview-frame {
    margin: 8px;
    border: 1px solid rgba(0,0,0,.2);
    box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
    padding: 6px;
    float: left;
    text-align: center;
}
.btn-file, .file-caption, .file-input, .file-loading:before, .project-file-preview, .file-zoom-dialog .modal-dialog, .krajee-default .file-thumbnail-footer, .krajee-default.file-preview-frame {
    position: relative;
}
.project-file-drop-zone {
    border: 1px dashed #aaa;
    border-radius: 4px;
    text-align: center;
    vertical-align: middle;
    margin: 12px 15px 12px 12px;
    padding: 5px;
}
.krajee-default.file-preview-frame .kv-file-content {
    width: 100px;
    height: 100px;
}
.krajee-default .file-preview-other-frame {
    display: flex;
    align-items: center;
    justify-content: center;
}
.file-caption-info{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80px;
    margin: 0 10px;
}
.project-upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 112px;
    text-align: -webkit-center;
    text-align: center;
}

.project-custom-upload-btn {
    width: 112px;
    border: 1px solid gray;
    color: gray;
    background-color: white;
    padding: 2px 18px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: bold;
}

.project-upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
