@import "./global.scss";

.dialog-backdrop {
  .modal-open .modal {
    overflow: hidden;
  }
}

.dialog-modal {
  .modal-content {
    width: 27.625rem !important;
    min-width: none;
    // min-height: 20.5625rem;
    height: fit-content;
    margin: auto;
    max-width: calc(100vw - 2rem) !important;
    max-height: none !important;
    text-align: center;
    font-size: 0.875rem !important;
    border: none;
    border-radius: 0.5rem;

    .modal-header {
      border: none;
      padding: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1.5rem;
      padding-bottom: 1rem;

      .h4 {
        font-weight: 600 !important;
        font-size: 1.25rem;
      }
    }

    .modal-body {
      padding: 0 3rem 0 3rem;
      font-size: 1rem !important;
      color: $base-grey-80;
      font-size: 1rem;
    }

    .modal-footer {
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem;
      gap: 1rem;
      flex-wrap: nowrap;

      .btn {
        // padding: 0.875rem 1.34375rem;
        flex: 1;
        flex-basis: 1;
        height: 3rem;
        font-weight: 600;
        border-radius: 0.5rem;
        margin: 0;
      }

      .btn-danger {
        background: $tersiary-red !important;
        border-color: $tersiary-red !important;
      }
    }
  }
}