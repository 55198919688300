@import "./global.scss";

.upload-to {
  font-size: 0.875rem !important;
  width: 30.625rem !important;
  max-width: calc(100vw - 1rem);


  .modal-content {
    border: none;
    box-shadow: none
  }

  * {

    @extend .sleek-scrollbar;
    @extend .white-scroll;
  }

  .modal-header {
    border: none;
    line-height: 0;

    h3 {
      font-weight: 600;
      font-size: 1rem !important;
      margin: 0;
    }
  }

  .modal-body {
    padding-right: 2rem;

    .form-control {
      height: 3.125rem;
      border: 1px solid #A7B9CB;
      border-radius: 0.5rem;

    }

    .crumbs {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0rem;
      font-size: 0.875rem !important;

      span {
        margin: 0;
        display: flex;
        align-items: center;
        color: #677888;
        gap: 0rem;
      }

      span:last-of-type {
        color: #000;
      }


    }

    .files-holder {
      height: 23.5rem;
      overflow-y: auto;
      overflow-x: hidden;



      &.folder {
        height: 17rem;
      }

      .btn {
        display: flex;
        width: 100%;
        justify-content: start;
        align-items: center;
        gap: 1.5rem;
        padding: 0;
        height: 4rem !important;
        padding: 0.5rem;
        max-height: unset;
        font-weight: 500;
        font-size: 1rem !important;

        &:hover {
          background-color: $primary-light-blue-20;
        }
      }

      .empty {
        .btn {
          font-size: 0.875rem !important;
          gap: 0.5rem;
          height: unset !important;
          width: unset !important;
          margin-top: 0.5rem;

        }
      }
    }
  }

  .modal-footer {
    border: none;
    box-shadow: 0px -4px 40px 0px #0000000F;

    .btn-c {
      height: 3rem;
      align-items: center;
      border-radius: 0.5rem;
      // line-height: 0;
      margin: 0;
      width: 10rem;
      max-width: unset;
      min-width: unset;
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      font-size: 0.875rem !important;

      &.save-folder {
        width: 13.125rem;
      }
    }
  }
}