.search-gallery-files {
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  width: 30%;
  font-size: 13px;
  padding: 5px;
}

.select-tag {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #f3f7f9;
  background-image: none;
}
/* Remove IE arrow */
.select-tag::-ms-expand {
  display: none;
}
/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 11.5em;
  height: 2em;
  line-height: 1.6;
  background: #f3f7f9;
  overflow: hidden;
  border-radius: 0.25em;
  color: #9babb4;
}
.select-tag {
  flex: 1;
  padding: 0 0.8em;
  cursor: pointer;
  opacity: 0.6;
  font-size: 14px;
}
/* Arrow */
.select::after {
  content: "\25BE";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0.4em;
  background: #f3f7f9;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
  font-size: 19px;
  font-weight: bold;
}
/* Transition */
.select:hover::after {
  //   color: #f39c12;
}

.file-gallery-container {
  height: 320px;
  overflow-y: auto;
  padding: 0 10px;
  margin-top: 30px;
}

.file-gallery-content {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  column-gap: 5%;
  row-gap: 15px;
  overflow: hidden;

  .file-gallery-single {
    height: 80px;
    background-color: #f7f7f7;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;

    > svg {
      height: 24px;
      width: 24px;
      margin-right: 5px;
    }

    > .file-gallery-name {
      font-size: 12px;
	  opacity: 0.8;
      font-weight: 100;
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    > .file-gallery-link {
      font-size: 13px;
      font-weight: 100;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }

  .file-gallery-image {
    height: 80px;
    border: 1px solid rgb(241, 241, 241);
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.gallery-preview-modal {
  width: 70% !important;

  .gallery-preview-content {
    // height: 80% !important;
    height: 580px;
  }
}
