* {
  border-color: #CED8E1; }

body {
  overflow: hidden; }

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.border {
  color: #A7B9CB;
  border-color: #A7B9CB; }

.global-backdrop,
.modal-backdrop {
  background: rgba(14, 29, 47, 0.54);
  /*&+.modal-backdrop {
    display: none;
  }*/ }
  .global-backdrop.show,
  .modal-backdrop.show {
    opacity: 1; }
  .global-backdrop.max-z-index,
  .modal-backdrop.max-z-index {
    z-index: 10050; }
    .global-backdrop.max-z-index + .modal,
    .modal-backdrop.max-z-index + .modal {
      z-index: 10052 !important; }
  .global-backdrop.max-z-index-2,
  .modal-backdrop.max-z-index-2 {
    z-index: 10060; }
    .global-backdrop.max-z-index-2.blur,
    .modal-backdrop.max-z-index-2.blur {
      backdrop-filter: blur(1px); }
    .global-backdrop.max-z-index-2 + .modal,
    .modal-backdrop.max-z-index-2 + .modal {
      z-index: 10062 !important; }

.picker-dialog-bg {
  z-index: 20000 !important; }

.picker-dialog {
  z-index: 20001 !important; }

/*.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  @extend .sleek-scrollbar;
}
*/
textarea {
  resize: none; }

.Toastify .Toastify__toast-container {
  width: 26.3125rem;
  z-index: 10080 !important; }

.Toastify .Toastify__toast-body {
  padding: 0;
  margin: 0; }
  .Toastify .Toastify__toast-body .content .icon {
    margin-right: 1.2rem; }
  .Toastify .Toastify__toast-body .content .title {
    font-size: 1rem; }
  .Toastify .Toastify__toast-body .content .message {
    color: #A7B9CB;
    font-size: 0.875rem;
    word-break: break-all; }

.Toastify .Toastify__toast.Toastify__toast--success {
  background: #2C2C2C;
  margin: 0;
  padding: 1rem 1.1875rem;
  min-height: auto;
  width: 26.3125rem;
  margin-bottom: 5px;
  border-radius: 0.375rem; }

.rdtPicker {
  right: 0 !important;
  top: -272px !important;
  width: 100% !important; }

.btn-outline-primary {
  color: #3F96D1;
  border-color: #3F96D1; }
  .btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:visited {
    background: #3F96D1;
    color: #fff; }

.white-scroll::-webkit-scrollbar-track, .assign-modal .modal-body .user-wrapper .user-list::-webkit-scrollbar-track, .white-scroll::-webkit-scrollbar, .assign-modal .modal-body .user-wrapper .user-list::-webkit-scrollbar {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none;
  width: 0.375rem !important;
  height: 0.375rem !important; }

.white-scroll::-webkit-scrollbar-thumb, .assign-modal .modal-body .user-wrapper .user-list::-webkit-scrollbar-thumb {
  box-shadow: none; }

.sleek-scrollbar, textarea, .assign-modal .modal-body .user-wrapper .user-list {
  /*scrollbar-color: #A7B9CB #D8E6F5;
  scrollbar-width: thin;
  scrollbar-highlight-color: red;
  */
  z-index: inherit; }
  .sleek-scrollbar::-webkit-scrollbar-track, textarea::-webkit-scrollbar-track, .assign-modal .modal-body .user-wrapper .user-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px #ddd;
    box-shadow: inset 0 0 4px #ddd;
    border-radius: 50px;
    background-color: #D8E6F5; }
  .sleek-scrollbar::-webkit-scrollbar, textarea::-webkit-scrollbar, .assign-modal .modal-body .user-wrapper .user-list::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #A7B9CB; }
  .sleek-scrollbar::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb, .assign-modal .modal-body .user-wrapper .user-list::-webkit-scrollbar-thumb {
    border-radius: 50px;
    -webkit-box-shadow: inset 0 0 4px #ddd;
    box-shadow: inset 0 0 4px #ddd;
    background-color: #A7B9CB; }

.spec-input {
  height: 30px !important; }

.spec-btn {
  background-color: #2569a1 !important;
  color: #fff; }

.m-h-between {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  color: #000000;
  font-size: 1.25rem; }

.modal-lg {
  width: 58% !important; }

.modal-md {
  width: 45% !important; }

.bg-img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #d6d6d6;
  display: flex;
  justify-content: center;
  align-items: center; }
  .bg-img :nth-child(1) {
    color: #6d6d6d; }

.r-img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  border: 1px solid #e4e4e4;
  object-fit: cover; }

.fm-input1 {
  width: 100% !important; }
  .fm-input1::placeholder {
    color: #677888; }

.invisible {
  visibility: hidden; }

.unchecked-box {
  height: 15px;
  width: 15px;
  background-color: #ccc;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .unchecked-box .check-mark-icon {
    height: 15px;
    width: 15px;
    color: #fff; }

.add-new-email-container {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  align-items: center; }

.dy-text {
  margin-right: 10px; }

.em-text {
  color: blue; }

.yes-text {
  background-color: #2569a1;
  color: #fff;
  border-radius: 3px;
  padding: 10px 20px;
  margin-top: 15px; }

.custom-height {
  overflow-y: auto;
  height: 100vh; }

.custom-color-picker-holder {
  width: 14rem;
  height: fit-content;
  background: #fff;
  border-radius: 6px;
  z-index: 99;
  padding: 0.5rem;
  padding-bottom: 0;
  font-weight: 500; }

.custom-color-picker {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 0.5rem 0 0.5rem 0;
  grid-gap: 0.5rem;
  justify-content: space-around; }

.color-picker-contents {
  width: 14rem;
  height: fit-content;
  background: #fff;
  border-radius: 6px;
  z-index: 99;
  padding: 1.5rem;
  font-weight: 500;
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 0.5rem 0 0.5rem 0;
  grid-gap: 1rem;
  margin-left: -6px; }

.select-color-box {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  border-radius: 50%;
  color: #677888;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .select-color-box:hover {
    opacity: 0.9; }

.user-image {
  background: #DCECF7;
  color: #5D696F;
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center; }

.p-hover {
  border: 1px solid #ffffff; }
  .p-hover:hover {
    border: 1px solid #3F96D1;
    box-sizing: border-box;
    width: initial; }

.bg-dark-blue {
  background-color: #012E54 !important; }

.text-14 {
  font-size: 0.875rem !important; }

.text-16 {
  font-size: 1rem !important; }

.bg-primary-light-blue-20 {
  background-color: #F2F7FC; }

.message-focus {
  animation: blink 0.85s linear infinite; }

@keyframes blink {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

.new-message {
  z-index: 1000;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #FFFFFF;
  position: absolute;
  bottom: 5.5rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center; }
  .new-message span {
    position: absolute;
    right: 0;
    top: -5px; }

mark {
  background-color: yellow; }

.color-box-custom-2 .picker-holder {
  border: 1px solid #A7B9CB;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #A7B9CB;
  width: 5.75rem;
  height: 3rem; }

.after-box-custom::after {
  position: absolute; }

.no-click {
  pointer-events: none; }

.tool-parent:hover .label-tooltip {
  opacity: 1;
  position: absolute; }

.label-tooltip {
  position: fixed;
  right: 0;
  left: 0;
  max-width: 18rem;
  height: fit-content;
  top: calc(-180% - 0.59em);
  background-color: #3F96D1;
  color: #FFFFFF;
  border-radius: 0.25rem;
  padding: 0.5rem;
  opacity: 0;
  transition: opacity 0.35s;
  transition-timing-function: ease; }
  .label-tooltip::after {
    content: '';
    position: absolute;
    left: 1rem;
    bottom: -0.8rem;
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid #3F96D1;
    clear: both; }

.general-menu {
  width: fit-content;
  background: #fff;
  color: #323232;
  border-radius: 2px;
  font-style: meduim;
  line-height: 22px;
  box-shadow: 0px 8px 1rem 0px #bdc9d728;
  z-index: 20;
  transform: translate(-60px, 50px) !important;
  padding: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .general-menu.general-dropdown-menu {
    min-width: 10.25rem;
    height: fit-content; }
  .general-menu .dropdown-item {
    font-size: 0.875rem !important;
    font-weight: 500;
    padding: 0.6rem 1rem;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 1; }
    .general-menu .dropdown-item svg {
      color: #788898;
      margin-right: 0.5rem; }
    .general-menu .dropdown-item .btn svg {
      color: inherit; }

.disabled-share .bg {
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; }

.disabled-share .content {
  z-index: 2; }

.old-msg-btn:disabled {
  background-color: red; }

#close-btn {
  color: #788898;
  background: #F2F7FC;
  border: none;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  #close-btn:hover {
    filter: brightness(85%); }

img {
  text-indent: -9999px; }

.username-tag {
  cursor: pointer; }

.invited-icon {
  width: 1rem !important; }

.file-more {
  border: none;
  background: inherit;
  width: 1rem;
  margin-right: 2px; }
  .file-more svg {
    width: 100% !important;
    color: #788898; }

.dropup {
  right: 0;
  top: 0;
  background: transparent;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center; }
  .dropup .file-more.btn-primary {
    background-color: inherit;
    color: #A7B9CB;
    border: none;
    width: 1.6rem;
    min-width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%; }
    .dropup .file-more.btn-primary svg {
      transform: scale(0.8); }
    .dropup .file-more.btn-primary:hover {
      background-color: rgba(0, 0, 0, 0.062); }
    .dropup .file-more.btn-primary:active {
      background-color: inherit; }
  .dropup .menu {
    z-index: 10;
    font-size: 0.875rem !important;
    background: #ffffff;
    box-shadow: 0px 4px 1rem 0px #B8C5C540;
    padding: 0.25rem;
    border-radius: 0.25rem; }
    .dropup .menu .dropdown-item {
      padding: 0.5rem 1rem;
      font-size: 0.875rem !important;
      font-weight: 500;
      margin: 0;
      border-radius: 0.25rem;
      line-height: unset;
      display: flex;
      gap: 0.5rem;
      align-items: center; }

.file-img .overlay {
  display: none;
  height: 3rem;
  width: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(180deg, #000000 -141.67%, rgba(0, 0, 0, 0) 100%);
  z-index: 1; }

.file-img .image-action {
  z-index: 3; }
  .file-img .image-action .file-more.btn-primary {
    background: #F2F7FC; }
    .file-img .image-action .file-more.btn-primary:hover {
      background-color: #F2F7FC; }

.file-img .dropup {
  display: none;
  z-index: 3; }

.file-img .show.dropup {
  display: block; }

.file-img:hover .dropup {
  display: block; }

.file-img:hover .overlay {
  display: unset; }

.popover {
  font-family: Inter; }

.avatar-menu {
  font-family: Inter;
  font-size: 0.875rem !important;
  background: #ffffff;
  box-shadow: rgba(57, 73, 76, 0.35) 0px 1px 6px 0px;
  padding: 0.25rem;
  border-radius: 0.25rem;
  width: fit-content;
  border: none;
  min-width: 10rem;
  z-index: 10070;
  /* &>.arrow {
    display: none;
  } */ }
  .avatar-menu .item {
    padding: 0.5rem 1rem;
    font-size: 0.875rem !important;
    font-weight: 500;
    margin: 0;
    border-radius: 0.25rem;
    line-height: unset;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%; }
    .avatar-menu .item:hover {
      background-color: #e9ecef; }

.avatar-badge {
  width: fit-content;
  position: absolute;
  bottom: 0;
  right: -2px; }

@media only screen and (max-width: 960px) {
  .Toastify .Toastify__toast-container {
    width: 96vw;
    margin-right: .5rem !important;
    margin-left: .5rem !important; }
  .Toastify .Toastify__toast.Toastify__toast--success {
    width: 100%; }
  .mobile-100-vw {
    width: 100vw; } }

form ::placeholder, .task-modal .modal-body ::placeholder {
  color: #677888;
  font-size: 0.875rem; }

form .form-group, .task-modal .modal-body .form-group {
  margin-bottom: 1.5rem !important;
  position: relative; }

form label, .task-modal .modal-body label {
  margin-bottom: 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 100%; }

form .form-check-label, .task-modal .modal-body .form-check-label {
  margin: 0; }

form .form-control, .task-modal .modal-body .form-control {
  padding: 0.75rem;
  border: 1px solid #A7B9CB;
  font-weight: 400;
  height: auto;
  border-radius: 0.5rem; }

form textarea, .task-modal .modal-body textarea {
  height: 7.25rem;
  resize: none; }

form select, .task-modal .modal-body select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url("");
  background-position: 100% center;
  background-repeat: no-repeat;
  outline: none; }

form .date-duration, .task-modal .modal-body .date-duration {
  width: 100%;
  justify-content: space-between; }
  form .date-duration .starting-date, .task-modal .modal-body .date-duration .starting-date {
    width: 12.8125rem;
    position: relative; }
  form .date-duration .starting, .task-modal .modal-body .date-duration .starting {
    width: 100%;
    position: relative; }
  form .date-duration .set-duration, .task-modal .modal-body .date-duration .set-duration,
  form .date-duration .set-color,
  .task-modal .modal-body .date-duration .set-color {
    width: 16rem; }
  form .date-duration .duration, .task-modal .modal-body .date-duration .duration {
    width: 5.8125rem; }
  form .date-duration .month, .task-modal .modal-body .date-duration .month {
    width: 9.6875rem; }

form .date-time, .task-modal .modal-body .date-time {
  background-color: red; }

form .form-icon, .task-modal .modal-body .form-icon {
  position: absolute;
  top: 2.8rem;
  right: 0.8rem;
  pointer-events: none;
  cursor: pointer; }

form .form-icon_chevron-down, .task-modal .modal-body form-icon_chevron-down {
  position: absolute;
  top: 3.2rem;
  right: 0.8rem;
  pointer-events: none;
  cursor: pointer; }

form .chevron-down, .task-modal .modal-body .chevron-down {
  position: absolute;
  right: 0.8rem;
  top: 1.2rem;
  color: #97A3B0;
  pointer-events: none; }

form .checkbox, .task-modal .modal-body .checkbox {
  font-size: 0.875rem;
  color: #97A3B0; }

form .check-form, .task-modal .modal-body .check-form {
  padding: 0.5rem 0 0.5rem 0; }

.form-progress {
  width: 35%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around; }
  .form-progress span {
    font-size: 1rem;
    font-weight: 600; }
  .form-progress > div {
    position: relative; }
    .form-progress > div .divider {
      position: absolute;
      width: 100%;
      border: 2px solid #F2F7FC;
      top: 50%;
      z-index: 1; }
  .form-progress svg {
    z-index: 2;
    pointer-events: fill; }

.task-modal {
  width: 36.4375rem !important;
  height: 36.125rem !important;
  max-width: none !important;
  max-height: none !important; }
  .customer-modal .modal-content {
    border: none;
    height: 39.125rem !important; }
  .task-modal .modal-header {
    border-bottom: none;
    align-items: center;
    padding: 1rem 1rem 1.5rem 1.5rem; }
    .task-modal .modal-header .modal-title,
    .task-modal .modal-header h4 {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 100%; }
  .task-modal .modal-body {
    padding: 1.5rem;
    padding-top: 0.5rem; }
    .task-modal .modal-body form textarea, .task-modal .modal-body .modal-body textarea {
      resize: none; }
    .task-modal .modal-body #set-color {
      width: 16rem !important; }
  .task-modal .modal-footer {
    height: 6.125rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem 1.5rem 1rem 1.5rem;
    border: none;
    font-weight: 600; }
    .task-modal .modal-footer * {
      margin: 0; }
    .task-modal .modal-footer .btn {
      padding: 0.875rem 1.75rem;
      font-weight: 600;
      font-size: 0.875rem;
      min-width: 7.375rem; }

.assign-modal {
  width: 36.4375rem !important;
  height: 41.75rem !important;
  max-width: none !important;
  max-height: none !important; }
  .assign-modal .modal-content {
    border: none; }
  .assign-modal .modal-header {
    border-bottom: none;
    align-items: center;
    padding: 1rem 1rem 1.5rem 1.5rem; }
    .assign-modal .modal-header .modal-title,
    .assign-modal .modal-header h4 {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 100%; }
  .assign-modal .modal-body {
    padding: 0; }
    .assign-modal .modal-body .search {
      padding: 0 1.5rem 1.375rem 1.5rem; }
      .assign-modal .modal-body .search .search-box {
        width: 100%;
        height: 3.125rem;
        border: 1px solid #A7B9CB;
        padding: 0.75rem;
        border-radius: 0.5rem;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .assign-modal .modal-body .search .search-box input {
          border: none;
          height: 95%;
          width: 80%;
          font-size: 0.875rem !important; }
        .assign-modal .modal-body .search .search-box .search-icon {
          color: #788898; }
    .assign-modal .modal-body .user-wrapper {
      height: 31.8rem; }
      .assign-modal .modal-body .user-wrapper .action {
        display: grid;
        grid-template-columns: 2.5rem auto;
        padding-right: 1.6rem;
        padding-left: 1.6rem;
        padding-bottom: 1.625rem; }
      .assign-modal .modal-body .user-wrapper .user-list {
        height: 22.625rem;
        margin-right: 0.5rem;
        padding-left: 1.6rem;
        overflow-y: auto;
        overflow-x: hidden; }
        .assign-modal .modal-body .user-wrapper .user-list .user {
          display: grid;
          grid-gap: 0;
          grid-template-columns: 2.5rem 4rem 19.25rem auto;
          width: 100%;
          align-items: center;
          margin-bottom: 1rem; }
          .assign-modal .modal-body .user-wrapper .user-list .user .grid-item {
            font-size: 0.875rem !important; }
            .assign-modal .modal-body .user-wrapper .user-list .user .grid-item svg * {
              pointer-events: all; }
            .assign-modal .modal-body .user-wrapper .user-list .user .grid-item .not-checked {
              color: #677888; }
            .assign-modal .modal-body .user-wrapper .user-list .user .grid-item img {
              border-radius: 50%;
              width: 3rem;
              height: 3rem; }
            .assign-modal .modal-body .user-wrapper .user-list .user .grid-item .name {
              color: #102E51;
              font-weight: 600;
              line-height: 160%;
              font-size: 0.875rem !important; }
            .assign-modal .modal-body .user-wrapper .user-list .user .grid-item .email {
              color: #97A3B0;
              line-height: 160%;
              font-size: 0.875rem !important; }
            .assign-modal .modal-body .user-wrapper .user-list .user .grid-item .is-leader {
              width: 6.6875rem;
              height: 2.375rem;
              background: #F2F7FC;
              display: block;
              border-radius: 100px;
              padding: 0.5rem 1rem;
              padding-right: 0;
              cursor: pointer;
              display: flex;
              align-items: center; }
              .assign-modal .modal-body .user-wrapper .user-list .user .grid-item .is-leader * {
                display: block;
                line-height: 160%; }
              .assign-modal .modal-body .user-wrapper .user-list .user .grid-item .is-leader :nth-child(1) {
                font-size: 0.875rem !important;
                font-weight: 500;
                margin-right: 9px; }
              .assign-modal .modal-body .user-wrapper .user-list .user .grid-item .is-leader :nth-child(2) {
                color: #788898;
                margin-top: 1px; }
            .assign-modal .modal-body .user-wrapper .user-list .user .grid-item .not-leader {
              color: #3F96D1;
              font-size: 0.875rem !important;
              padding-left: 0.5rem;
              cursor: pointer; }
  .assign-modal .modal-footer {
    height: 6.125rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem 1.5rem 1rem 1.5rem; }
    .assign-modal .modal-footer * {
      margin: 0; }
    .assign-modal .modal-footer .btn {
      padding: 0.875rem 1.75rem;
      font-weight: 600;
      font-size: 0.875rem;
      min-width: 7.375rem; }

.todo-modal {
  width: 36.4375rem !important;
  max-width: 100vw !important;
  font-size: 0.875rem !important; }
  .todo-modal .modal-header {
    border-bottom: none;
    align-items: center;
    padding: 1rem 1rem 1.5rem 1.5rem; }
    .todo-modal .modal-header .modal-title,
    .todo-modal .modal-header h4 {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 100%; }
  .todo-modal table tr td:first-of-type {
    width: 6.5rem;
    color: #4B5458; }
  .todo-modal table tr td {
    padding: 0.5rem 0; }
  .todo-modal table tr .assigned-users {
    display: flex; }
    .todo-modal table tr .assigned-users .profile-image-holder {
      margin-left: -0.8rem; }
  .todo-modal .modal-footer {
    justify-content: space-between; }
    .todo-modal .modal-footer .btn {
      padding: 0.875rem 0rem;
      font-weight: 600;
      font-size: 0.875rem;
      min-width: 7.375rem; }

@media only screen and (max-width: 960px) {
  form .date-duration .starting-date, .task-modal .modal-body .date-duration .starting-date {
    width: 12rem; }
  .task-modal,
  .assign-modal {
    width: 96vw !important; }
    .task-modal .modal-content,
    .assign-modal .modal-content {
      border: none; }
    .task-modal .modal-footer,
    .assign-modal .modal-footer {
      height: auto;
      position: relative; }
      .task-modal .modal-footer .form-progress,
      .assign-modal .modal-footer .form-progress {
        margin-bottom: 1rem; }
  .task-modal .modal-content {
    height: fit-content !important; } }
    
    .customer-modal {
      height: 44.125rem !important;

      .modal-content {
        height: 44.125rem !important;
      }
    }
