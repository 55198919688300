@import './global.scss';

.custom-wrapper {
	left: 0;
	padding-top: 60px;
	position: relative;
	transition: all 0.2s ease-in-out;
	overflow-y: auto;
	height: 100vh;
}

.header-wrapper {
	height: 100px;

	.project-name {
		display: block;
		width: 30rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.kanban-board {
	.kanban-cont {
		height: calc(100vh - 160px);
		overflow-x: auto;
		overflow-y: hidden;
	}
}

.kanban-list {
	min-width: 310px;
	width: 310px;
	border-radius: 4px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1),
		0 6px 20px 0 rgba(0, 0, 0, 0.1);
	max-height: 100%;

}

.kanban-box {
	padding: 10px;
}


.kanban-list>[data-rbd-droppable-id] {
	/*.droppable-holder {*/
	// scroll-behavior: smooth;
	padding: 0px 18px 0px 18px;
	min-height: 7px;
	max-height: 54vh;
	overflow-y: auto;
	overflow-x: hidden;
	// overflow-anchor: none;
	@extend .sleek-scrollbar;
	/*}*/
	overflow-y: overlay;
}


.task-text-wrapper {
	display: flex;
	width: 100%;
}

.task-name-text {
	width: calc(100% - 10%);
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	line-height: 17px;
	margin-bottom: 10px;
	height: 35px;
}

.task-name-text:hover+.task-edit-icon {
	visibility: visible;
	opacity: 0.5;
}

.task-detail-text {
	width: calc(100% - 10%);
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	line-height: 17px;
	margin-bottom: 10px;
	height: 51px;
	opacity: 0.7;
}

.task-detail-text:hover+.task-edit-icon {
	visibility: visible;
	opacity: 0.5;
}

.task-edit-icon {
	visibility: hidden;
	width: 10%;
}

.task-edit-icon:hover {
	visibility: visible;
}

.task-editable-textarea {
	border-radius: 3px;
	padding: 5px;
	background-color: #f7f7f7;
	color: #000000;
	width: calc(100% - 5%);
	line-height: 17px;
	margin-bottom: 10px;
}

.task-editable-textarea:hover+.task-edit-icon {
	visibility: visible;
	opacity: 0.5;
}

.task-board-controls {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-top: 10px;
	height: 40px;
	align-items: center;
}

.custom-avatar-row {
	display: flex;
	align-items: center;
	padding-left: 10px;
}

.custom-avatar {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	margin-left: -10px;
}

.btn-add-task-user {
	color: #fff;
	background-color: #2569a1;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -15px;
}

.select-project {
	height: 35px;
	width: 200px !important;
	text-overflow: ellipsis;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.control-icon {
	color: rgb(160, 160, 160);
	height: 15px;
	width: 15px;
	margin-left: 10px;
}

.row-centered {
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon-border {
	border-radius: 3px;
	border: 1px solid rgb(223, 223, 223);
	color: rgb(136, 136, 136);
	background-color: #fff;
	height: 37px;
	width: 40px;
	padding: 0 7px;
	display: inline-flex;
	align-items: center;
	cursor: pointer;
}

$fex-color: #2569a1;
$carton-color: #e9e9e9;
$carton-color2: #f4f4f4;

//Custom Spinner
$size: 17px;
$point: #2569a1;
$gray2: #f4f4f4;
$bdw: 4px;
$big: 1.6;
$small: 0.625;

.flex-full-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.button {
	all: unset;
}

img {
	object-fit: cover;
}

.button-common {
	border-radius: 3px;
	padding: 5px 10px;
	cursor: text;
}

.button-common2 {
	border-radius: 3px;
	padding: 3px 7px !important;
	cursor: text;
}

.margin-left-30 {
	margin-left: 30px;
}

@mixin avatar-h-w($w, $x) {
	width: $w;
	height: $x;
}

@mixin avatar-m-l($y) {
	margin-left: $y;
}

[contenteditable] {
	outline: 0px solid transparent;
}

.task-2-chat {
	z-index: 1000;
	position: fixed;
	height: 520px;
	width: 750px;
	background-color: #fff;
	box-shadow: rgba(51, 51, 51, 0.24) 0px 4px 12px 0px;
	display: flex;
	flex-direction: column;

	.task-2-chat-header {
		display: flex;
		padding: 10px;
		align-items: center;
		border-bottom: 1px solid #e2e8f0;

		.task-2-close-btn {
			color: #a0a0a0;
		}

		.task-2-title {
			margin: 0 auto;
			cursor: default;
		}
	}

	.task-2-chat-body {
		display: flex;
		flex-direction: column;
		height: calc(100% - 60px);
		overflow-y: auto;
		@extend .sleek-scrollbar;
		margin: 10px 0px 50px 0px;
		padding: 10x 10px 60px 10px;

		.span-0-7 {
			font-weight: bold;
			font-size: 14px;
			opacity: 0.7;
		}

		>div:nth-child(1) {
			display: flex;
			flex-direction: column;
			margin-bottom: 20px;

			.task-2-chat-assigned-to {
				display: flex;
				margin: 10px 0 30px;
				align-items: center;
				padding: 0 10px;

				>span:nth-child(1) {
					@extend .span-0-7;
					margin-right: auto;
				}

				.ts-awaiting-btn {
					@extend .button-common2;
					font-size: 12px;
					border: 1px solid #a0a0a0;
					opacity: 0.8;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #8b8b8b;
					cursor: pointer;

					&:hover {
						background-color: #a0a0a0;
						color: #fff;
					}

					> :nth-child(1) {
						margin-right: 7px;
					}

					svg {
						height: 16px;
						width: 16px;
					}
				}

				.ts-accepted-btn {
					@extend .button-common2;
					font-size: 12px;
					border: 1px solid #a0a0a0;
					opacity: 0.8;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #8b8b8b;

					&:hover {
						background-color: #a0a0a0;
						color: #fff;
					}

					> :nth-child(1) {
						margin-right: 7px;
					}
				}

				.ts-pending-btn {
					@extend .button-common2;
					font-size: 12px;
					background-color: #a0a0a0;
					color: #fff;
				}

				.ts-mark-completed-btn {
					@extend .button-common2;
					font-size: 12px;
					border: 1px solid #2569a1;
					opacity: 0.8;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #2569a1;
					cursor: pointer;

					&:hover {
						background-color: #2569a1;
						color: #fff;
					}

					> :nth-child(1) {
						margin-right: 10px;
					}

					svg {
						height: 16px;
						width: 16px;
					}
				}

				.ts-approve-as-complete-btn {
					@extend .button-common2;
					font-size: 12px;
					background-color: #9ca706;
					color: #fff;
					opacity: 0.8;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					> :nth-child(1) {
						margin-right: 10px;
					}

					svg {
						height: 16px;
						width: 16px;
					}
				}

				.ts-complete-btn {
					background-color: #23b654;
					color: #fff;
					cursor: text;
					@extend .button-common2;
					font-size: 12px;
				}

				.ts-under-review-btn {
					background-color: #9ca706;
					color: #fff;
					cursor: text;
					@extend .button-common2;
					font-size: 12px;
					margin-left: 10px;
				}
			}

			>div:nth-child(2) {
				display: flex;

				>div:nth-child(1) {
					padding-left: 20px;
				}

				>div:nth-child(2) {
					display: flex;
					font-family: Inter, Roboto, Arial, sans-serif;
					align-items: center;

					>span {
						font-size: 13px;
						font-weight: bold;
						opacity: 0.7;
					}

					>.assigned-to-details {
						position: relative;

						>span {
							font-size: 13px;
							font-weight: bold;
							opacity: 0.7;
							cursor: default;
						}

						>.assigned-to-others-text {
							color: blue;
							margin-left: 5px;
						}

						>.assigned-to-others-text:hover+.assigned-to-others-layout {
							display: block;
						}

						>.assigned-to-others-layout:hover {
							display: block;
						}

						>.assigned-to-others-layout {
							display: none;
							position: absolute;
							top: 22px;
							left: -95px;
							border: 1px solid #fefefe;
							background-color: #fff;
							width: 180px;
							max-height: 150px;
							overflow-y: auto;
							@extend .sleek-scrollbar;
							padding: 10px 10px 0;
							box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
							z-index: 2;

							>div {
								display: flex;
								width: 100%;
								align-items: center;
								margin-bottom: 15px;

								>img {
									height: 35px;
									width: 35px;
									border-radius: 50%;
									margin-right: 10px;
								}

								>span {
									font-size: 13px;
									font-weight: bold;
									opacity: 0.7;
									font-family: Inter, Roboto, Arial, sans-serif;
								}
							}
						}
					}
				}
			}
		}

		>div:nth-child(2) {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;

			>div {
				display: flex;
				flex-direction: column;

				>span:nth-child(1) {
					margin-bottom: 3px;
					@extend .span-0-7;
				}

				>span:nth-child(2) {
					font-size: 12px;
					opacity: 0.8;
					margin-right: 10px;
				}
			}
		}

		>div:nth-child(3) {
			display: flex;
			flex-direction: column;
			margin-bottom: 20px;

			>span:nth-child(1) {
				margin-bottom: 3px;
				@extend .span-0-7;
			}

			>span:nth-child(2) {
				font-size: 13px;
				opacity: 0.8;
				height: auto;
				overflow-y: auto;
				@extend .sleek-scrollbar;
				// background-color: #fcfcfc;
				padding: 3px;
			}
		}

		>div:nth-child(4) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 10px;
			background-color: #f5f9fc;
			font-weight: bold;
			margin-bottom: 20px;

			>span:nth-child(1) {
				height: 40px;
				display: flex;
				align-items: center;
			}

			.gallery-icon {
				height: 20px;
				width: 20px;
				opacity: 0.6;
				cursor: pointer;
				color: #ffc107;
			}
		}

		>div:nth-child(5) {
			display: flex;
			flex-direction: column;
			height: 100%;


		}
	}
}

select {
	outline: none;
}

.btn-plus {
	background-color: #2569a1 !important;
}

.card {
	border: unset !important;
}

.task-card {
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	background-color: #ffffff;

	&:hover {
		filter: brightness(97%);

		.task-status-more-layout {
			background-color: #ffffff;
			filter: none !important;
		}
	}
}

// Ga
.task-status-more {
	z-index: 1;
	cursor: pointer;

	>.task-status-more-icon {
		color: rgb(160, 160, 160);
	}

	button {
		border: 0;
		margin: 0;
		padding: 0;
		color: rgb(160, 160, 160);
		background-color: inherit;
		// color: inherit
	}

	svg {
		pointer-events: none;
	}

	&:focus-within .task-status-more-layout {
		display: flex;
	}

	>.task-status-more-icon:active+.task-status-more-layout {
		display: flex;
	}

	>.task-status-more-layout:hover {
		display: flex;
	}

	>.task-status-more-layout {
		position: absolute;
		display: none;
		flex-direction: column;
		// justify-content: center;
		// align-items: center;
		right: 8px;
		top: 35px;
		width: 120px;
		padding: 10px 0;
		background-color: #fff;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

		>span {
			padding: 5px 10px 5px 5px;
			cursor: default;
			display: flex;
			align-items: center;
			justify-content: start;
			font-weight: 100;

			&:hover {
				background-color: #ebecf0;
			}
		}
	}
}

.badge-position {
	position: relative;
	right: 5px;
	top: -8px;
}

.no-scroll {
	overflow: hidden;
}

.add-new-task {
	// margin-top: 15px;
	height: auto;
	padding: 4px;
}