@import "./global.scss";

* {
  // font-family: 'Inter' !important;
}

select {
  background: #ffffff;
}

.task-detail-content {
  width: 71.4375rem;
  height: 44.25rem;
  max-width: none !important;
  max-height: none !important;
  min-height: inherit;
  // @extend .sleek-scrollbar;



  .modal-header {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    // border-bottom-color: #A7B9CB;

    #info {
      display: flex !important;
    }

    .task-status {
      background: #C8E7FD;
      color: #23A2F8;
      padding: 4px 8px;
      font-weight: 600;
      margin: 0;
      border-radius: 4px;
      margin-right: 1.22rem;
      font-size: 0.875rem !important;
    }

    .action {
      display: flex;
      align-items: center;
      line-height: 0;
      font-size: 0.875rem !important;

      svg {
        margin-right: 0.6rem;
      }

      color: #97A3B0;
    }

    #close-btn {
      color: $base-grey-60;
      background: #F2F7FC;
      border: none;
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .modal-content {
    border-radius: 0.5rem;
    border: none;
    // height: calc(100% + 0.4rem);
    width: 100%;
    overflow: hidden;
    min-height: 44.25rem;
  }

  .modal-body {
    // height: 40.5rem;
    padding: 0;


    .nav-tabs {
      display: flex;
      font-weight: 600;
      color: $base-grey-60;
      padding: 0;
      border: none;

      .tab {
        width: 50%;
        border-bottom: 3px solid transparent;
        height: 3.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.active {
          border-bottom: 3px solid #102E51;
          color: #102E51;
        }
      }
    }

    .border-right {
      padding-right: 2px;
      width: 36.375rem;
      border-color: #A7B9CB;
      position: relative;
      overflow-x: hidden;
      @extend .sleek-scrollbar;
      @extend .white-scroll;
    }

    .info {
      padding: 1.5rem;
      padding-bottom: 1rem;
      // height: 40.5rem;
      // overflow: auto;
      overflow: hidden;
      position: absolute;
      left: 0;
      right: 0;


      .task-header {
        padding: 0;
        margin-bottom: 1.5rem;
        display: flex;
        justify-content: space-between;

        .title {
          h1 {
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 24.2px;
            margin: 0;
          }

          p {
            font-size: 0.875rem !important;
            color: #97A3B0;
            line-height: 22.2px;
            margin: 0;
            font-weight: 400;
          }
        }

        .members {

          img,
          .user-image {
            border-radius: 50%;

            border: 2px solid #fff;
            width: 40px;
            min-width: 28px;
            z-index: 2;
          }

          .profile-image-holder {
            margin-right: -10px;
          }
        }
      }

      .desc {
        // font-weight: 600;
        line-height: 21px;
        color: #737373;

        p {
          margin: 0;
          font-size: 0.875rem !important;
          white-space: pre-line;
        }

        margin-bottom: 1.5rem;
      }

      .attachment {
        h3 {
          font-weight:
            600;
          font-size: 1rem;
          margin: 0;
        }

        .actions {
          font-size: 0.875rem !important;
          color: #97A3B0;
          font-weight: 600;
          display: flex;
          align-items: center;


          .search-wrap {
            width: auto;
            display: flex;
            align-items: center;
            background: #fff;
            padding: 0.16rem;
            border-radius: 6px;
            color: #97A3B0;
            border: 1px solid #F2F7FC;
            background: #F2F7FC;

            input {
              background: inherit;
              font-size: 0.75rem !important;

              &::placeholder {
                font-size: 0.75rem !important;
              }
            }

          }

          .search {
            display: flex;
            line-height: 0;
            width: 0;
            transition: width 0.2s;
            transition-timing-function: ease;
            overflow: hidden;
            border: none;


            &:not(:placeholder-shown),
            &:focus {
              width: 11rem;
              padding-left: 0.5rem;
            }

          }

          .search-btn {
            // position: absolute;
            display: flex;
            align-items: center;
            margin: 0;
            margin-right: -4px;
            // padding: 0.8rem;

            svg {
              font-size: 13px;
            }
          }

          select {

            margin-left: 0.5rem;
            padding: 0.6rem 0.6rem;
            border-radius: 6px;
            font-size: 0.875rem;
            font-weight: 600;
            color: $base-grey-60;

            border: 1px solid #F2F7FC;
            background: #F2F7FC;
          }

          .btn {
            padding: 0;
            width: 2rem;
            height: 2rem;
            background: #F2F7FC;
            border-radius: 0.25rem;
            color: #677888;

            svg {
              width: 1rem;
            }

            &.active {
              background-color: #D9ECFA;
              border: 1px solid $primary;
              color: $primary;
            }
          }
        }

        .crumbs {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 0rem;
          font-size: 0.875rem !important;



          span {
            margin: 0;
            display: flex;
            align-items: center;
            color: #677888;
            gap: 0rem;
            cursor: pointer;
          }

          span:first-of-type {
            color: #64C5FA;
          }

          span:last-of-type {
            color: #000;
          }


        }

        /*.files {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;

          .file {
            margin-right: 0.9rem;
            width: 7.2rem;

            .holder {
              width: inherit;
              height: 7.2rem;
              background-color: #A7B9CB;
            }

            .details {
              width: inherit;
            }
          }
        }
        */


        .no-attachments {
          width: 14rem;
          text-align: center;
          font-weight: 500;
          font-size: 0.875rem !important;
          color: #677888;
          margin-top: 2.0625rem;
        }

        .files {
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: auto auto auto auto;
          text-align: center;
          width: 100%;
        }

        .grid-item {
          width: 7.6rem !important;
          height: 10.875rem;
          // margin-bottom: 1rem;

          .img-holder {
            // background: #A7B9CB;
            position: relative;
            border: 1px solid #DCECF7;
            height: 7.6rem;
            margin-bottom: 0.5rem;

            &.is-image {
              border: none;

              img {
                object-fit: contain;
                width: 100%;
                height: 100%;
              }
            }

            svg {
              width: 56%;
              height: auto;
            }

            .dropdown {
              position: absolute;
              right: 0;
              top: 0;

              .menu {
                background: #ffffff;
                padding: 0.25rem;
                box-shadow: 0px 4px 1rem 0px #B8C5C540;


                .dropdown-item {

                  font-size: 0.875rem !important;
                  font-weight: 500;
                  padding: 0.5rem 1rem;
                }

              }
            }

            .file-more {
              // color: inherit;
              border: none;
              background: inherit;
              width: 1rem;
              margin-right: 2px;

              svg {
                width: 100% !important;
                color: #788898;
              }
            }

            .lock-icon {
              color: $base-grey-40;
            }
          }


          span {
            font-size: 0.875rem !important;
            color: #788898;
            line-height: 22px;
            word-break: break-all;
          }
        }

        .files-update {
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: auto auto auto;
          width: 100%;

          .item {
            // height: 6.625rem;
            height: 10rem;
            width: 10.5rem;
            box-shadow: 0px 3px 13px 0px #ABB3BC30;
            display: flex;
            flex-direction: column;
            border-radius: 0.5rem;

            .icon-holder {
              flex-grow: 1;
              overflow: hidden;
              border-radius: 0.5rem 0.5rem 0 0;
              border: 1px solid #CED8E1;

              img {
                object-fit: cover;
                height: 100%;
                width: 100%;
                cursor: pointer;
              }

              .icon-ga,
              .file-icon {
                transform: scale(1.5) !important;
                background-color: inherit;
                cursor: pointer;

              }
            }

            .file-details {
              justify-content: space-between;
              height: 2.875rem;
              padding: 0.5rem;
              position: relative;
              align-items: flex-end;
              border: 1px solid #CED8E1;
              border-top: none;
              border-radius: 0 0 0.5rem 0.5rem;
              white-space: nowrap;



              .filename {
                font-size: 0.75rem !important;
                font-weight: 500;
                color: $base-grey-100;
                display: block;
                cursor: pointer;

              }

              .filesize {
                font-size: 0.75rem !important;
                font-weight: 400;
                color: $base-grey-60;
                line-height: 160%;
                display: block;
              }

              .dropup {
                //  position: absolute;
                right: 0;
                top: 0;
                background: #ffffff;

                .file-more.btn-primary {
                  background-color: $base-white;
                  color: #A7B9CB;
                  border: none;

                }

                .menu {
                  font-size: 0.875rem !important;
                  background: #ffffff;
                  padding: 0.25rem;
                  box-shadow: 0px 4px 1rem 0px #B8C5C540;


                  .dropdown-item {
                    padding: 0.5rem 1rem;
                    font-size: 0.875rem !important;
                    font-weight: 500;
                  }

                }
              }
            }
          }
        }

        .files-update-full {
          font-size: 0.75rem;
          display: flex;
          gap: 1rem;
          padding: 1rem 0;
          align-items: center;
          flex-direction: column;
          justify-content: space-between;

          .item-full {
            display: flex;
            gap: 1rem;
            padding: 0.5rem;
            align-items: center;
            width: 100%;
            box-shadow: 0px 1px 4px 0px #ABB3BC30;

            .image-holder {
              min-width: 3.1rem;
              width: 3.1rem;
              height: 2rem;

              img {
                object-fit: contain;
                width: 100%;
                height: 100%;
              }
            }

            .filename-holder {
              width: 80%;
              display: flex;
              flex-direction: column;


              .filename {
                font-size: 0.75rem !important;
                font-weight: 500;
                color: $base-grey-100;
                display: block;
                cursor: pointer;

              }

              .filesize {
                font-size: 0.75rem !important;
                font-weight: 400;
                color: $base-grey-60;
                line-height: 160%;
                display: block;
              }
            }

          }


          .dropup {
            //  position: absolute;
            right: 0;
            top: 0;
            background: #ffffff;

            .file-more.btn-primary {
              background-color: $base-white;
              color: #A7B9CB;
              border: none;

            }

            .menu {
              padding: 0.25rem;
              background: #ffffff;
              box-shadow: 0px 4px 1rem 0px #B8C5C540;


              .dropdown-item {
                padding: 0.5rem 1rem;
                font-size: 0.875rem !important;
                font-weight: 500;
              }

            }
          }
        }
      }
    }

    .conversations {
      width: 35rem;
      min-height: 40.5rem;
      background: #F2F7FC;
      border-radius: 0 0 0.5rem 0;
      position: relative;

      .head {
        padding: 1rem 1.5rem;
        background: #fff;
        font-weight: 600;
        font-size: 1rem;
      }

      display: flex;
      flex-direction: column;

      .drag-area {
        position: absolute;
        width: inherit;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #F2F7FC;
        z-index: 50;

        padding: 1rem;

        div:first-of-type {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          border: 0.25rem dashed #323232;
          font-weight: 600;
          font-size: 1.25rem;
          pointer-events: none;
        }
      }
    }

    .empty-conversations {
      width: 13.6875rem;
      height: 12.625rem;
      margin-top: 10.125rem;
      flex: 1;

      h4 {
        font-size: 1rem;
        font-weight: 500;
        margin: 0;
        line-height: 1.6rem;
      }

      span {
        color: #5D696F;
        font-size: 0.875rem !important;
        line-height: 1.375rem;
      }
    }

    .search-window {
      height: 80%;
      width: 20.375rem;
      background-color: $base-white;
      top: 0;
      right: 0;
      z-index: 10;
      height: 40.5rem;
      border-left: 1px solid #CED8E1;
      padding: 0.875rem 1rem;
      padding-right: 0;
      transition: transform 0.3s;
      transition-timing-function: ease;

      &.hide {
        transform: translate(20.375rem, 0);
      }

      &.show {
        transform: none;
      }

      .search-area {
        margin-bottom: 1.25rem;
        padding-right: 1rem;
      }

      .holder {
        background: #F2F7FC;
        width: 16.375rem;
        height: 2.375rem;
        border-radius: 0.5rem;
        padding: 0.5rem 0.75rem;

        input {
          background: inherit;
          border-radius: inherit;
          border: none;
          font-size: 0.875rem !important;
          width: 100%;
        }

        svg {
          color: #677888
        }
      }

      p {
        font-weight: 500;
      }

      .search-results {
        font-weight: 400;
        padding-right: 0.5rem;
        font-size: 0.875rem !important;
        overflow-y: auto;
        height: 80%;
        @extend .sleek-scrollbar;
        @extend .white-scroll;

        .result {
          gap: 1rem;
          margin-bottom: 1.5rem;

          .flex-grow-1 {
            max-width: 15rem;
          }

          .user-details {
            width: 15rem;
            overflow: hidden;
          }

          .name {

            font-weight: 600;
            color: #323232;
            margin-right: 2rem;
            font-size: 0.875rem !important;
          }

          .time {
            font-size: 0.75rem;
            color: #677888;
          }

          .message {
            color: #677888;
            font-weight: 400;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .thread {
      // scroll-behavior: smooth;
      // background: #F2F7FC;
      background-color: #F3F5F7;

      height: 32.55rem;
      //flex-grow: 1;
      width: calc(100% - 2px);
      overflow: auto;
      overflow-x: hidden;
      @extend .sleek-scrollbar;
      @extend .white-scroll;



      .user-image {
        min-width: 28px;
        border-radius: 50%;
        margin-right: 0.5rem;
        border: 2px solid #fff;
        font-size: 1rem !important;
      }

      .close-reply {
        position: absolute;
        right: 0.25rem;
        top: -1rem;
        // background: #fff;
        border-radius: 50%;
      }

      .message-box-holder {
        width: 30rem;

        .message-box {

          .dropdown,
          .toggle-holders .dropup {
            position: absolute;
            top: 5px;
            right: 5px;
            display: none;
            overflow: hidden;

            .comment-toggle {
              background: $base-white;
              color: $base-grey-80;
              padding: 0;
              border: none;

              svg {
                transform: rotate(180deg);
                transition: transform 0.35s;
                transition-timing-function: ease;
              }
            }

            .comment-menu {
              width: 8.75rem;
              // height: 10.5rem;
              height: fit-content;
              box-shadow: 0px 5px 12px 0px #B2BDD336;
              background: $base-white;
              border-radius: 0.25rem;
              z-index: 10;
              padding: 0.25rem;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .dropdown-item {
                padding: 0.5rem 1rem;
                text-align: left;
                font-weight: 500;
                font-size: 0.8rem !important;
                color: $base-grey-100;
              }


            }


          }

          .toggle-holders .dropup {
            right: 1.5rem;
            background-color: #fff;
            //display: block;
            height: unset;
            width: unset;


            .reaction-toggle {
              background-color: #fff;
              border: none;


            }

            .reaction-menu {
              width: 12.25rem;
              height: 2.25rem;
              border-radius: 0.5rem;
              background-color: #fff;
              box-shadow: 0px 0px 12px 0px #B2BDD336;

              display: flex;
              align-items: center;
              margin-bottom: 1rem;
              margin-right: 1rem;
              z-index: 10;

              &::after {
                content: '';
                position: absolute;
                right: 0.7rem;
                bottom: -0.4rem;
                width: 0;
                height: 0;
                border-left: 0.5rem solid transparent;
                border-right: 0.5rem solid transparent;
                border-top: 0.5rem solid #fff;
                clear: both;
              }

              &[data-popper-placement="bottom-end"] {
                margin-top: 0.4rem;

                &::after {
                  transform: rotate(180deg);
                  top: -0.4rem;

                }
              }

              .dropdown-item {
                width: 2rem;
                height: 2rem;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0.25rem;
              }
            }
          }

          .show.dropdown,
          .toggle-holders .show.dropup {
            display: block;
            overflow: unset;

            .comment-toggle {
              color: $primary;

              svg {
                transform: rotate(0deg);
              }
            }
          }

          &:hover {
            .dropdown {
              display: block;
            }

            .toggle-holders .dropup {
              display: block;

              .reaction-toggle {
                color: $primary;
              }
            }
          }

          .user-reactions {
            .user-reactions-dropdown {
              position: relative !important;
            }

            .user-reactions-toggle {
              border-radius: 9999px;
              // background: #11111126;
              height: 1.5rem;
              width:
                3.75rem;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 0.625rem !important;
              background-color: #fff;
              border: none;
              gap: 0.25rem;



              &:hover {
                background-color: #fff;
              }

              span {
                display: block;
                //margin-left: 0.25rem;
                // color: #fff;
                color: #677888 !important;
              }

              .emoji-holder {
                width: 1.5rem;
                min-width: 1.5rem;
                height: 1.5rem;
                background: #c8e7fd70;

                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                z-index: 2;

                border: 1px solid #FFFFFF;

                svg {
                  transform: scale(0.8);
                }

                &:nth-of-type(2) {
                  margin-left: -0.5rem;
                  z-index: 1;
                }
              }


            }

            .user-reactions-menu {
              min-width: 17rem;
              //height: 22.625rem;
              height: fit-content;
              background-color: #fff;
              box-shadow: 0px 5px 12px 0px #B2BDD336;
              z-index: 100;
              margin-top: 1.125rem;
              border-radius: 0.5rem;
              margin-left: -1rem;

              &[data-popper-placement="bottom-end"] {
                margin-bottom: 1.125rem;
              }

              .tabs {
                display: flex;
                padding: 1rem;
                padding-bottom: 0;
                margin-bottom: 1rem;

                border-bottom: 1px solid #F3F5F7;


                .btn {
                  border-radius: unset !important;
                  height: 2.125rem;
                  padding-top: 0;
                  font-family: 600;

                }

                .tab {
                  width: 4.8rem;
                  border-bottom: 3px solid #fff;


                  &.checked {
                    border-bottom: 3px solid #102E51;
                    font-weight: 600;
                  }

                  span {
                    font-size: 0.75rem;
                    color: #677888;
                    font-weight: 400;

                  }
                }
              }

              .content {
                max-height: 15rem;
                overflow: auto;
                @extend .sleek-scrollbar;
                @extend .white-scroll;
                margin: 0 0.25rem;
                margin-bottom: 1rem;

                .user-item {
                  display: flex;
                  gap: 1rem;
                  align-items: center;
                  padding: 0.5rem 1.25rem;

                  .user-avatar {
                    width: 2rem;
                    height: 2rem;
                    margin: 0;
                  }
                }
              }
            }
          }
        }



      }

      .message-box {
        padding: 0.5rem 1rem;
        max-width: 30rem;
        min-width: 10rem;
        border-radius: 0 0.5rem 0.5rem 0.5rem;
        width: fit-content !important;

        .sender-details {
          min-width: 10rem;

          p {
            width: inherit;
            white-space: nowrap;
            overflow: hidden;
            // text-overflow: ellipsis;
          }
        }

        p,
        span {
          margin: 0;
          font-size: 0.875rem !important;
          line-height: 22px;
          color: #181E2A;
          word-break: break-word;
        }

        .username {
          font-weight: 600;
          color: #323232;
          margin-right: 2rem;
          font-size: 0.875rem !important;
        }

        .created-at {
          color: #97A3B0;
          font-size: 0.75rem !important;
          line-height: 21px;

          .waiting {
            color: inherit;

            svg {
              width: 15px;
              margin-bottom: 1px;
            }
          }
        }

        .comment-files {
          margin-top: 5px !important;
        }

        .file-img {
          width: 15.875rem;
          // height: 13.75rem;
          background: #A7B9CB;
          height: auto;
          min-height: 3rem;
          position: relative;
          border: 1px solid #A7B9CB;



          .image-action {
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
            padding: 0.2rem;
            // background: #00000018;
            border-radius: 50%;

            .cancel {
              white-space: nowrap;
              font-size: 0.65rem !important;
              color: #000;
            }
          }

          .download-holder {
            // opacity: 0;
            transition: opacity 0.1s;
            transition-timing-function: ease;
          }

          .img {
            width: 100%;
          }

          /*&:hover {
            .download-holder {
              opacity: 1;
            }
          }*/

        }

        .file-other {
          width: 15.875rem;
          border: 1px solid #A7B9CB;
          height: 3.25rem;
          border-radius: 0.375rem;
          padding: 0.25rem 0.5rem 0.25rem 0.958125rem;
          padding-right: 12.69px;
          position: relative;

          .lock-icon {
            color: $base-grey-40;
          }

          .file-icon {
            margin-right: 0.708125rem;
            width: 1.833125rem;
            cursor: pointer;
          }

          .file-details {
            display: flex;
            flex-direction: column;
            white-space: nowrap;

            :nth-child(1) {
              font-size: 0.875rem !important;
              font-weight: 600;
              color: #231F20;
            }

            :nth-child(2) {
              color: #788898;
              font-size: 0.75rem !important;
            }
          }

          .close,
          .download {
            color: #788898;
            // width: 0.664375rem;
            font-size: 1rem;
            margin-right: -2px;
            cursor: pointer;
          }



          .react-sweet-progress-circle {
            cursor: pointer;
          }

          .cancel {
            color: #000;
            font-size: 0.65rem !important;
            // cursor: pointer;
            font-weight: 500;
            white-space: nowrap;
          }
        }

        .comment {
          white-space: pre-line;

          a {
            color: #3F96D1 !important;
          }
        }

        .edit-box {
          font-size: 0.875rem !important;

          textarea {
            font-size: 0.875rem !important;
            min-width: 100%;
            width: 26rem !important;
            height: max-content;
            line-height: 1.375rem;

            &.reply-textarea {
              width: 24rem !important;
            }
          }


          .utils {
            display: flex;
            gap: 0.25rem;

            svg {
              color: #677888 !important;
              width: 80%;
            }

            .btn-light {
              &.select-folder {
                display: flex;
                align-items: center;
                font-weight: 400;
              }


            }
          }
        }

        .username-tag {
          color: $primary;
        }

        .quote {
          background-color: $primary-light-blue-20;
          border-radius: 0.25rem;
          border-left: 2px solid #71B0DB;
          margin-bottom: 0.25rem;
          margin-top: 0.25rem;
          height: 4.5rem;
          cursor: pointer;

          .content {
            padding: 0.25rem 0.625rem;
            flex-grow: 1;
            max-width: 23rem;
          }


          .quote-username {
            font-weight: 600;
            font-size: 0.75rem !important;
            color: #4B5458;
          }

          .comment {
            color: #4B5458;
            font-size: 0.75rem !important;
            width: calc(95%);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .username-tag {
              font-size: inherit !important;
            }
          }

          .image-holder {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 0 0.25rem 0.25rem 0;
            background-color: $primary-light-blue-20;

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
              border-radius: inherit;
            }
          }
        }
      }

      .reply {
        margin-top: 1rem;

        .message-box {
          max-width: 27rem !important;
        }

        .user-image {
          min-width: 28px;
          width: 28px;
          height: 28px;
          font-size: 1rem !important;
        }
      }

      span {
        color: #97A3B0;
        font-weight: 600;
        font-size: 0.75rem !important;
      }

      .username-tag {
        color: $primary;
      }
    }

    .comment-area {
      /* height:
        4.5rem;*/
      position: relative;
      bottom: 0;
      right: 0;
      width: inherit;
      padding: 0 1rem 1rem 1rem;
      background: #F2F7FC;
      border-bottom-right-radius: 0.5rem;
      z-index: 2;


      // for comment area
      .quote {
        background-color: $primary-light-blue-20;
        border-radius: 0.25rem;
        border-left: 2px solid #71B0DB;
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
        height: 4.5rem;

        .content {
          padding: 0.25rem 0.625rem;
          flex-grow: 1;
          max-width: 25rem;
          line-height: 1.5rem;
        }


        .quote-username {
          font-weight: 600;
          font-size: 0.75rem !important;
          color: #4B5458;
        }

        .comment {
          color: #4B5458;
          font-size: 0.75rem !important;
          width: calc(95%);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .username-tag {
            font-size: inherit !important;
          }
        }

        .image-holder {
          width: 4.5rem;
          height: 4.5rem;
          border-radius: 0 0.25rem 0.25rem 0;
          background-color: $primary-light-blue-20;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: inherit;
          }

          svg {
            transform: scale(2);
          }
        }
      }

      .close-quote-btn {
        width: 0.75rem;
        color: #A7B9CB;

      }

      .conversation-files {
        position: relative;
        width: calc(100%);
        // height: 5rem;
        // background: red;
        background: #fff;
        bottom: 0.5rem;
        border-radius: 10px;
        min-height: fit-content;
        max-height: 9rem;
        overflow: auto;
        overflow-x: hidden;
        padding: 1rem;
        @extend .sleek-scrollbar;

        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar {
          background-color: #fff !important;
          box-shadow: none;
        }

        .files {
          display: grid;
          // grid-template-columns: 13.0625rem 13.0625rem 13.0625rem 13.0625rem;
          grid-template-columns: 7rem 7rem 7rem 7rem;
          justify-content: space-between;
          grid-gap: 0.5rem;
          padding-right: 0.5rem;


          .grid-item {
            border: 1px dashed #CED8E1;
            // height: 3.25rem;
            height: 7rem;
            // width: 7rem;
            border-radius: 0.375rem;
            padding: 1.375rem 0.5rem 0.25rem 0.25rem;
            // padding-right: 12.69px;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            // >div {

            .file-icon {
              // margin-right: 0.708125rem;
              // width: 1.833125rem;
            }

            .file-details {
              display: flex;
              flex-direction: column;
              color: $lighter;
              text-align: center;
              white-space: nowrap;

              :nth-child(1) {
                font-size: 0.875rem !important;
                //font-weight: 600;

              }

              :nth-child(2) {

                font-size: 0.75rem !important;
              }
            }

            //}

            .close {
              color: #A7B9CB;
              // width: 0.664375rem;
              font-size: 1rem;
              margin-right: -2px;
              cursor: pointer;
              position: absolute;
              right: -0.5rem;
              top: -0.75rem;
              background: #F3F5F7;
              padding: 0.25rem;
              border-radius: 50%;
              width: 1.5rem;
              height: 1.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .react-sweet-progress-circle {
              cursor: pointer;
            }

            .cancel {
              // color: $base-grey-100;
              color: #000;
              font-size: 0.65rem !important;
              // cursor: pointer;
              font-weight: 500;
              white-space: nowrap;
            }

            /*.cancel {
              visibility: hidden;
  
              &::after {
                content: 'x';
                visibility: visible;
                margin-right: 30px;
              }
            }
            */

          }
        }
      }


      .comment-section {
        /*min-height:
          3.5rem;
        height: auto;
        */
        border-radius: 10px;
        box-shadow: 0px 4px 1rem 0px #ABB3BC36;
        background: #fff;
        justify-content: space-between;
        // gap: 0.25rem;
        align-items: flex-end;
        width: 100%;



        textarea {
          padding: 1rem 1rem; // margin-bottom: 1rem;
          // padding-bottom: 0.rem;
          // max-height: 12.5rem;
          overflow-y: auto;
          overflow-x: hidden;
          border-radius: 10px;
          border: none;
          width: 30rem;
          height: inherit;
          height: 3.5rem;
          outline: none;
          resize: none;
          overflow: hidden;
          margin-top: 5px;

          /*&:focus+.actions,
          &:not(:placeholder-shown)+.actions,
          */
          &+.active {
            height: 3.5rem;
          }

          &+.in-active {
            height: 0;
          }

          @extend .sleek-scrollbar;
          @extend .white-scroll;

        }

        .actions {

          overflow: hidden;
          transition: height 0.1s;
          transition-timing-function: ease;


          >div {
            right: 0;
            left: 0;
            padding: 0 1rem;

            .utils {
              gap: 0.5rem;

              .btn-light {
                background-color: $base-white;
                border: none;

                &:hover {
                  background-color: initial;

                }
              }
            }

          }

          button {
            font-weight: 500;
          }
        }

        ::placeholder {
          color: #97A3B0;
        }

        .send {
          color: $primary
        }

        .attach {
          // color: #97A3B0;
          // margin: 0 0.5rem -0.6rem 0.5rem;

        }

        svg {
          color: #677888;
          width: 1.2rem;



          // margin-bottom: 1rem;
        }



      }
    }

  }


  .modal-footer {
    justify-content: space-between;
    height: 5.875rem;
  }

}

.mass-message-select-actions {
  height: 4rem;
  background-color: #fff;
  width: calc(100% - 2rem);
  position: absolute;
  border-radius: 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
}



@media only screen and (max-width: 960px) {
  .remove {
    display: none;
    visibility: hidden;
  }

  .task-detail-content {
    width: 100vw !important;
    margin: 0;

    .modal-header {
      padding-bottom: 0.8125rem;
    }

    .modal-content {
      margin-top: 4.6rem;
      // height: calc(100vh - 5rem);
      max-height: calc(var(--vh, 1vh) * 100 - 5rem);
      height: calc(var(--vh, 1vh) * 100 - 5rem);
      height: calc(var(--vh, 1vh) * 100 - 5rem);
      position: fixed;
    }

    .modal-body {
      height: unset;



      .holder {
        display: flex;
        flex-direction: column;
        height: calc(var(--vh, 1vh) * 100 - 12.5rem);
      }

      .info {
        // height: calc(100vh - 12.5rem);
        height: calc(var(--vh, 1vh) * 100 - 12.5rem);
        padding: 1rem;
        width: 100vw;
        border: none;
        overflow-y: auto;
        overflow-x: hidden;
        position: unset;

        .task-header {
          flex-wrap: wrap;

          .title {
            width: 100%;
            margin-bottom: 1rem;
          }
        }

        #searchBox {

          &:not(:placeholder-shown),
          &:focus {
            width: 5rem;
            padding-left: 0.5rem;
          }
        }

        .attachment {
          .files-update {
            grid-template-columns: 45% 45%;

            .item {
              width: 100%;
            }
          }
        }
      }

      .conversations {
        width: 100vw;
        height: calc(var(--vh, 1vh) * 100 - 11.5rem) !important;
        min-height: unset;

        .search-window {
          width: 100vw;

          &.hide {
            transform: translate(100vw, 0);
          }

        }

        .thread {
          // height: calc(100vh - 17.5rem) !important;
          // height: calc(var(--vh, 1vh) * 100 - 17.5rem);
          // height: unset;
          // flex-grow: 1;

          height: calc(100% - 5rem);

          .message-box-holder {
            max-width: 78vw;
          }

          .message-box {
            max-width: 80vw;

            .quote {
              .comment {
                width: calc(50%);
              }
            }

            .sender-details {
              p {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                // text-overflow: ellipsis;
              }
            }

            .edit-box {


              textarea {
                width: 16rem !important;

                &.reply-textarea {
                  width: 14rem !important;
                }
              }


            }
          }
        }

        .comment-area {
          padding: 1rem;
          background: white;
          // min-height: 5.5rem;

          position: fixed;
          bottom: 0;



          .comment-section {
            box-shadow: 0px 4px 16px 0px #ABB3BC36;
            width: calc(100vw - 2rem);
          }

          textarea {
            width: 88vw;
            max-height: 12.5rem;
            overflow-y: auto;
          }
        }
      }
    }


  }
}

/*
@media only screen and (max-width: 960px) {
  .remove {
    display: none;
    visibility: hidden;
  }

  .task-detail-content {
    width: 100vw !important;
    margin: 0;

    .modal-header {
      padding-bottom: 0.8125rem;
    }

    .modal-content {
      margin-top: 4.6rem;
      // height: calc(100vh - 5rem);
      height: calc(var(--vh, 1vh) * 100 - 5rem);
      position: fixed;
    }

    .modal-body {
      // height: unset;

      .border-right {
        position: unset;
      }

      .nav-tabs {
        display: flex;
        font-weight: 600;
        color: $base-grey-60;
        padding: 0;
        border: none;

        .tab {
          width: 50%;
          border-bottom: 3px solid transparent;
          height: 3.125rem;
          display: flex;
          align-items: center;
          justify-content: center;

          &.active {
            border-bottom: 3px solid #102E51;
            color: #102E51;
          }
        }
      }

      .holder {
        display: flex;
        flex-direction: column;
      }

      .info {
        // height: calc(100vh - 12.5rem);
        height: calc(var(--vh, 1vh) * 100 - 12.5rem);
        padding: 1rem;
        width: 100vw;
        border: none;
        overflow-x: hidden;

        .task-header {
          flex-wrap: wrap;

          .title {
            width: 100%;
            margin-bottom: 1rem;
          }
        }

        #searchBox {

          &:not(:placeholder-shown),
          &:focus {
            width: 5rem;
            padding-left: 0.5rem;
          }
        }

        .attachment {
          .files-update {
            grid-template-columns: auto auto;
          }
        }
      }

      .conversations {
        width: 100vw;
        height: calc(var(--vh, 1vh) * 100 - 11.5rem) !important;

        .search-window {
          width: 100vw;

          &.hide {
            transform: translate(100vw, 0);
          }

        }

        .thread {
          // height: calc(100vh - 17.5rem) !important;
          max-height: calc(var(--vh, 1vh) * 100 - 17.5rem);
          // height: unset;
          flex-grow: 1;

          .message-box-holder {
            max-width: 78vw;
          }

          .message-box {
            .sender-details {
              p {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                // text-overflow: ellipsis;
              }
            }

            .edit-box {


              textarea {
                width: 16rem !important;

                &.reply-textarea {
                  width: 14rem !important;
                }
              }


            }

            .quote {
              .content {
                max-width: 68vw;
              }
            }
          }
        }

        .comment-area {
          padding: 1rem;
          background: white;
          // min-height: 5.5rem;

          .comment-section {
            box-shadow: 0px 4px 16px 0px #ABB3BC36;
            width: calc(100vw - 2rem);
          }

          textarea {
            width: 88vw;
            max-height: 12.5rem;
            overflow: auto;

            &::-webkit-scrollbar {
              display: none;
            }

            -ms-overflow-style: none;
          
            scrollbar-width: none;
           

          }
        }
      }
    }
  }
}
*/