@import "./global.scss";

body {
  background-color: #F3F5F7;
}

.main-wrapper {
  background-image: linear-gradient(#102E51 8.5rem, #F3F5F7 8.5rem 100%);
}

.new-wrapper {
  overflow: auto;
  height: calc(100vh - 6rem);
  padding: 0 2rem;
  //  background-color: #F3F5F7;
  box-shadow: 0px 4px 13px 0px #D1DBE421;

  .page-wrapper {
    box-shadow: 0px 4px 13px 0px #D1DBE421;

    .content.container-fluid {
      min-height: calc(100vh - 11rem);
    }
  }


}

.page-top-bar {
  //overflow: hidden;

  background-color: #fff;
  height: 5rem;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid #CED8E1;
  border-top-right-radius: 0.25rem;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    font-weight: 600;
    font-size: 1.25rem;
    color: #111111;
    white-space: nowrap;
    /* max-width: 50%; */
    text-overflow: ellipsis;
    overflow: hidden;
  }

  form {
    max-width: calc(100% - 2.5rem);
    overflow: hidden;




  }

  .search {
    margin: 0;
    display: block;
    width: 31.9375rem;
    height: 3rem;
    background-color: #F2F7FC;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    padding: 1rem 1.125rem;
    gap: 1rem;

    svg {
      color:
        #677888;
    }

    input {
      border: none;
      width: 65%;
      background-color: #F2F7FC;

    }


  }

  .btn {
    font-weight: 600;

  }

  .btn-light {
    color: #677888;
  }

  .btn-primary,
  .main-btn {
    // min-width: 7.0625rem;
    // height: 2.5rem;
    width: 12rem;
    height: 3rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    &.inner {
      width: 6.8rem;
      height: 2.5rem;
    }

  }


  /*.btn-reset {
    background-color: #A7B9CB;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
  }*/



}

.g-crumb {
  display: flex;
  gap: 0.4rem;
  margin-bottom: 1.5rem;
  color: #677888;
  align-items: center;

  a {
    color: #677888;
    white-space: nowrap;
    max-width: calc(100vw / 2 - 2.5rem);
    text-overflow: ellipsis;
    overflow: hidden;

  }

  .active {
    color: #000000;
  }


}

.page-h1 {
  font-size: 1.125rem;
  font-weight: 600;
}

.m-progress,
.m-lead,
.m-team {
  padding: 1rem 0 !important;

  .gap {
    gap: 0.5rem;
    width: 80%;
  }

  .title {
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 0.875rem !important;
  }

  .react-sweet-progress-symbol {
    color: #40D886;
    font-weight: 500;
    font-size: 0.875rem !important;
    white-space: nowrap;
  }

  .content {
    padding-left: 0.625rem;
    width: 10.25rem;

    &:first-child {}

    &:nth-child(3) {
      // padding-bottom: 0.8rem;
      margin-top: -0.6rem;
    }

    span {
      color: #788898;
      font-size: 0.875rem !important;
      font-weight: 500;
      line-height: 1.0625rem;
      white-space: nowrap;
      display: flex;
      align-items: center;


      svg {
        display: inline;
        width: 14px;
        height: 14px;
        // margin-right: 0.6rem;
      }
    }
  }

  .user-image {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }


}

.btn-date {
  justify-content: space-between;
  white-space: nowrap;
  border-radius: 0.25rem;
  font-size: 0.75rem !important;
  display: flex;
  align-items: center;
  font-weight: 600 !important;
  padding: 0;
  line-height: 0;
  height: 2rem;
  padding: 0 0.25rem;
  min-width: 4.8125rem;

  span {
    line-height: 0;
  }

  svg {
    // margin-right: 0.45625rem;
    width: 14px;
    height: 14px;
    margin-top: -2px;

  }
}

.btn-date.btn-outline-secondary {
  color: #788898;
  border: 1px solid #788898;

  &:hover {
    background: initial;
  }
}

.project-user {
  &.border {
    border: 1px solid #CED8E1 !important;
    border-radius: 0.25rem;
    box-shadow: none;
  }
}

.card.border-bottom {
  border-bottom: 1px solid #CED8E1 !important;
}

.project-details-table {
  color: #4B5458;

  .text-right {
    font-weight: 600;
  }

  td {
    padding: 0.3125rem 0 !important;
  }
}

.staff-grid-row {
  gap: 3.75rem;
  margin: 0;
}

.task-img-collection-update {
  display: flex;
  gap: 0.5rem;
}

.header-actions {
  gap: 1rem;

  .action-btn {
    background: #F2F7FC;
    color: #2A7BB1;
    font-size: 0.875rem;
    font-weight: 600;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;
    border-radius: 0.375rem;
    padding-right: 1rem;
    padding-left: 1rem;

    &:hover {
      // color: #2A7BB1 !important;
    }
  }
}

@media only screen and (max-width: 960px) {
  .new-wrapper {
    overflow: auto;
    height: fit-content;
    padding: 0 1rem;
    height: calc(100vh - 4.5rem);
    padding: 0;
    padding-bottom: 1.5rem;

    .page-wrapper {
      width: 100% !important;

      .projects-grid-row {
        display: grid;
        grid-template-columns: auto;
        grid-gap: 1.5rem;
        gap: 1.5rem;

        .pj-card {
          width: 100%;
          max-width: 100%;
        }
      }


    }

    .page-top-bar {
      padding: 1rem;
      gap: 1.125rem;

      .btn,
      .btn-primary {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 1rem;

        svg {
          width: 1.1rem;
        }

      }

      .nav {
        flex-grow: 1 !important;
        justify-content: start;
      }

      .search {
        width: fit-content;
      }

      .search {
        width: 2rem;
        overflow: hidden;
        gap: 0;
        background-color: #fff;
        padding: 0;

        transition: all 0.35s;
        transition-timing-function: ease;

        input {
          width: 0;
          padding: 0;
        }
      }

      .search:focus-within {
        width: unset;
        background-color: #F2F7FC;
        padding: 1rem;



        input {
          width: 80%;
          margin-left: 1rem;
        }
      }


    }


    .staff-grid-row {
      gap: 1.5rem;
    }

    .pj-card {
      width: 100%;
      max-width: 100%;
    }
  }


  .header-actions {
    gap: 1rem;

    .action-btn {
      padding-right: 0.25rem;
      padding-left: 0.25rem;

      span {
        display: none;
      }
    }
  }


}





@media print {
  .main-wrapper {
    background-image: none;

    .g-crumb {
      display: none;
    }
  }
}