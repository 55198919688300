@import './global.scss';

.single-comment {
  display: flex;
  flex-direction: column;
  // margin-bottom: 20px;
  padding-bottom: 10px;
  // border-bottom: 1px solid rgb(248, 248, 248);

  >div:nth-child(1) {
    display: flex;
    margin-bottom: 10px;

    >img:nth-child(1) {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 15px;
    }

    >div:nth-child(2) {
      >div:nth-child(1) {
        display: flex;
        flex-direction: row;

        >span:nth-child(1) {
          font-size: 14px;
          font-weight: bold;
        }

        >div:nth-child(2) {
          font-size: 12px;
          margin-left: 10px;
          margin-top: 2px;

          >span:nth-child(1) {
            margin-right: 5px;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  >.file-detail-flex {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    padding-right: 5px;
  }

  >.only-comment {
    font-size: 14px;
    opacity: 0.9;
    white-space: pre-wrap;
    margin-left: 55px;
  }

  .comment-reply {
    font-size: 12px;
    white-space: pre-wrap;
    margin-left: 55px;

    span {
      margin-right: 14px;
      cursor: pointer;

      &:hover {
        color: $fex-color;
        text-decoration: underline;
      }
    }
  }

  .comment-replies {
    margin: 5px 5px 5px 35px;
  }
}

.single-comment:last-child {
  margin-bottom: 0px;
}