.overlay {
  height: inherit;
  position: sticky;
  top: 0;
  background-color: #fff;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  z-index: 1002;
}

.sd-cs {
  height: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.75rem 0;
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: sticky;
  top: 0;
  transition: all 0.2s ease-in-out 0s;
  width: 14.5rem;
  z-index: 1001;
  background-color: #fff !important;
  cursor: default;
  border-right: 1px solid #CED8E1;
  border-radius: inherit;

  >.sd-link {
    display: flex;
    padding: 0.75rem 2rem;
    align-items: center;
    margin-bottom: 1rem;
    color: #4B5458;
    font-weight: 600;
    height: 3.125rem;

    &:hover {
      color: #102E51 !important;
    }

    svg {
      color: #A7B9CB
    }

    :nth-child(1) {
      height: 20px;
      width: 20px;
    }

    >span:nth-child(2) {
      margin-left: 10px;
      font-size: 14px;
    }
  }

  .sd-active {


    svg {
      color: #3F96D1;
    }
  }

  >.sd-active,
  >div:hover {
    background-color: #F2F7FC !important;
    color: #102E51 !important;
  }
}

.sd-pr {
  background-color: #051623 !important;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  color: #fff;
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: fixed;
  top: 50px;
  transition: all 0.2s ease-in-out 0s;
  width: 230px;
  z-index: 1001;
  box-shadow: 5px 0 5px -5px #bbb;

  >div:nth-child(1) {
    padding-left: 15px;

    >span:nth-child(2) {
      margin-left: 15px;
      cursor: default;
    }
  }

  >div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    margin: 25px 0 30px;
    padding: 10px 15px;
    // border-top: 1px solid rgb(161, 161, 161);
    // border-bottom: 1px solid rgb(161, 161, 161);

    >a {
      color: #fff !important;
    }
  }

  >div:nth-child(3) {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    cursor: default;
    font-size: 14px;
    overflow-y: auto;

    >div {
      margin-bottom: 40px;

      >div {
        padding: 10px 5px 10px 10px;
        margin-bottom: 10px;
        overflow: hidden;
      }

      >.pr-active,
      >div:hover {
        background-color: #fff !important;
        color: #102E51 !important;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
      }

      .pr-single {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 80px;
        margin-right: 10px;
      }
    }
  }
}

.page-title {
  padding: 0 1rem;

  p {
    font-size: 1.25rem;
    font-weight: 600;
    white-space: nowrap;
    margin-bottom: 0;
    text-transform: capitalize;
  }
}

@media (max-width: 960px) {
  .sd-cs {
    top: 4.5rem;
    position: fixed;
    height: 100vh;
  }

  .overlay {
    top: 4.5rem;
  }

  .overlay.opened {
    background: rgba(14, 29, 47, 0.4) !important;
    width: 100vw;
    height: 100vh;
    position: fixed;
    right: 0;

    .sd-cs {
      left: 0;
    }
  }

  .overlay.closed {
    background: none;
    width: 0;

    .sd-cs {
      left: -14.5rem;
    }
  }
}