@import "./global.scss";

$fex-color: #2569a1;

.sm-lr {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.team-modal {
  width: 55% !important;

  .f-height {
    height: 450px;
  }
}

.team-view-tab {
  color: #677888;
}

.team-view-layout {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #fff;
  width: 100%;
  border-top-right-radius: 0.25rem;

  >.team-view-header {
    height: 70px;
    background-color: #fff;
    margin: 0 3% 10px 3%;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 0 15px;
    border: 1px solid #ededed;
    box-shadow: 0px 4px 13px 0px #D1DBE421;


    >span:nth-child(1) {
      width: 20%;
      padding-right: 10px;
    }

    >div:nth-child(2) {
      display: flex;

      >div {
        display: flex;
        border-left: 1px solid #ccc;
        display: flex;
        padding: 0 15px;
        color: rgb(180, 180, 180);
        font-size: 0.875rem !important;

        >div:nth-child(2) {
          margin-left: 5px;
        }

        >div {
          >span:nth-child(1) {
            margin-right: 5px;
          }
        }
      }
    }

    >div:nth-child(3) {
      display: flex;
      margin-left: auto;

      >span {
        background-color: $fex-color;
        border: 1px solid $fex-color;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .team-view-body {
    display: flex;
    flex-direction: column;
    // height: calc(100vh - 150px);
    margin: 1rem;
    overflow-y: auto;
    font-weight: 600;
    font-size: 0.875rem;

    >div:nth-child(1) {
      display: grid;
      grid-template-columns: 28% 28% 34%;
      column-gap: 5%;
      height: 150px;

      >div {
        background-color: #fff;
        border-radius: 3px;
        padding: 15px 15px 10px 15px;
        border: 1px solid #ededed;
        box-shadow: 0px 4px 13px 0px #D1DBE421;

      }

      .header-title {
        font-weight: 600;
        margin-bottom: 10px;
      }

      >div:nth-child(1) {
        display: flex;
        flex-direction: column;

        >span:nth-child(2) {
          flex: 1;
          color: rgb(151, 151, 151);
        }
      }

      >div:nth-child(2) {
        display: flex;
        flex-direction: column;

        >.team-leaders {
          display: flex;

          .team-leaders-content {
            display: flex;
            position: relative;
            padding-left: 20px;
            align-items: center;

            .avatars {
              display: inline-flex;
              flex-direction: row-reverse;
            }

            .avatar {
              position: relative;
              border-radius: 50%;
              overflow: hidden;
              height: 35px;
              width: 35px;
              margin-left: -20px;
              cursor: default;
            }

            .avatar img {
              height: 35px;
              width: 35px;
            }

            .avatar .av-alternative {
              height: 35px;
              width: 35px;
              border-radius: 50%;
              background-color: #808080;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
            }

            .team-leaders-length {
              color: #2569a1;
              cursor: pointer;
            }

            >.team-leaders-length:hover+.assigned-to-others-layout {
              display: block;
            }

            >.assigned-to-others-layout:hover {
              display: block;
            }

            >.assigned-to-others-layout {
              display: none;
              position: absolute;
              top: 25px;
              left: 90px;
              border: 1px solid #fefefe;
              background-color: #fff;
              width: 180px;
              max-height: 150px;
              overflow-y: auto;
              @extend .sleek-scrollbar;
              padding: 10px 10px 0;
              box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
              z-index: 2;

              >div {
                display: flex;
                width: 100%;
                align-items: center;
                margin-bottom: 15px;

                >img {
                  @extend .sm-lr;
                }

                >span {
                  font-size: 13px;
                  font-weight: 600;
                  opacity: 0.7;
                  font-family: Inter, Roboto, Arial, sans-serif;
                }
              }
            }
          }
        }

        >.team-created-by-name {
          display: flex;
          margin-top: auto;

          >span:nth-child(1) {
            font-weight: 600;
            margin-right: 5px;
          }

          >span:nth-child(2) {
            font-size: 15px;
            color: rgb(151, 151, 151);
          }
        }
      }

      >div:nth-child(3) {
        display: flex;

        >div:nth-child(1) {
          display: flex;
          flex-direction: column;
          width: 50%;

          >div:nth-child(2) {
            display: flex;
            flex-direction: column;

            >div {
              display: flex;
              align-items: center;

              >span {
                font-size: 14px;
              }

              >span:nth-child(1) {
                height: 10px;
                width: 10px;
                border-radius: 50%;
                margin-right: 10px;
              }

              >span:nth-child(2) {
                margin-right: 5px;
              }

              .bg-under-review-legend {
                background-color: #a4ae15;
              }

              .bg-completed-legend {
                background-color: #23b654;
              }

              .bg-active-legend {
                background-color: #0000ff;
              }

              .bg-overdue-legend {
                background-color: #e40b0b;
              }
            }
          }
        }

        >div:nth-child(2) {
          width: 50%;
        }
      }
    }

    >div:nth-child(2) {
      display: grid;
      grid-template-columns: repeat(3, 33.33%);
      background-color: #fff;
      height: 40px;
      margin-top: 40px;

      >span {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
      }

      .task-active-view {
        background-color: #fff;
        color: #3F96D1;
        border-bottom: 3px solid #3F96D1;
      }
    }

    >.team-view-members {
      >div:nth-child(1) {
        display: grid;
        grid-template-columns: 25% 15% 15% 15% 30%;
        margin: 1.875rem 0 1.5rem;
        color: #677888;

        >span:nth-child(1) {
          padding-left: 20px;
        }

        >span:not(:nth-child(1)) {
          text-align: center;
        }
      }

      >div:nth-child(2) {
        >div {
          background-color: #fff;
          border-radius: 3px;
          display: grid;
          grid-template-columns: 25% 15% 15% 15% 30%;
          //  height: 80px;
          align-items: center;
          margin-bottom: 1.5rem;
          font-weight: 500;

          >a:nth-child(1) {
            display: flex;
            align-items: center;

            >img {
              height: 1.1rem;
              width: 1.1rem;
              border-radius: 50%;
              margin: 0 10px 0 20px;
              background-color: #F2F7FC;
            }

            >.av-alternative2 {
              height: 1.1rem;
              width: 1.1rem;
              border-radius: 50%;
              background-color: #F2F7FC;
              color: #5D696F;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              margin: 0 10px 0 20px;
            }
          }

          >span {
            text-align: center;
          }
        }

        >div:nth-child(even) {
          //  background-color: #fafafa;
        }
      }
    }
  }

  .team-view-projects {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem 1rem !important;
    border-radius: 0.5rem;
    background-color: #F2F7FC;
    gap: 1.5rem;

    >div:nth-child(1) {
      padding-left: 0 !important;
    }
  }

  .team-view-tasks {
    display: flex;
    flex-direction: column;
    padding-top: 40px !important;

    .task-wrapper {
      padding: 0 !important;

      .task-list-body {
        overflow: hidden !important;
      }
    }
  }

  .team-view-none {
    height: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .member-view-body {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 150px);
    margin: 20px 3%;
    overflow-y: auto;

    >div:nth-child(1) {
      display: grid;
      grid-template-columns: 30% 45%;
      column-gap: 25%;

      >div {
        background-color: #fff;
        border-radius: 3px;
        height: 150px;
        padding: 20px;
      }

      .header-title {
        font-weight: 600;
        margin-bottom: 10px;
      }

      >div:nth-child(1) {
        display: flex;
        flex-direction: column;

        >div:nth-child(2) {
          display: flex;
          align-items: center;

          >img {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            margin-right: 10px;
          }

          >.av-alternative {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background-color: #808080;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 17px;
            margin-right: 10px;
          }

          >.team-created-by-name {
            display: flex;
            flex-direction: column;

            >span:nth-child(2) {
              font-size: 15px;
              color: rgb(151, 151, 151);
            }
          }
        }
      }

      >div:nth-child(2) {
        display: flex;

        >div {
          width: 50%;
        }

        >div:nth-child(1) {
          display: flex;
          flex-direction: column;

          >div:nth-child(2) {
            display: flex;
            flex-direction: column;

            >div {
              display: flex;
              align-items: center;

              >span {
                font-size: 14px;
              }

              >span:nth-child(1) {
                height: 10px;
                width: 10px;
                border-radius: 50%;
                margin-right: 10px;
              }

              >span:nth-child(2) {
                margin-right: 5px;
              }

              .bg-assigned-legend {
                background-color: blue;
              }

              .bg-completed-legend {
                background-color: rgb(12, 221, 12);
              }

              .bg-active-legend {
                background-color: rgb(255, 238, 0);
              }
            }
          }
        }
      }
    }

    >div:nth-child(2) {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      background-color: #fff;
      height: 40px;
      margin-top: 40px;

      >span {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
      }

      .task-active-view {
        background-color: #fff;
        color: #3F96D1;
        border-bottom: 3px solid #3F96D1;
      }
    }
  }
}

.hs-bar-container {
  // height: 18px;

  >svg {
    border-radius: 0;
    color: inherit;

    text {
      fill: inherit !important;
      opacity: 0.4;
      font-weight: 600;
      font-size: 14px !important;
    }

  }
}

@media (max-width: 960px) {
  .team-view-layout {
    padding-right: 0;
    margin-left: 0;
    width: 100vw;
  }
}