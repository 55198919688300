.switch {
  position: relative !important;
  display: inline-block !important;
  width: 46px !important;
  height: 24px !important;
}

.switch input {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
}

.slider {
  position: absolute !important;
  cursor: pointer !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: #ccc !important;
  -webkit-transition: 0.2s !important;
  transition: 0.25s !important;
}

.slider:before {
  position: absolute !important;
  content: "" !important;
  height: 16px !important;
  width: 16px !important;
  left: 4px !important;
  bottom: 4px !important;
  background-color: white !important;
  -webkit-transition: 0.25s !important;
  transition: 0.25s !important;
}

input:checked+.slider {
  background-color: #3F96D1 !important;
}

input:focus+.slider {
  box-shadow: 0 0 1px #3F96D1 !important;
}

input:checked+.slider:before {
  -webkit-transform: translateX(22px) !important;
  -ms-transform: translateX(22px) !important;
  transform: translateX(22px) !important;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px !important;
}

.slider.round:before {
  border-radius: 50% !important;
}