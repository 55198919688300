.reports-container {
  display: flex;
  flex-direction: column;
  padding: 20px 30px 50px;

  >.dashboard-row-one {
    display: grid;
    grid-template-columns: 22% 22% 22% 22%;
    column-gap: 4%;

    >div {
      display: flex;
      height: 100px;
      border-radius: 5px;
      padding: 0 20px;
      align-items: center;
      color: #fff;

      >span:nth-child(1) {
        margin-right: 20px;
      }

      >div:nth-child(2) {
        display: flex;
        flex-direction: column;

        >span:nth-child(2) {
          font-size: 22px;
          padding-left: 10px;
        }
      }
    }

    >div:nth-child(1) {
      background-color: #225377;

      >span:nth-child(1) {
        padding: 10px;
        border-radius: 50%;
        background-color: #2d5c7e;
        box-shadow: 10px -10px #225377;
        -moz-box-shadow: 10px -10px #225377;
        -webkit-box-shadow: 10px -10px #225377;
        -o-box-shadow: 10px -10px #225377;
      }
    }

    >div:nth-child(2) {
      background-color: #287ab7;

      >span:nth-child(1) {
        padding: 10px;
        border-radius: 50%;
        background-color: #3381bb;
        box-shadow: 10px -10px #287ab7;
        -moz-box-shadow: 10px -10px #287ab7;
        -webkit-box-shadow: 10px -10px #287ab7;
        -o-box-shadow: 10px -10px #287ab7;
      }
    }

    >div:nth-child(3) {
      background-color: #277ab7;

      >span:nth-child(1) {
        padding: 10px;
        border-radius: 50%;
        background-color: #3281bb;
        box-shadow: 10px -10px #277ab7;
        -moz-box-shadow: 10px -10px #277ab7;
        -webkit-box-shadow: 10px -10px #277ab7;
        -o-box-shadow: 10px -10px #277ab7;
      }
    }

    >div:nth-child(4) {
      background-color: #3aa4f2;

      >span:nth-child(1) {
        padding: 10px;
        border-radius: 50%;
        background-color: #44a9f3;
        box-shadow: 10px -10px #3aa4f2;
        -moz-box-shadow: 10px -10px #3aa4f2;
        -webkit-box-shadow: 10px -10px #3aa4f2;
        -o-box-shadow: 10px -10px #3aa4f2;
      }
    }
  }

  .header-one {
    background-color: #0a6db9;
    width: 100%;
    color: #fff;
    padding: 20px 0 20px 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-weight: 600;
  }

  .none-found-text {
    display: block;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .sp-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  .sp-initial {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .sp-view-more {
    margin: auto auto 20px auto;
    background-color: #cbe0f0;
    border-radius: 3px;
    padding: 5px 15px;
    font-size: 13px;
    color: #0d66ab;
  }

  .dashboard-row-two {
    display: grid;
    grid-template-columns: 47.5% 47.5%;
    column-gap: 5%;
    margin-top: 40px;

    >div {
      height: 400px;
      border-radius: 5px;
      background-color: #fff;
      border: 1px solid #ededed;
      box-shadow: 0px 4px 13px 0px #D1DBE421;

    }

    >div:nth-child(1) {
      display: flex;
      flex-direction: column;

      >div:nth-child(2) {
        background-color: #0d66ab;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        color: #fff;
        padding: 10px 20px;
        font-size: 14px;

        >span:nth-child(2) {
          text-align: center;
        }
      }

      >div:nth-child(3) {
        display: flex;
        flex-direction: column;
        padding-top: 10px;

        >div:nth-child(even) {
          background-color: #fbfbfb;
        }

        >div {
          display: grid;
          grid-template-columns: 8% 50% 30%;
          height: 50px;
          align-items: center;
          column-gap: 3%;
          padding: 0 20px;
        }
      }
    }

    >div:nth-child(2) {
      padding: 40px;

      >span:nth-child(1) {
        margin-bottom: 30px;

      }

      >div {
        margin: 40px 0 0;
      }
    }
  }

  >.dashboard-row-three {
    display: grid;
    grid-template-columns: 47.5% 47.5%;
    column-gap: 5%;
    margin-top: 60px;

    >div {
      height: 400px;
      border-radius: 5px;
      background-color: #fff;
      border: 1px solid #ededed;
      box-shadow: 0px 4px 13px 0px #D1DBE421;

    }

    >div:nth-child(1) {
      display: flex;
      flex-direction: column;

      >div:nth-child(2) {
        background-color: #0d66ab;
        display: grid;
        grid-template-columns: 45% 30% 30%;
        color: #fff;
        padding: 10px 20px;
        font-size: 14px;
      }

      >div:nth-child(3) {
        display: flex;
        flex-direction: column;
        padding-top: 10px;

        >div:nth-child(even) {
          background-color: #fbfbfb;
        }

        >div {
          display: grid;
          grid-template-columns: 7% 32% 28% 20%;
          height: 50px;
          align-items: center;
          column-gap: 4%;
          padding: 0 10px;

          >span {
            word-wrap: break-word;
          }
        }
      }
    }

    >div:nth-child(2) {
      display: flex;
      flex-direction: column;

      >div:nth-child(2) {
        background-color: #0d66ab;
        display: grid;
        grid-template-columns: 50% 50%;
        color: #fff;
        padding: 10px 20px;
        font-size: 14px;

        >span:nth-child(2) {
          text-align: center;
        }
      }

      >div:nth-child(3) {
        display: flex;
        flex-direction: column;
        padding-top: 10px;

        >div:nth-child(even) {
          background-color: #fbfbfb;
        }

        >div {
          display: grid;
          grid-template-columns: 60% 30%;
          height: 70px;
          align-items: center;
          column-gap: 10%;
          padding: 0 20px;

          >div:nth-child(1) {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
              font-size: 14px;
              font-weight: bold;
            }

            >div:nth-child(2) {
              display: flex;
              font-size: 12px;
              opacity: 0.8;

              >span {
                margin-right: 3px;
                margin-top: 0;
              }

              >span:nth-child(2) {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 730px) {
    .dashboard-row-one {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1rem;

      >div {
        //   flex: 0 48%;
        width: calc(100%/2 - 0.5rem);
      }
    }
  }

  @media (max-width: 960px) {
    >.dashboard-row-two {
      grid-template-columns: 100%;
      row-gap: 20px;

      >div:nth-child(2)>div {
        margin-left: -6.2rem;
      }
    }


    >.dashboard-row-three {
      grid-template-columns: 100%;
      row-gap: 20px;
    }
  }
}

@media print {

  .header,
  .sd-cs,
  .hide-on-print,
  .page-top-bar {
    display: none !important;
  }

  .page-wrapper,
  .new-wrapper {
    width: 100vw !important;
    padding: 0;
    margin: 0;
  }
}