@import "./global.scss";

#close-btn {
  color: #788898;
  background: #F2F7FC;
  border: none;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

form {
  ::placeholder {
    color: #677888;
    font-size: 0.875rem;
  }

  .form-group {
    margin-bottom: 1.5rem !important;
    position: relative;
  }

  label {
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 100%;
  }

  .form-check-label {
    margin: 0;
  }

  .form-control {
    padding: 0.75rem;
    border: 1px solid #A7B9CB;
    font-weight: 400;
    height: auto;
    border-radius: 0.5rem;
  }

  textarea {
    height: 7.25rem;
    resize: none;
  }

  select {

    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-image: url('');
    background-position: 100% center;
    background-repeat: no-repeat;
    outline: none;
  }

  .date-duration {
    width: 100%;
    justify-content: space-between;

    .starting-date {
      width: 15.9375rem;
    }

    .set-duration {
      width: 16rem;
    }

    .duration {
      width: 5.8125rem;
    }

    .month {
      width: 9.6875rem;
    }
  }

  .form-icon {
    position: absolute;
    top: 2.8rem;
    right: 0.8rem;
  }

  .chevron-down {
    position: absolute;
    right: 0.8rem;
    top: 1.2rem;
    color: #97A3B0;
    pointer-events: none;
  }

  .checkbox {
    font-size: 0.875rem !important;
    color: #97A3B0;
  }

  .check-form {
    padding: 0.5rem 0 0.5rem 0;

  }
}

.form-progress {
  width: 11.21875rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  span {
    font-size: 1rem;
    font-weight: 600;
  }

  >div {
    position: relative;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;

    .divider {
      position: absolute;
      width: 100%;
      border: 2px solid #F2F7FC;
      top: 50%;
      z-index: 1;
    }
  }

  svg {
    z-index: 2;
    pointer-events: fill;
  }
}

.project-backdrop {
  z-index: 1050;
  // backdrop-filter: blur(1px);

}

.project-modal {
  width: 36.4375rem !important;
  height: 41.75rem !important;
  max-width: none !important;
  max-height: none !important;
  overflow: hidden !important;

  .modal-content {
    border: none;

  }

  .modal-header {
    border-bottom: none;
    align-items: center;
    padding: 1rem 1rem 1.5rem 1.5rem;

    .modal-title,
    h4 {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 100%;

    }
  }


  .modal-body {
    padding: 0;

    .search {
      padding: 0 1.5rem 1.375rem 1.5rem;

      .search-box {
        // width: 33.4375rem;
        width: 100%;
        height: 3.125rem;
        border: 1px solid #A7B9CB;
        padding: 0.75rem;
        border-radius: 0.5rem;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          border: none;
          height: 95%;
          width: 80%;
          font-size: 0.875rem !important;
        }

        .search-icon {
          color: #788898;
        }
      }
    }

    .user-wrapper {
      height: 26.5rem;

      .action {
        display: grid;
        grid-template-columns: 2.5rem auto;
        padding-right: 1.6rem;
        padding-left: 1.6rem;
        padding-bottom: 1.625rem;
      }

      .user-list {
        // width: 34.4375rem;
        max-height: 23rem;
        // 34.4375
        margin-right: 0.5rem;
        padding-left: 1.6rem;
        overflow-y: auto;
        overflow-x: hidden;

        @extend .sleek-scrollbar;
        @extend .white-scroll;

        .user {
          display: grid;
          grid-gap: 0;
          grid-template-columns: 2.5rem 4rem 19.25rem auto;
          width: 100%;
          align-items: center;
          margin-bottom: 1rem;

          .grid-item {
            font-size: 0.875rem !important;

            svg {
              * {
                pointer-events: all
              }
            }



            .checked {
              // color: $primary;
            }

            .not-checked {
              color: #677888;
            }

            img {
              border-radius: 50%;
              width: 3rem;
              height: 3rem;
            }

            .name {
              color: #102E51;
              font-weight: 600;
              line-height: 160%;
              font-size: 0.875rem !important;
            }

            .email {
              color: #97A3B0;
              line-height: 160%;
              font-size: 0.875rem !important;
            }

            .is-leader {
              width: 6.6875rem;
              height: 2.375rem;
              background: #F2F7FC;
              display: block;
              border-radius: 100px;
              padding: 0.5rem 1rem;
              padding-right: 0;
              cursor: pointer;

              * {
                display: block;
                line-height: 160%;
              }

              display: flex;
              align-items:center;

              :nth-child(1) {
                font-size: 0.875rem !important;
                font-weight: 500;
                margin-right: 9px;
              }

              :nth-child(2) {
                color: #788898;
                // font-size: 0.6rem !important;
                margin-top: 1px;
              }
            }

            .not-leader {
              color: $primary;
              font-size: 0.875rem !important;
              padding-left: 0.5rem;
              cursor: pointer;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

    }


  }

  .modal-footer {
    height: 6.125rem;
    // position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: #ffffff;
    padding: 1rem 1.5rem 1rem 1.5rem;


    * {
      margin: 0;
    }

    .btn {
      padding: 0.875rem 1.75rem;
      font-weight: 600;
      font-size: 0.875rem;
    }

    .form-progress {
      width: 11.2rem !important;
    }


    .btn-primary {}

    .btn-outline-primary {}
  }

}

.stage-1 {
  width: 36.4rem !important;
  height: 38.3rem !important;
  max-width: none !important;
  max-height: none !important;

  .modal-content {
    border: none;
    // height: 38.3rem !important;
  }

  .modal-header {
    border-bottom: none;
    align-items: center;
    padding: 1rem 1rem 1rem 1.5rem;

    .modal-title,
    h4 {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 100%;
    }
  }

  .modal-body {
    padding: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @extend form;
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border: none;
    padding: 1.5rem;
    height: 6.125rem;
    height: auto;

    .form-progress {
      margin: 0;
    }

    .btn {
      padding: 0.875rem 2.5rem;
      border-radius: 0.5rem;
      font-weight: 600;
      margin: 0;
    }
  }

}

.stage-2 {

  width: 36.4375rem !important;
  height: auto !important;
  max-width: none !important;
  max-height: none !important;

  .modal-content {
    height: auto !important;
    border: none;
  }

  .modal-header {
    align-items: center;
    border: none;

    .modal-title,
    h4 {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 100%;
    }
  }

  .modal-body {
    /*padding: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    */
    padding: 0;
    @extend form;

    form {
      .input-holder {
        padding: 1.5rem;
        padding-top: 1rem;
        padding-bottom: 0rem;


        .client,
        .project-cost {
          width: 15.9375rem;

          .holders {
            padding: 0.75rem 1rem;
            border: 1px solid #A7B9CB;
            font-weight: 400;
            height: auto;
            border-radius: 0.5rem;

            .currency,
            .amount {
              border: none;
              padding: 0;
              border-radius: 0;
            }

            .currency {
              border-right: inherit;
              padding-right: inherit;
              width: 1.8rem;
            }

            .amount {
              padding-left: inherit;
            }
          }
        }

        .check-btns {
          .btn {
            padding: 0.5rem 1rem;
            margin-right: 1.4375rem;
            border-radius: 0.5rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: space-between;

            svg {
              margin-right: 0.68rem;
              width: 1.125rem;
              height: 1.125rem;
            }
          }

          .btn.on {
            border: 1px solid #3F96D1;
            background: #F2F7FC;
            color: #102E51;
          }

          .btn.off {
            color: #97A3B0;
            border: 1px solid #A7B9CB
          }

        }

        .add-file {
          margin-bottom: 1rem;

          .btn {
            font-weight: 600;
            font-size: 0.875rem !important;
          }
        }
      }

      >div {
        justify-content: space-between;
      }


      .project-files {
        padding-left: 1.5rem;
        margin-right: 0.75rem;
        max-height: 7rem !important;
        overflow: auto;
        overflow-x: hidden;
        @extend .sleek-scrollbar;

        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar {
          background-color: #fff !important;
          box-shadow: none;
        }



        .files {
          display: grid;
          // grid-template-columns: 13.0625rem 13.0625rem 13.0625rem 13.0625rem;
          grid-template-columns: 10.8125rem 10.8125rem 10.8125rem;
          grid-gap: 0.5rem;
          padding-right: 0.5rem;


          .grid-item {
            border: 1px solid #A7B9CB;
            height: 3.25rem;
            border-radius: 0.375rem;
            padding: 0.25rem 0.5rem 0.25rem 0.958125rem;
            padding-right: 12.69px;
            position: relative;

            >div {

              .file-icon {
                margin-right: 0.708125rem;
                width: 1.833125rem;
              }

              .file-details {
                :nth-child(1) {
                  font-size: 0.875rem !important;
                  font-weight: 600;
                  color: #231F20;
                }

                :nth-child(2) {
                  color: #788898;
                  font-size: 0.75rem !important;
                }
              }
            }

            .close {
              color: #788898;
              // width: 0.664375rem;
              font-size: 1rem;
              margin-right: -2px;
              cursor: pointer;
            }

            .react-sweet-progress-circle {
              cursor: pointer;
            }

            .cancel {
              color: $base-grey-100;
              //font-size: 0.65rem !important;
              // cursor: pointer;
              font-weight: 500;
            }

            /*.cancel {
              visibility: hidden;
  
              &::after {
                content: 'x';
                visibility: visible;
                margin-right: 30px;
              }
            }
            */

          }
        }
      }
    }

  }

  .modal-footer {
    height: 6.125rem;
    // position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: #ffffff;
    padding: 1rem 1.5rem 1rem 1.5rem;
    border: none;

    font-weight: 600;

    * {
      margin: 0;
    }

    .btn {
      padding: 0.875rem 1.75rem;
      font-weight: 600;
      font-size: 0.875rem;
    }

    .btn-primary {}

    .btn-outline-primary {}
  }
}

@media only screen and (max-width: 960px) {

  .stage-1,
  .stage-2,
  .project-modal {
    width: 96vw !important;
  }

  .stage-1 {
    .modal-content {
      height: auto !important;
    }
  }

  .stage-2 {
    .modal-body {
      .check-btns {
        flex-wrap: wrap;

        button {
          margin-bottom: 0.5rem;
        }
      }
    }

    .modal-footer {
      height: auto !important;

      .form-progress {
        margin-bottom: 1rem;
      }
    }
  }

  .project-modal {
    .modal-footer {
      height: auto !important;

      .form-progress {
        margin-bottom: 1rem;
      }
    }
  }
}