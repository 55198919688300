#onboard-anchor {
  width: 4px;
  height: 4px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999999;
}

/*
.introjs-tooltip * {
  color: #ECF4FA;
}

.introjs-overlays,
.introjs-helperLayer {
  display: none;
}

.introjs-tooltip {
  background-color: #012E54 ;
  min-width: 350px ;
  max-width: 350px ;

  .introjs-skipbutton {
    color: #ECF4FA;
    padding-right: 0;
    // font-size: 13px ;
    font-weight: normal;
  }

  .introjs-skipbutton:hover,
  .introjs-skipbutton:focus {
    color: #1893CF;

  }

  .introjs-arrow.top,
  .introjs-arrow.top-middle {
    border-bottom-color: #012E54;
  }

  .introjs-arrow.bottom {
    border-top-color: transparent;
  }

  .introjs-tooltiptext {
    display: flex;
    align-items: center;

    :first-child {
      width: 15%;
      margin-right: 1rem;
      border-radius: 5px;

      img {
        margin: 'auto'
      }
    }

    :last-child {
      width: 85%;
    }
  }

  .introjs-tooltipbuttons {
    border: none;
    padding: 1rem;

    .introjs-button {
      background-color: #1a4365;
      color: #ECF4FA;
      text-shadow: none;
      border: none;
      margin-left: 1rem;
    }

    .introjs-prevbutton,
    .introjs-nextbutton {
      float: none;
    }
  }
}
*/

.intro-ga {
  position: fixed !important;
  // margin-left: -142.5px !important;
  // margin-top: 10px !important;
}

.introjs-overlays,
.introjs-helperLayer {
  //  display: none;
}

.introjs-helperLayer {
  border-radius: 100px !important;
}

.introjs-tooltip {
  width: 23.5625rem !important;
  // height: 32.5rem !important;
  max-width: 23.5625rem !important;

  .introjs-tooltip-header {
    // display: none;
    position: absolute;
    top: 0;
    width: 23.5625rem !important;

    .introjs-skipbutton {
      color: #3F96D1 !important;
      font-size: 0.875rem;

      &::after {
        // content: " Tour";
      }
    }

    padding-top: 1.25rem;
  }

  .introjs-tooltiptext {

    // position: fixed;
    // bottom: 0;
    // z-index: 1000000;
    background: #ffffff;
    width: 23.5625rem !important;
    // height: calc(32.5rem - 6.5rem) !important;
    padding: 1.5rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0.25rem;


    .onboard-header {
      display: flex;
      justify-content: space-between;
      text-align: start;
      margin-bottom: 0.5rem;

      .page {
        color: #454E53;
      }

      .skip {
        color: #3F96D1;
        display: none;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: end;
    }

    .image {
      margin-top: 2rem;

      svg {
        margin: auto;
      }
    }

    .title {
      margin-top: 2rem;
      color: #102E51;
      font-size: 1.25rem;
      margin-bottom: 0.75rem;
      line-height: 100%;
      font-weight: 600;
    }

    .desc {
      color: #5D696F;
      margin: 0;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 160%;
    }

    button {
      padding: 0.875rem 1.75rem;
      background: #3F96D1;
      color: #ffffff;
      border: none;
      margin-top: 2rem;
      border-radius: 0.5rem;
    }

    button.next {
      margin-right: 0.75rem;
      background: #ffffff;
      color: #3F96D1;
      border: 1px solid #9BAAB9
    }

  }


  .introjs-tooltipbuttons {
    width: 23.5625rem !important;
    display: flex;
    justify-content: center;
    border: none;
    padding-bottom: 1.5rem;

    .introjs-prevbutton,
    .introjs-nextbutton {
      padding: 0.875rem 1.75rem;
      background: #3F96D1;
      color: #ffffff;
      border: none;
      border-radius: 0.5rem;
      text-shadow: none;

      &:focus,
      &:active,
      &:visited {
        border: none !important;
      }
    }


    .introjs-prevbutton {
      margin-right: 0.75rem;
      background: #ffffff;
      color: #3F96D1;
      border: 1px solid #3F96D1;
    }
  }

}