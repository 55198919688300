* {
  border-color: #CED8E1; }

body {
  overflow: hidden; }

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.border {
  color: #A7B9CB;
  border-color: #A7B9CB; }

.global-backdrop,
.modal-backdrop {
  background: rgba(14, 29, 47, 0.54);
  /*&+.modal-backdrop {
    display: none;
  }*/ }
  .global-backdrop.show,
  .modal-backdrop.show {
    opacity: 1; }
  .global-backdrop.max-z-index,
  .modal-backdrop.max-z-index {
    z-index: 10050; }
    .global-backdrop.max-z-index + .modal,
    .modal-backdrop.max-z-index + .modal {
      z-index: 10052 !important; }
  .global-backdrop.max-z-index-2,
  .modal-backdrop.max-z-index-2 {
    z-index: 10060; }
    .global-backdrop.max-z-index-2.blur,
    .modal-backdrop.max-z-index-2.blur {
      backdrop-filter: blur(1px); }
    .global-backdrop.max-z-index-2 + .modal,
    .modal-backdrop.max-z-index-2 + .modal {
      z-index: 10062 !important; }

.picker-dialog-bg {
  z-index: 20000 !important; }

.picker-dialog {
  z-index: 20001 !important; }

/*.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  @extend .sleek-scrollbar;
}
*/
textarea {
  resize: none; }

.Toastify .Toastify__toast-container {
  width: 26.3125rem;
  z-index: 10080 !important; }

.Toastify .Toastify__toast-body {
  padding: 0;
  margin: 0; }
  .Toastify .Toastify__toast-body .content .icon {
    margin-right: 1.2rem; }
  .Toastify .Toastify__toast-body .content .title {
    font-size: 1rem; }
  .Toastify .Toastify__toast-body .content .message {
    color: #A7B9CB;
    font-size: 0.875rem;
    word-break: break-all; }

.Toastify .Toastify__toast.Toastify__toast--success {
  background: #2C2C2C;
  margin: 0;
  padding: 1rem 1.1875rem;
  min-height: auto;
  width: 26.3125rem;
  margin-bottom: 5px;
  border-radius: 0.375rem; }

.rdtPicker {
  right: 0 !important;
  top: -272px !important;
  width: 100% !important; }

.btn-outline-primary {
  color: #3F96D1;
  border-color: #3F96D1; }
  .btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:visited {
    background: #3F96D1;
    color: #fff; }

.white-scroll::-webkit-scrollbar-track, .white-scroll::-webkit-scrollbar {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none;
  width: 0.375rem !important;
  height: 0.375rem !important; }

.white-scroll::-webkit-scrollbar-thumb {
  box-shadow: none; }

.sleek-scrollbar, textarea, .kanban-list > [data-rbd-droppable-id], .task-2-chat .task-2-chat-body, .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > .assigned-to-details > .assigned-to-others-layout, .task-2-chat .task-2-chat-body > div:nth-child(3) > span:nth-child(2) {
  /*scrollbar-color: #A7B9CB #D8E6F5;
  scrollbar-width: thin;
  scrollbar-highlight-color: red;
  */
  z-index: inherit; }
  .sleek-scrollbar::-webkit-scrollbar-track, textarea::-webkit-scrollbar-track, .kanban-list > [data-rbd-droppable-id]::-webkit-scrollbar-track, .task-2-chat .task-2-chat-body::-webkit-scrollbar-track, .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > .assigned-to-details > .assigned-to-others-layout::-webkit-scrollbar-track, .task-2-chat .task-2-chat-body > div:nth-child(3) > span:nth-child(2)::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px #ddd;
    box-shadow: inset 0 0 4px #ddd;
    border-radius: 50px;
    background-color: #D8E6F5; }
  .sleek-scrollbar::-webkit-scrollbar, textarea::-webkit-scrollbar, .kanban-list > [data-rbd-droppable-id]::-webkit-scrollbar, .task-2-chat .task-2-chat-body::-webkit-scrollbar, .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > .assigned-to-details > .assigned-to-others-layout::-webkit-scrollbar, .task-2-chat .task-2-chat-body > div:nth-child(3) > span:nth-child(2)::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #A7B9CB; }
  .sleek-scrollbar::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb, .kanban-list > [data-rbd-droppable-id]::-webkit-scrollbar-thumb, .task-2-chat .task-2-chat-body::-webkit-scrollbar-thumb, .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > .assigned-to-details > .assigned-to-others-layout::-webkit-scrollbar-thumb, .task-2-chat .task-2-chat-body > div:nth-child(3) > span:nth-child(2)::-webkit-scrollbar-thumb {
    border-radius: 50px;
    -webkit-box-shadow: inset 0 0 4px #ddd;
    box-shadow: inset 0 0 4px #ddd;
    background-color: #A7B9CB; }

.spec-input {
  height: 30px !important; }

.spec-btn {
  background-color: #2569a1 !important;
  color: #fff; }

.m-h-between {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  color: #000000;
  font-size: 1.25rem; }

.modal-lg {
  width: 58% !important; }

.modal-md {
  width: 45% !important; }

.bg-img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #d6d6d6;
  display: flex;
  justify-content: center;
  align-items: center; }
  .bg-img :nth-child(1) {
    color: #6d6d6d; }

.r-img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  border: 1px solid #e4e4e4;
  object-fit: cover; }

.fm-input1 {
  width: 100% !important; }
  .fm-input1::placeholder {
    color: #677888; }

.invisible {
  visibility: hidden; }

.unchecked-box {
  height: 15px;
  width: 15px;
  background-color: #ccc;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .unchecked-box .check-mark-icon {
    height: 15px;
    width: 15px;
    color: #fff; }

.add-new-email-container {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  align-items: center; }

.dy-text {
  margin-right: 10px; }

.em-text {
  color: blue; }

.yes-text {
  background-color: #2569a1;
  color: #fff;
  border-radius: 3px;
  padding: 10px 20px;
  margin-top: 15px; }

.custom-height {
  overflow-y: auto;
  height: 100vh; }

.custom-color-picker-holder {
  width: 14rem;
  height: fit-content;
  background: #fff;
  border-radius: 6px;
  z-index: 99;
  padding: 0.5rem;
  padding-bottom: 0;
  font-weight: 500; }

.custom-color-picker {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 0.5rem 0 0.5rem 0;
  grid-gap: 0.5rem;
  justify-content: space-around; }

.color-picker-contents {
  width: 14rem;
  height: fit-content;
  background: #fff;
  border-radius: 6px;
  z-index: 99;
  padding: 1.5rem;
  font-weight: 500;
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 0.5rem 0 0.5rem 0;
  grid-gap: 1rem;
  margin-left: -6px; }

.select-color-box {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  border-radius: 50%;
  color: #677888;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .select-color-box:hover {
    opacity: 0.9; }

.user-image {
  background: #DCECF7;
  color: #5D696F;
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center; }

.p-hover {
  border: 1px solid #ffffff; }
  .p-hover:hover {
    border: 1px solid #3F96D1;
    box-sizing: border-box;
    width: initial; }

.bg-dark-blue {
  background-color: #012E54 !important; }

.text-14 {
  font-size: 0.875rem !important; }

.text-16 {
  font-size: 1rem !important; }

.bg-primary-light-blue-20 {
  background-color: #F2F7FC; }

.message-focus {
  animation: blink 0.85s linear infinite; }

@keyframes blink {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

.new-message {
  z-index: 1000;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #FFFFFF;
  position: absolute;
  bottom: 5.5rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center; }
  .new-message span {
    position: absolute;
    right: 0;
    top: -5px; }

mark {
  background-color: yellow; }

.color-box-custom-2 .picker-holder {
  border: 1px solid #A7B9CB;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #A7B9CB;
  width: 5.75rem;
  height: 3rem; }

.after-box-custom::after {
  position: absolute; }

.no-click {
  pointer-events: none; }

.tool-parent:hover .label-tooltip {
  opacity: 1;
  position: absolute; }

.label-tooltip {
  position: fixed;
  right: 0;
  left: 0;
  max-width: 18rem;
  height: fit-content;
  top: calc(-180% - 0.59em);
  background-color: #3F96D1;
  color: #FFFFFF;
  border-radius: 0.25rem;
  padding: 0.5rem;
  opacity: 0;
  transition: opacity 0.35s;
  transition-timing-function: ease; }
  .label-tooltip::after {
    content: '';
    position: absolute;
    left: 1rem;
    bottom: -0.8rem;
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid #3F96D1;
    clear: both; }

.general-menu {
  width: fit-content;
  background: #fff;
  color: #323232;
  border-radius: 2px;
  font-style: meduim;
  line-height: 22px;
  box-shadow: 0px 8px 1rem 0px #bdc9d728;
  z-index: 20;
  transform: translate(-60px, 50px) !important;
  padding: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .general-menu.general-dropdown-menu {
    min-width: 10.25rem;
    height: fit-content; }
  .general-menu .dropdown-item {
    font-size: 0.875rem !important;
    font-weight: 500;
    padding: 0.6rem 1rem;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 1; }
    .general-menu .dropdown-item svg {
      color: #788898;
      margin-right: 0.5rem; }
    .general-menu .dropdown-item .btn svg {
      color: inherit; }

.disabled-share .bg {
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; }

.disabled-share .content {
  z-index: 2; }

.old-msg-btn:disabled {
  background-color: red; }

#close-btn {
  color: #788898;
  background: #F2F7FC;
  border: none;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  #close-btn:hover {
    filter: brightness(85%); }

img {
  text-indent: -9999px; }

.username-tag {
  cursor: pointer; }

.invited-icon {
  width: 1rem !important; }

.file-more {
  border: none;
  background: inherit;
  width: 1rem;
  margin-right: 2px; }
  .file-more svg {
    width: 100% !important;
    color: #788898; }

.dropup {
  right: 0;
  top: 0;
  background: transparent;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center; }
  .dropup .file-more.btn-primary {
    background-color: inherit;
    color: #A7B9CB;
    border: none;
    width: 1.6rem;
    min-width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%; }
    .dropup .file-more.btn-primary svg {
      transform: scale(0.8); }
    .dropup .file-more.btn-primary:hover {
      background-color: rgba(0, 0, 0, 0.062); }
    .dropup .file-more.btn-primary:active {
      background-color: inherit; }
  .dropup .menu {
    z-index: 10;
    font-size: 0.875rem !important;
    background: #ffffff;
    box-shadow: 0px 4px 1rem 0px #B8C5C540;
    padding: 0.25rem;
    border-radius: 0.25rem; }
    .dropup .menu .dropdown-item {
      padding: 0.5rem 1rem;
      font-size: 0.875rem !important;
      font-weight: 500;
      margin: 0;
      border-radius: 0.25rem;
      line-height: unset;
      display: flex;
      gap: 0.5rem;
      align-items: center; }

.file-img .overlay {
  display: none;
  height: 3rem;
  width: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(180deg, #000000 -141.67%, rgba(0, 0, 0, 0) 100%);
  z-index: 1; }

.file-img .image-action {
  z-index: 3; }
  .file-img .image-action .file-more.btn-primary {
    background: #F2F7FC; }
    .file-img .image-action .file-more.btn-primary:hover {
      background-color: #F2F7FC; }

.file-img .dropup {
  display: none;
  z-index: 3; }

.file-img .show.dropup {
  display: block; }

.file-img:hover .dropup {
  display: block; }

.file-img:hover .overlay {
  display: unset; }

.popover {
  font-family: Inter; }

.avatar-menu {
  font-family: Inter;
  font-size: 0.875rem !important;
  background: #ffffff;
  box-shadow: rgba(57, 73, 76, 0.35) 0px 1px 6px 0px;
  padding: 0.25rem;
  border-radius: 0.25rem;
  width: fit-content;
  border: none;
  min-width: 10rem;
  z-index: 10070;
  /* &>.arrow {
    display: none;
  } */ }
  .avatar-menu .item {
    padding: 0.5rem 1rem;
    font-size: 0.875rem !important;
    font-weight: 500;
    margin: 0;
    border-radius: 0.25rem;
    line-height: unset;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%; }
    .avatar-menu .item:hover {
      background-color: #e9ecef; }

.avatar-badge {
  width: fit-content;
  position: absolute;
  bottom: 0;
  right: -2px; }

@media only screen and (max-width: 960px) {
  .Toastify .Toastify__toast-container {
    width: 96vw;
    margin-right: .5rem !important;
    margin-left: .5rem !important; }
  .Toastify .Toastify__toast.Toastify__toast--success {
    width: 100%; }
  .mobile-100-vw {
    width: 100vw; } }

.custom-wrapper {
  left: 0;
  padding-top: 60px;
  position: relative;
  transition: all 0.2s ease-in-out;
  overflow-y: auto;
  height: 100vh; }

.header-wrapper {
  height: 100px; }
  .header-wrapper .project-name {
    display: block;
    width: 30rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.kanban-board .kanban-cont {
  height: calc(100vh - 160px);
  overflow-x: auto;
  overflow-y: hidden; }

.kanban-list {
  min-width: 310px;
  width: 310px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  max-height: 100%; }

.kanban-box {
  padding: 10px; }

.kanban-list > [data-rbd-droppable-id] {
  /*.droppable-holder {*/
  padding: 0px 18px 0px 18px;
  min-height: 7px;
  max-height: 54vh;
  overflow-y: auto;
  overflow-x: hidden;
  /*}*/
  overflow-y: overlay; }

.task-text-wrapper {
  display: flex;
  width: 100%; }

.task-name-text {
  width: calc(100% - 10%);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 17px;
  margin-bottom: 10px;
  height: 35px; }

.task-name-text:hover + .task-edit-icon {
  visibility: visible;
  opacity: 0.5; }

.task-detail-text {
  width: calc(100% - 10%);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 17px;
  margin-bottom: 10px;
  height: 51px;
  opacity: 0.7; }

.task-detail-text:hover + .task-edit-icon {
  visibility: visible;
  opacity: 0.5; }

.task-edit-icon {
  visibility: hidden;
  width: 10%; }

.task-edit-icon:hover {
  visibility: visible; }

.task-editable-textarea {
  border-radius: 3px;
  padding: 5px;
  background-color: #f7f7f7;
  color: #000000;
  width: calc(100% - 5%);
  line-height: 17px;
  margin-bottom: 10px; }

.task-editable-textarea:hover + .task-edit-icon {
  visibility: visible;
  opacity: 0.5; }

.task-board-controls {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  height: 40px;
  align-items: center; }

.custom-avatar-row {
  display: flex;
  align-items: center;
  padding-left: 10px; }

.custom-avatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-left: -10px; }

.btn-add-task-user {
  color: #fff;
  background-color: #2569a1;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -15px; }

.select-project {
  height: 35px;
  width: 200px !important;
  text-overflow: ellipsis;
  border: 1px solid #ccc;
  border-radius: 4px; }

.control-icon {
  color: #a0a0a0;
  height: 15px;
  width: 15px;
  margin-left: 10px; }

.row-centered {
  display: flex;
  align-items: center;
  justify-content: center; }

.icon-border {
  border-radius: 3px;
  border: 1px solid #dfdfdf;
  color: #888888;
  background-color: #fff;
  height: 37px;
  width: 40px;
  padding: 0 7px;
  display: inline-flex;
  align-items: center;
  cursor: pointer; }

.flex-full-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.button {
  all: unset; }

img {
  object-fit: cover; }

.button-common {
  border-radius: 3px;
  padding: 5px 10px;
  cursor: text; }

.button-common2, .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-awaiting-btn, .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-accepted-btn, .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-pending-btn, .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-mark-completed-btn, .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-approve-as-complete-btn, .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-complete-btn, .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-under-review-btn {
  border-radius: 3px;
  padding: 3px 7px !important;
  cursor: text; }

.margin-left-30 {
  margin-left: 30px; }

[contenteditable] {
  outline: 0px solid transparent; }

.task-2-chat {
  z-index: 1000;
  position: fixed;
  height: 520px;
  width: 750px;
  background-color: #fff;
  box-shadow: rgba(51, 51, 51, 0.24) 0px 4px 12px 0px;
  display: flex;
  flex-direction: column; }
  .task-2-chat .task-2-chat-header {
    display: flex;
    padding: 10px;
    align-items: center;
    border-bottom: 1px solid #e2e8f0; }
    .task-2-chat .task-2-chat-header .task-2-close-btn {
      color: #a0a0a0; }
    .task-2-chat .task-2-chat-header .task-2-title {
      margin: 0 auto;
      cursor: default; }
  .task-2-chat .task-2-chat-body {
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
    overflow-y: auto;
    margin: 10px 0px 50px 0px;
    padding: 10x 10px 60px 10px; }
    .task-2-chat .task-2-chat-body .span-0-7, .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to > span:nth-child(1), .task-2-chat .task-2-chat-body > div:nth-child(2) > div > span:nth-child(1), .task-2-chat .task-2-chat-body > div:nth-child(3) > span:nth-child(1) {
      font-weight: bold;
      font-size: 14px;
      opacity: 0.7; }
    .task-2-chat .task-2-chat-body > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px; }
      .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to {
        display: flex;
        margin: 10px 0 30px;
        align-items: center;
        padding: 0 10px; }
        .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to > span:nth-child(1) {
          margin-right: auto; }
        .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-awaiting-btn {
          font-size: 12px;
          border: 1px solid #a0a0a0;
          opacity: 0.8;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #8b8b8b;
          cursor: pointer; }
          .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-awaiting-btn:hover {
            background-color: #a0a0a0;
            color: #fff; }
          .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-awaiting-btn > :nth-child(1) {
            margin-right: 7px; }
          .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-awaiting-btn svg {
            height: 16px;
            width: 16px; }
        .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-accepted-btn {
          font-size: 12px;
          border: 1px solid #a0a0a0;
          opacity: 0.8;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #8b8b8b; }
          .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-accepted-btn:hover {
            background-color: #a0a0a0;
            color: #fff; }
          .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-accepted-btn > :nth-child(1) {
            margin-right: 7px; }
        .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-pending-btn {
          font-size: 12px;
          background-color: #a0a0a0;
          color: #fff; }
        .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-mark-completed-btn {
          font-size: 12px;
          border: 1px solid #2569a1;
          opacity: 0.8;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #2569a1;
          cursor: pointer; }
          .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-mark-completed-btn:hover {
            background-color: #2569a1;
            color: #fff; }
          .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-mark-completed-btn > :nth-child(1) {
            margin-right: 10px; }
          .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-mark-completed-btn svg {
            height: 16px;
            width: 16px; }
        .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-approve-as-complete-btn {
          font-size: 12px;
          background-color: #9ca706;
          color: #fff;
          opacity: 0.8;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer; }
          .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-approve-as-complete-btn > :nth-child(1) {
            margin-right: 10px; }
          .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-approve-as-complete-btn svg {
            height: 16px;
            width: 16px; }
        .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-complete-btn {
          background-color: #23b654;
          color: #fff;
          cursor: text;
          font-size: 12px; }
        .task-2-chat .task-2-chat-body > div:nth-child(1) .task-2-chat-assigned-to .ts-under-review-btn {
          background-color: #9ca706;
          color: #fff;
          cursor: text;
          font-size: 12px;
          margin-left: 10px; }
      .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) {
        display: flex; }
        .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
          padding-left: 20px; }
        .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) {
          display: flex;
          font-family: Inter, Roboto, Arial, sans-serif;
          align-items: center; }
          .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > span {
            font-size: 13px;
            font-weight: bold;
            opacity: 0.7; }
          .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > .assigned-to-details {
            position: relative; }
            .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > .assigned-to-details > span {
              font-size: 13px;
              font-weight: bold;
              opacity: 0.7;
              cursor: default; }
            .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > .assigned-to-details > .assigned-to-others-text {
              color: blue;
              margin-left: 5px; }
            .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > .assigned-to-details > .assigned-to-others-text:hover + .assigned-to-others-layout {
              display: block; }
            .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > .assigned-to-details > .assigned-to-others-layout:hover {
              display: block; }
            .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > .assigned-to-details > .assigned-to-others-layout {
              display: none;
              position: absolute;
              top: 22px;
              left: -95px;
              border: 1px solid #fefefe;
              background-color: #fff;
              width: 180px;
              max-height: 150px;
              overflow-y: auto;
              padding: 10px 10px 0;
              box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
              z-index: 2; }
              .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > .assigned-to-details > .assigned-to-others-layout > div {
                display: flex;
                width: 100%;
                align-items: center;
                margin-bottom: 15px; }
                .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > .assigned-to-details > .assigned-to-others-layout > div > img {
                  height: 35px;
                  width: 35px;
                  border-radius: 50%;
                  margin-right: 10px; }
                .task-2-chat .task-2-chat-body > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > .assigned-to-details > .assigned-to-others-layout > div > span {
                  font-size: 13px;
                  font-weight: bold;
                  opacity: 0.7;
                  font-family: Inter, Roboto, Arial, sans-serif; }
    .task-2-chat .task-2-chat-body > div:nth-child(2) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px; }
      .task-2-chat .task-2-chat-body > div:nth-child(2) > div {
        display: flex;
        flex-direction: column; }
        .task-2-chat .task-2-chat-body > div:nth-child(2) > div > span:nth-child(1) {
          margin-bottom: 3px; }
        .task-2-chat .task-2-chat-body > div:nth-child(2) > div > span:nth-child(2) {
          font-size: 12px;
          opacity: 0.8;
          margin-right: 10px; }
    .task-2-chat .task-2-chat-body > div:nth-child(3) {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px; }
      .task-2-chat .task-2-chat-body > div:nth-child(3) > span:nth-child(1) {
        margin-bottom: 3px; }
      .task-2-chat .task-2-chat-body > div:nth-child(3) > span:nth-child(2) {
        font-size: 13px;
        opacity: 0.8;
        height: auto;
        overflow-y: auto;
        padding: 3px; }
    .task-2-chat .task-2-chat-body > div:nth-child(4) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      background-color: #f5f9fc;
      font-weight: bold;
      margin-bottom: 20px; }
      .task-2-chat .task-2-chat-body > div:nth-child(4) > span:nth-child(1) {
        height: 40px;
        display: flex;
        align-items: center; }
      .task-2-chat .task-2-chat-body > div:nth-child(4) .gallery-icon {
        height: 20px;
        width: 20px;
        opacity: 0.6;
        cursor: pointer;
        color: #ffc107; }
    .task-2-chat .task-2-chat-body > div:nth-child(5) {
      display: flex;
      flex-direction: column;
      height: 100%; }

select {
  outline: none; }

.btn-plus {
  background-color: #2569a1 !important; }

.card {
  border: unset !important; }

.task-card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: #ffffff; }
  .task-card:hover {
    filter: brightness(97%); }
    .task-card:hover .task-status-more-layout {
      background-color: #ffffff;
      filter: none !important; }

.task-status-more {
  z-index: 1;
  cursor: pointer; }
  .task-status-more > .task-status-more-icon {
    color: #a0a0a0; }
  .task-status-more button {
    border: 0;
    margin: 0;
    padding: 0;
    color: #a0a0a0;
    background-color: inherit; }
  .task-status-more svg {
    pointer-events: none; }
  .task-status-more:focus-within .task-status-more-layout {
    display: flex; }
  .task-status-more > .task-status-more-icon:active + .task-status-more-layout {
    display: flex; }
  .task-status-more > .task-status-more-layout:hover {
    display: flex; }
  .task-status-more > .task-status-more-layout {
    position: absolute;
    display: none;
    flex-direction: column;
    right: 8px;
    top: 35px;
    width: 120px;
    padding: 10px 0;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); }
    .task-status-more > .task-status-more-layout > span {
      padding: 5px 10px 5px 5px;
      cursor: default;
      display: flex;
      align-items: center;
      justify-content: start;
      font-weight: 100; }
      .task-status-more > .task-status-more-layout > span:hover {
        background-color: #ebecf0; }

.badge-position {
  position: relative;
  right: 5px;
  top: -8px; }

.no-scroll {
  overflow: hidden; }

.add-new-task {
  height: auto;
  padding: 4px; }
