@import "./global.scss";
$fex-color: #2569a1;

button {
  max-height: 3rem;
}

.project-modal {
  width: 60% !important;

  .w-p-100 {
    width: 100%;
    background-color: #f2f2f2;
  }

  .w-dt-100 {

    select,
    input {
      width: 100% !important;
      background-color: #f2f2f2;
    }
  }

  select {
    height: 35px;
  }

  textarea {
    width: 100% !important;
    background-color: #f2f2f2;
  }

  .fm-area {
    height: 100px;
    resize: none;
  }

  .next-add-project {
    height: 40px;
    background-color: $fex-color;
    color: #fff;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    margin: 50px 0 0 130px;
    cursor: default;
  }

  .in-active-project {
    opacity: 0.5;
    pointer-events: none;
  }

  .f-height {
    height: 450px;
  }

  .s-height {
    height: 500px;
  }
}

.dv-participant {
  height: 480px;

  .participants-tabs {
    display: flex;

    >div {
      width: 50%;
      text-align: center;
      border-bottom: 2px solid transparent;
      cursor: default;
      padding-bottom: 5px;
    }

    >.active-participants {
      border-bottom: 4px solid $fex-color;
    }

    >.inactive-participants {
      color: rgb(158, 158, 158);
    }
  }

  .participants-list {
    height: 68%;
    width: calc(100% + 0.75rem);
    margin-top: 10px;
    overflow-y: auto;
    @extend .sleek-scrollbar;
    @extend .white-scroll;

    .user-info {
      overflow-x: hidden;
      display: grid;
      grid-template-columns: 5rem minmax(8rem, 1fr) 8rem;
      align-items: center;
      padding: 0.5rem 0;
      cursor: default;
      gap: 1rem;

      &:hover {
        //  background-color: #f4f4f4;

        >.user-init {
          // background-color: #fff;
        }
      }



      .user-desc {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #F2F7FC;
      }

      .employee-name {
        font-weight: 600;
      }

      .user-init {
        background-color: #F2F7FC;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
      }

      >input[type="checkbox"] {
        height: 15px;
        width: 15px;
      }

      .actions {
        .btn {
          font-weight: 600;
          border-radius: 99999px;
          display: flex;
          align-items: center;
        }

        .bg-light {
          background-color: #F2F7FC;
          color: #4B5458;

        }
      }
    }
  }

  .none-found {
    margin-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.project-controls {
  // grid-template-columns: 30% 70%;
}

.team-controls {
  grid-template-columns: 40% 60%;
}

.upload-project-layout {
  display: flex;
  flex-direction: column;

  >.dv-up-lt {
    display: flex;
    margin-bottom: 10px;

    >span:nth-child(1) {
      margin-top: 10px;
      height: 35px;
      width: 35px;

      // padding-right: 10px;
      >svg {
        color: rgb(160, 160, 160);
        height: 30px;
        width: 30px;
        // padding: 5px;
        // border: 1px solid rgb(160, 160, 160);
        border-radius: 3px;
        // margin-right: auto;
      }
    }

    >div:nth-child(2) {
      width: 100%;
      overflow: auto;
      background-color: #fafafa;

      >.list-of-files {
        padding: 10px 5px;
        height: 80px;
        display: flex;
        flex-wrap: wrap;

        >div {
          display: flex;
          flex-direction: column;
          color: rgba(160, 160, 160, 0.8);
          padding: 3px 0;
          height: 95px;
          width: 22%;
          border-radius: 3px;
          background-color: #fff;
          padding: 5px;
          margin: 0 0 10px 10px;

          .fl-close {
            height: 20px;
            width: 20px;
            margin-left: auto;

            svg {
              height: 20px;
              width: 20px;
            }
          }

          .fl-file-icon {
            height: 25px;
            width: 25px;
            margin: 0 auto 5px;

            svg {
              height: 25px;
              width: 25px;
            }
          }

          .fl-info {
            width: 90%;
            font-size: 12px;
            margin: 0 10px 5px 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: nowrap;
            margin: 0 auto;
            text-align: center;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            font-weight: bold;
            cursor: default;
          }

          .fl-file-size {
            margin: 0 5px;
            display: block;
            font-size: 10px;
            text-align: center;
          }
        }
      }
    }
  }
}

.participants-project-controls {
  display: grid;
  // margin-bottom: 10px;

  >div:nth-child(1) {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;

    >span:not(.slider):not(.back):not(.sh-only) {
      width: 85px;
    }

    >span:not(.slider):not(.sh-only) {
      height: 40px;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      cursor: default;
      user-select: none;
    }

    .bg-fx {
      background-color: $fex-color;
      color: #fff;
    }

    .bo-fx {
      border: 1px solid $fex-color;
      color: $fex-color;
    }

    .back {
      @extend .bg-fx;
      color: #fff;
      width: 60px;
      margin-left: auto;
    }

    .cancel {
      @extend .bo-fx;
      margin: 0 40px;
    }

    .next {
      @extend .bg-fx;
    }

    .project-dv-switch {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto 10px 0 0;

      .sh-only {
        font-size: 11px;
      }
    }
  }
}

.participants-team-controls {
  display: grid;
  margin-bottom: 10px;

  >div:nth-child(1) {
    display: flex;
    flex-direction: column;

    >div:nth-child(1) {
      display: flex;
      align-items: center;
      margin-top: auto;

      >span:nth-child(1) {
        >svg {
          height: 35px;
          width: 35px;
          color: rgb(160, 160, 160);
          padding: 5px;
          border: 1px solid rgb(160, 160, 160);
          border-radius: 3px;
        }
      }

      >div:nth-child(2) {
        // height: 40px;
        // width: 100%;
        overflow: auto;

        >.list-of-files {
          padding: 0 10px 0 20px;

          >div {
            width: 100%;
            display: flex;
            justify-content: space-between;
            color: rgb(160, 160, 160);
            padding: 3px 0;

            span:nth-child(1) {
              width: 90%;
              font-size: 11px;
            }

            svg {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }
  }

  >div:nth-child(2) {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    gap: 1rem;

    >span:not(.slider):not(.back):not(.sh-only) {
      width: 85px;
    }

    >span:not(.slider):not(.sh-only) {
      height: 40px;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      cursor: default;
      user-select: none;
    }

    .bg-fx {
      background-color: $fex-color;
      color: #fff;
    }

    .bo-fx {
      border: 1px solid $fex-color;
      color: $fex-color;
    }

    .back {
      @extend .bg-fx;
      color: #fff;
      width: 60px;
      margin-left: auto;
    }

    .cancel {
      @extend .bo-fx;
      margin: 0 40px;
    }

    .next {
      @extend .bg-fx;
    }

    .project-dv-switch {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto 10px 0 0;

      .sh-only {
        font-size: 11px;
      }
    }
  }
}

.add-project-input {
  color: #677888;
  width: 100%;
  height: 2.875rem !important;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem !important;
  border: 1px solid #A7B9CB !important;

  &+svg {
    position: absolute;
    right: 1rem;
    top: 0.8rem;
    color: #677888;
  }
}

.team-description {
  height: 150px !important;
  resize: none !important;
}

.team-next-section {
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.p-active-task,
.p-active-task.active,
.p-active-task.active:focus,
.p-active-task.active:hover {
  background-color: #fff !important;
  color: #3F96D1 !important;
  border: none;
  border-bottom: 3px solid #3F96D1 !important;
  font-weight: 600;

}

.under-review-btn {
  background-color: #9ca706;
  border: 1px solid #9ca706;
}

.single-all-type-task {
  background-color: #F2F7FC;
  border-radius: 0.5rem;

  &:hover {
    //  background-color: transparent !important;
  }

  >.s-a-t {
    padding: 1rem 0rem 0.25rem 1rem !important;
  }

  .task-container {
    background: inherit;
    font-size: 0.875rem !important;
  }

}

.task-list {
  background-color: #fff !important;
}

.untask {
  background-color: #9ca706 !important;
  border: 1px solid #9ca706 !important;
  color: #fff !important;
}

.team-dv-switch {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: row-reverse;
}

.check-all {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  margin-right: 20px;

  >input {
    margin: auto 0;
  }
}

.print-preview-modal {
  width: 70% !important;

  .print-preview-content {
    // height: 80% !important;
    height: 580px;
  }

  .continue-print-btn {
    color: #2569a1;
    background-color: #fff;
    margin-right: 50px;
    padding: 5px 7px;
    border-radius: 3px;
  }
}

.member-detail-row {
  >.remove-user-icon {
    display: none;
    cursor: pointer;
  }

  &:hover {
    >.remove-user-icon {
      display: block;
    }
  }
}

.assigned-to-container {
  max-height: 10.4rem;
  overflow-y: auto;


  .name {
    color: #111111;
    font-weight: 600
  }

  .user-name {
    color: #677888;
    font-size: 0.75rem !important;
  }

  @extend .sleek-scrollbar;
}

.team-members {
  .modal-content {
    height: 41.75rem !important;
  }
}

@media (max-width: 960px) {
  .project-modal {
    width: 95% !important;
  }
}

.create-task-layout {
  >h4 {
    margin-bottom: 80px;
  }

  .maybe-btn {
    border: 1px solid $fex-color;
    color: $fex-color;
    padding: 7px 10px;
    border-radius: 5px;
    margin-right: 50px;
    width: 120px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;

    &:hover {
      opacity: 0.7;
    }
  }

  .yes-btn {
    background-color: $fex-color;
    color: #fff;
    padding: 7px 10px;
    border-radius: 5px;
    width: 120px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;

    &:hover {
      opacity: 0.7;
    }
  }
}


#create_project,
#edit_project {
  .modal-dialog {
    max-width: none !important;
  }
}

.dropdown-menu {}

.action-icon:hover+.dropdown-menu {
  visibility: visible;
}

.dropdown-menu {
  visibility: hidden;
}

.dropdown-menu:hover {
  visibility: visible;
}

.form-progress {
  width: 11.21875rem;
  max-width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.625rem;
  align-items: flex-start !important;

  span {
    font-size: 1rem;
    font-weight: 600;
  }

  >div {
    position: relative;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;

    .divider {
      position: absolute;
      width: 100%;
      border: 2px solid #F2F7FC;
      top: 50%;
      z-index: 1;
    }
  }

  svg {
    z-index: 2;
    pointer-events: fill;
  }
}