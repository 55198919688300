.todo {
  font-size: 0.875rem !important;

  .title {
    font-weight: 600;
  }

  .todo-progress {
    gap: 0.5rem;

    .bar {
      height: 0.5rem;

      .progress-bar {
        background: #71B0DB;

      }

    }

    .btn {

      text-decoration: underline;

    }
  }

  .todo-item {
    min-height: 2.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    border-radius: 0.5rem;
    padding: 0.75rem;
    cursor: default;
    justify-content: space-between;
    margin-bottom: 0.25rem;

    .text {
      white-space: pre-line;

      .username-tag,
      a {
        color: #3F96D1;
        font-weight: 500;
      }
    }

    textarea {
      resize: none;
      // background-color: inherit;
      text-decoration: none;

    }

    .btn.action {
      width: 1.5rem;
      height: 1.5rem;
      padding: 0;
      color: #677888;
      background-color: #67788827;
      visibility: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      &.edit {
        svg {
          transform: scale(0.75);
        }

        &:hover {
          background-color: #f7f7f7;
        }
      }

      &.del-btn {

        color: #FE5B52;
        background-color: rgba(254, 91, 82, 0.15);


        &:hover {
          color: #fff;
          background-color: #FE5B52;
        }
      }
    }




    &:hover,
    &.is-edit {
      background: #EAECF0;

      .btn {
        visibility: unset;
      }
    }

  }




  .add-todo {

    textarea {
      resize: none;
      // background-color: inherit;
      text-decoration: none;
    }

    .btn {
      font-weight: 600;
      font-size: 0.875rem !important;
      height: 2.25rem;
      min-width: 3.75rem;
    }
  }
}