$base-grey-60: #788898;

.file-backdrop {
  z-index: 1050;
  backdrop-filter: blur(1px);
}

.file-modal {
  // width: 34.3125rem !important;
  // height: 29.75rem !important;
  width: auto;
  // max-width: 48.5625rem !important;
  max-width: 80vw !important;
  max-height: none !important;

  .modal-content {
    border: none;
    // height: 29.75rem !important;
    background: transparent;
  }

  .modal-header {
    display: none;
  }


  .modal-body {
    padding: 0;
    position: relative;
    // background: transparent;

    #close-btn {
      color: $base-grey-60;
      background: #F2F7FC;
      border: none;
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: -2rem;
    }


    .file-holder {
      position: relative;
      min-height: 24rem;
    }

    .file {
      position: relative;
      //max-width: 34.3125rem;
      max-width: 80rem;
      min-width: 65vw;
      margin: auto;

      .carou-item {
        img {
          object-fit: contain;
        }

      }

      .carousel-item {
        text-align: center;

        &.active {
          // display: flex;
          // align-items: center;
          // justify-content: center;
        }

        .TransformComponent-module_container__3NwNd {
          margin: auto !important;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain !important;
          margin: auto;

        }
      }
    }

    .actions {
      // position: absolute;
      text-align: center;
      color: #ffffff;
      font-size: 1.25rem;
      font-weight: 600;
      margin-top: 2rem;

      .btn {
        font-size: 1rem;
        color: #ffffff;
        padding: 0.5625rem 1rem 0.5625rem 1.1rem;
        // width: 8.875rem;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.5rem;
        margin-top: 1rem;
        font-weight: 600;
        line-height: 160%;
        background: #ABABAB4D;
        backdrop-filter: blur(14px);

        span {
          margin-left: 0.6rem;
        }

        svg {
          margin: 0;
        }
      }

      .download {}

      .comment {
        background: #ABABAB4D;
      }
    }

    .controls {
      // width: 80rem;
      width: 90vw;
      // width: 48.5625rem;
      // height: 2.25rem;
      height: 3rem;
      bottom: 0;
      left: 0;
      margin: auto;
      position: fixed;
      right: 0;
      top: 0;
      display: flex;
      justify-content: space-between;
    }
  }

  .speed {
    //  background-color: red;
  }

  .modal-footer {
    display: none;
  }
}

@media only screen and (max-width: 960px) {
  #close-btn {
    color: $base-grey-60;
    background: #ffffff;
  }

  .file-backdrop {
    background: #ffffff;
    backdrop-filter: unset;
  }

  .file-modal {
    width: 100vw !important;
    margin: 0;
    background: #ffffff;

    .modal-content {
      margin-top: 4.6rem;
      height: calc(var(--vh, 1vh) * 100 - 4.8rem);
      position: fixed;
      background: inherit;
      overflow-y: auto;

      .modal-header {
        display: flex;
        align-items: center;
        font-size: 1.125rem;
        font-weight: 600;
        padding: 1rem;

        .filename {
          width: 16rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .file-holder {
        min-height: 100%;
        height: 100%;
      }

      .file {
        max-width: calc(100vw);
        height: calc(var(--vh, 1vh) * 100 - 13rem);
        overflow: auto;
        margin: 0;

        &.mt-3 {
          margin: 0 !important;
        }
      }

      .actions {
        display: none;
      }

      .carousel {
        height: calc(var(--vh, 1vh) * 100 - 13rem);
        // width: calc(100vw - 2rem);
        margin: 1rem !important;
        display: flex;
        align-items: center;
        margin: auto;


        .TransformComponent-module_content__TZU5O {
          height: 100vh !important;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .modal-footer {
      color: #677888;
      height: 4.5rem;
      display: flex;
      justify-content: space-between;

      .mobile-controls {
        :first-child {
          margin-right: 2rem;
        }
      }
    }
  }
}