@import "./global.scss";

$fex-color: #2569a1;
$carton-color: #e9e9e9;
$carton-color2: #f4f4f4;

//Custom Spinner
$size: 17px;
$point: #2569a1;
$gray2: #f4f4f4;
$bdw: 4px;
$big: 1.6;
$small: 0.625;

.flex-full-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  all: unset;
}

img {
  object-fit: cover;
}

.button-common {
  border-radius: 3px;
  padding: 5px 10px;
  cursor: text;
}

.button-common2 {
  border-radius: 3px;
  padding: 3px 7px !important;
  cursor: text;
}

.margin-left-30 {
  margin-left: 30px;
}

@mixin avatar-h-w($w, $x) {
  width: $w;
  height: $x;
}

@mixin avatar-m-l($y) {
  margin-left: $y;
}

[contenteditable] {
  outline: 0px solid transparent;
}

.task-1-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-left: 235px;
  padding-top: 80px;
}

.task-header-common {
  background-color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.task-1-header {
  @extend .task-header-common;
  margin: 0 3% 30px 2%;
  padding: 0 3%;
  height: 60px;
}

.task-name {
  max-width: 30%;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}

.task-header-controls {
  display: flex;
  margin-left: auto;
  align-items: center;

  .switch-view-btn {
    @extend .button-common;
    background: #5a9fdc;
    font-size: 14px;
    margin-left: 10px;
    color: #fff;
    cursor: pointer;
    padding: auto 15px;
  }

  .new-task-btn {
    @extend .button-common;
    background-color: $fex-color;
    color: #fff;
    margin-left: 40px;
    cursor: pointer;
  }

  .custom-dropdown-tag {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: #f3f7f9;
    background-image: none;
  }

  /* Remove IE arrow */
  .custom-dropdown-tag::-ms-expand {
    display: none;
  }

  /* Custom Select */
  .custom-dropdown {
    position: relative;
    display: flex;
    width: 10.2em;
    height: 2em;
    line-height: 1.6;
    background: #f3f7f9;
    overflow: hidden;
    border-radius: 0.25em;
    color: #9babb4;
  }

  .ct-container {
    margin-left: 10px;
  }

  .custom-dropdown-tag {
    flex: 1;
    padding: 0 0.8em;
    cursor: pointer;
    opacity: 0.6;
    font-size: 14px;
  }

  /* Arrow */
  .custom-dropdown::after {
    content: "\25BE";
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 0.36em;
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: 0.25s all ease;
    -o-transition: 0.25s all ease;
    transition: 0.25s all ease;
    font-size: 19px;
    font-weight: bold;
    opacity: 0.9;
  }

  .gear-icon {
    color: #fff;
    width: 25px;
    height: 25px;
    margin-left: 15px;
    cursor: pointer;
  }
}

.search-dropdown-tag {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #fff;
  background-image: none;
  width: 80px;
}

/* Remove IE arrow */
.search-dropdown-tag::-ms-expand {
  display: none;
}

/* Custom Select */
.search-dropdown {
  position: relative;
  display: flex;
  width: 6em;
  height: 2.2em;
  background: #fff;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #9babb4;
}

.search-dropdown-tag {
  flex: 1;
  padding: 0 0.8em;
  cursor: pointer;
  opacity: 0.6;
  font-size: 14px;
  user-select: none;
}

/* Arrow */
.search-dropdown::after {
  content: "\25BE";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0.36em;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
  font-size: 19px;
  font-weight: bold;
}

@mixin avatar-layout($w, $x, $y, $z) {
  display: flex;
  position: relative;

  .avatars {
    display: inline-flex;
    flex-direction: row-reverse;
  }

  .avatar {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    @include avatar-h-w($w, $x);
    @include avatar-m-l($y);
    cursor: default;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .avatar img {
    @include avatar-h-w($w, $x);
    width: inherit;
    height: inherit
  }

  .avatar .av-alternative {
    @include avatar-h-w($w, $x);
    border-radius: 50%;
    background-color: #DCECF7;
    color: #5D696F;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    width: inherit;
    height: inherit
  }

  .avatar .account-clock-icon {
    position: absolute;
    bottom: 4px;
    right: 4px;
    background-color: #fff;
    color: #808080;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-button-wrapper {
    .add-button {
      @include avatar-h-w($z, $z);
    }

    background-color: $fex-color;
    @include avatar-h-w($w, $x);
    @extend .flex-full-center;
  }

  .white-border {
    background-color: #5a9fdc;
  }
}

.task-2-board-title {
  color: #fff;
  font-weight: 600;
  font-size: 14.5px;
  margin-right: 30px;
  // text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px #05345B;
}

.task-img-collection {
  @include avatar-layout(2rem, 2rem, -20px, 20px);
}

.task-2-board-search-icon {
  color: #fff;
  height: 25px;
  width: 25px;
  margin-left: 10px;
}

.task-2-board-search-field {
  all: unset;
  height: 2em;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #f3f7f9;
  border-right: none;
  padding: 0 5px 0 7px;
  user-select: none;
  width: 10em;
}

.task-2-board-floating-icon {
  width: 1.2em;
  height: 2.2em;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  >svg {
    color: $fex-color;
  }
}

.task-2-board-reuseable {
  height: 2.2em;
  border: transparent;
  margin-left: 40px;
  display: flex;
}

.task-2-board-search-container {
  @extend .task-2-board-reuseable;
  animation: slideIn 3s;
}

@keyframes slideIn {
  from {
    width: 0em;
  }

  to {
    width: 17.2em;
  }
}

.task-2-board-search-hidden {
  // @extend .task-2-board-reuseable;
  // width: 0;
  // animation: slideOut 0.3s;
  visibility: hidden;
}

// @keyframes slideOut {
// 	from {
// 		width: 17.2em;
// 	}
// 	to {
// 		width: 0;
// 	}
// }

@mixin svg-layout($x, $y) {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  >img {
    height: $x;
    width: $y;
  }
}

.sm-lr {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  margin-right: 10px;
}

.sp-initials {
  @extend .sm-lr;
  background-color: #808080;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.task-1-body {
  display: flex;
  height: calc(100% - 110px);
  margin-bottom: 20px;
  padding: 0 3% 0 2%;

  >div:nth-child(1) {
    margin-right: 40px;
  }

  >div {
    border-radius: 3px;
    flex: 1;
    width: 50%;
    overflow-y: auto;
    @extend .sleek-scrollbar;
  }
}

.task-1-list-of-tasks {
  cursor: default;
  background-color: #fff;

  >.task-1-show-tasks {
    .task-1-by-project {
      display: flex;
      padding: 30px;
      align-items: center;
      border-radius: 3px;
      border: 1px solid transparent;

      &:nth-child(odd) {
        background-color: #fefefe;
      }

      &:nth-child(even) {
        background-color: #fafafa;
      }

      >.task-1-awaiting {
        background-color: #a0a0a0;
      }

      >.task-1-complete {
        background-color: #23b654;
      }

      >.task-1-incomplete {
        background-color: #2569a1;
      }

      >.task-1-under-review {
        background-color: #9ca706;
      }

      >span:nth-child(1) {
        width: 8%;
        height: 36px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10%;

        >svg:nth-child(1) {
          height: 20px;
          width: 20px;
        }
      }

      >span:nth-child(2) {
        width: 57%;
        margin-right: 10%;
      }

      >.task-ctrl-icon {
        width: 6%;
        height: 28px;
        border-radius: 50%;
        color: #aaa;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        background-color: #eee;

        >svg:nth-child(1) {
          height: 15px;
          width: 15px;
        }
      }

      >.add-person-icon {
        margin-right: 10px;
      }

      >.empty-person-icon {
        display: none;
      }
    }

    .task-1-selected {
      border: 1px solid $fex-color;
    }

    .task-1-by-users {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 190px);
      background-color: #f7f7f7;

      >div {
        background-color: #fff;
        margin-bottom: 25px;

        >.task-1-user-details {
          display: grid;
          grid-template-columns: 7% 35% 30% 10%;
          align-items: center;
          column-gap: 5%;
          padding: 20px;

          >img {
            height: 2rem;
            width: 2rem;
            border-radius: 50%;
            margin-right: 10px;
          }

          >.task-1-rate-info {
            span:nth-child(2) {
              opacity: 0.7;
              margin-left: 5px;
            }
          }
        }

        // >div{
        //   @extend .task-1-by-project;
        // }
      }
    }
  }

  >.task-empty-container {
    @include svg-layout(200px, 200px);

    >img {
      object-fit: contain;
    }
  }
}

.task-1-chat {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;

  .task-1-chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;

    svg {
      height: 20px;
      width: 20px;
    }

    >span:nth-child(1) {
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: 1.2rem;
      width: 10rem;
    }

    .ts-awaiting-btn {
      @extend .button-common;
      border: 1px solid #a0a0a0;
      opacity: 0.8;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #8b8b8b;
      cursor: pointer;

      &:hover {
        background-color: #a0a0a0;
        color: #fff;
      }

      > :nth-child(1) {
        margin-right: 7px;
      }

      svg {
        height: 16px;
        width: 16px;
      }
    }

    .ts-accepted-btn {
      @extend .button-common2;
      font-size: 12px;
      border: 1px solid #a0a0a0;
      opacity: 0.8;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #8b8b8b;

      &:hover {
        background-color: #a0a0a0;
        color: #fff;
      }

      > :nth-child(1) {
        margin-right: 7px;
      }
    }

    .ts-pending-btn {
      @extend .button-common;
      background-color: #a0a0a0;
      color: #fff;
    }

    .ts-mark-completed-btn {
      @extend .button-common;
      border: 1px solid #2569a1;
      opacity: 0.8;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #2569a1;
      cursor: pointer;

      &:hover {
        background-color: #2569a1;
        color: #fff;
      }

      > :nth-child(1) {
        margin-right: 10px;
      }
    }

    .ts-approve-as-complete-btn {
      @extend .button-common;
      background-color: #9ca706;
      color: #fff;
      opacity: 0.8;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      > :nth-child(1) {
        margin-right: 10px;
      }
    }

    .ts-complete-btn {
      background-color: #23b654;
      color: #fff;
      cursor: text;
      @extend .button-common;
    }

    .ts-under-review-btn {
      background-color: #9ca706;
      color: #fff;
      cursor: text;
      @extend .button-common;
    }
  }

  .task-1-chat-body {
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
    overflow-y: auto;
    @extend .sleek-scrollbar;
    margin: 0 10px 60px 15px;

    .span-0-7 {
      font-weight: bold;
      font-size: 14px;
      opacity: 0.7;
    }

    >div:nth-child(1) {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      >span:nth-child(1) {
        margin-bottom: 10px;
        @extend .span-0-7;
      }

      >div:nth-child(2) {
        display: flex;

        >div:nth-child(1) {
          padding-left: 20px;
        }

        >div:nth-child(2) {
          display: flex;
          font-family: Inter, Roboto, Arial, sans-serif;
          align-items: center;

          >span {
            font-size: 13px;
            font-weight: bold;
            opacity: 0.7;
          }

          >.assigned-to-details {
            position: relative;

            >span {
              font-size: 13px;
              font-weight: bold;
              opacity: 0.7;
              cursor: default;
            }

            >.assigned-to-others-text {
              color: blue;
              margin-left: 5px;
            }

            >.assigned-to-others-text:hover+.assigned-to-others-layout {
              display: block;
            }

            >.assigned-to-others-layout:hover {
              display: block;
            }

            >.assigned-to-others-layout {
              display: none;
              position: absolute;
              top: 17px;
              border: 1px solid #fefefe;
              background-color: #fff;
              width: 180px;
              max-height: 150px;
              overflow-y: auto;
              @extend .sleek-scrollbar;
              padding: 10px 10px 0;
              box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
              z-index: 2;

              >div {
                display: flex;
                width: 100%;
                align-items: center;
                margin-bottom: 15px;

                >img {
                  @extend .sm-lr;
                }

                >span {
                  font-size: 13px;
                  font-weight: bold;
                  opacity: 0.7;
                  font-family: Inter, Roboto, Arial, sans-serif;
                }
              }
            }
          }
        }
      }
    }

    >div:nth-child(2) {
      display: grid;
      grid-template-columns: 33.3% 33.3% 33.3%;
      margin-bottom: 20px;

      >div {
        display: flex;
        flex-direction: column;

        >span:nth-child(1) {
          margin-bottom: 3px;
          @extend .span-0-7;
        }

        >span:nth-child(2) {
          font-size: 12px;
          opacity: 0.8;
        }
      }
    }

    >div:nth-child(3) {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      >span:nth-child(1) {
        margin-bottom: 3px;
        @extend .span-0-7;
      }

      >span:nth-child(2) {
        font-size: 13px;
        opacity: 0.8;
        height: 45px;
        overflow-y: auto;
        @extend .sleek-scrollbar;
        // background-color: #fcfcfc;
        padding: 3px;
      }
    }

    >div:nth-child(4) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      background-color: #f5f9fc;
      font-weight: bold;
      margin-bottom: 20px;

      >span:nth-child(1) {
        height: 40px;
        display: flex;
        align-items: center;
      }

      .gallery-icon {
        height: 20px;
        width: 20px;
        opacity: 0.6;
        cursor: pointer;
        color: #ffc107;
      }
    }

    >div:nth-child(5) {
      display: flex;
      flex-direction: column;

      >.single-comment {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgb(248, 248, 248);

        >div:nth-child(1) {
          display: flex;
          margin-bottom: 10px;

          >img:nth-child(1) {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            margin-right: 15px;
          }

          >div:nth-child(2) {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
              font-size: 14px;
              font-weight: bold;
            }

            >div:nth-child(2) {
              font-size: 12px;

              >span:nth-child(1) {
                margin-right: 10px;
                text-transform: uppercase;
              }
            }
          }
        }

        >.file-detail-flex {
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 5px;
          padding-right: 5px;
          background-color: #fefefe;
        }

        >.only-comment {
          font-size: 14px;
          opacity: 0.9;
          white-space: pre-wrap;
        }
      }
    }
  }

  .task-1-illustration {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >img {
      height: 200px;
      width: 200px;
      margin-bottom: 20px;
      object-fit: contain;
    }
  }
}

.task-controls-layout {
  position: absolute;
  bottom: 0;
  width: 100%;

  .task-controls-attachments-layout {
    height: 100px;
    padding: 10px 0 10px 10px;
    border-radius: 3px;
    background-color: #eee;
    display: flex;
    width: 100%;
    overflow-x: auto;
    // margin-bottom: 3px;

    .attachments-row {
      height: 100%;
      display: flex;

      .single-attachment {
        height: 100%;
        width: 120px;
        margin-right: 20px;
        border-radius: 3px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        padding: 5px;

        .attach-file-close {
          display: flex;

          .attach-file-close-icon {
            height: 15px;
            margin-left: auto;
            margin-bottom: 1px;
          }
        }

        .attach-file-col {
          display: grid;
          grid-template-columns: 25% 75%;

          .attach-file-icon {
            color: rgb(158, 158, 158);
          }

          .attach-file-info {
            display: flex;
            flex-direction: column;

            >span:nth-child(1) {
              font-size: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }

            >span:nth-child(2) {
              font-size: 10px;
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

  .task-controls-message-layout {
    padding: 5px;
    border-radius: 3px;
    @extend .flex-full-center;
    background-color: rgb(233, 233, 233);
    width: 100%;

    >div:nth-child(1) {
      border: 1px solid rgb(228, 228, 228);
      border-radius: 3px;
      background-color: #f2f2f2;
      height: 40px;
      display: flex;
      align-items: center;
      width: 100%;
      margin-right: 15px;
      padding: 5px 5px 5px 7px;

      >textarea {
        all: unset;
        height: 100%;
        width: 100%;
        font-size: 16px;
      }

      >span:nth-child(2) {
        margin-left: 5px;

        >svg {
          height: 20px;
          width: 20px;
          color: #aaa;
        }
      }
    }

    >span:nth-child(2) {
      background-color: $fex-color;
      border-radius: 3px;
      color: #fff;
      padding: 5px 3%;
      @extend .flex-full-center;
      border: 1px solid $fex-color;

      &:hover {
        background-color: #fff;
        border: 1px solid $fex-color;
        color: $fex-color;
      }
    }
  }
}

.task-2-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  padding: 50px 0 0 0;
  background-color: #fff;

  .task-2-header {
    @extend .task-header-common;
    opacity: 1;
    height: 60px;
    padding: 0 2% 0 1%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    align-content: center;
    // background-color: #9b9b99;
    // background: none;
    // border-bottom: 1px solid #fff;
    background-color: rgba(129, 129, 131, 0.9);
  }
}

.bg-default {
  background-color: #9b9b99;
}

.bg-indigo {
  // background-color: rgb(1, 39, 197);
  background-color: #0d538d;
}

.bg-teal {
  background-color: rgb(11, 133, 125);
}

.bg-green {
  background-color: rgb(16, 124, 16);
}

.bg-blue {
  background-color: rgb(0, 120, 212);
}

.bg-violet {
  background-color: rgb(136, 23, 152);
}

.bg-flamingo {
  background-color: rgb(230, 124, 115);
}

.bg-pink {
  background-color: rgb(227, 0, 140);
}

.bg-westeria {
  background-color: rgb(179, 157, 219);
}

.bg-tomato {
  background-color: rgb(213, 0, 0);
}

.bg-orange {
  background-color: rgb(219, 58, 0);
}

.bg-cocoa {
  background-color: rgb(121, 85, 72);
}

.bg-board-grey {
  background-color: rgb(102, 102, 102);
}

.bg-board-black {
  background-color: rgb(0, 0, 0);
}

.bg-beige {
  background-color: beige;
}

.bg-default-circle {
  background-color: #ebecf0;
}

.bg-task-default {
  background-color: #fff;
}

.task-2-body {
  opacity: 1;
  display: flex;
  height: calc(100% - 60px);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 40px;

  >.task-2-container {
    // background-color: #9b9b99;
    // border-radius: 3px;
    flex: 1;
    overflow: auto;
    @extend .sleek-scrollbar;

    >.task-2-content {
      >.task-2-wrapper {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        display: inline-flex;
        padding: 0px 0 30px 7px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;

        .relative-parent {
          position: relative;
        }

        >.single-project:not(:last-child) {
          margin-right: 7px;
        }

        >.single-project:last-child {
          margin-right: 7px;
        }

        >.single-project {
          width: 300px;
          background-color: #ebecf0;
          padding: 10px 10px 0 10px;
          border-radius: 5px;
          height: 100%;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.2);

          .task-2-common {
            display: flex;
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
          }

          .task-2-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            padding: 5px 7px;
            position: relative;
            z-index: 3;

            >.project-2-name {
              width: 250px;
              font-weight: bold;
              cursor: pointer;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            >.task-2-more-icon {
              // color: rgb(184, 184, 184);
            }

            >.task-2-more-icon:hover+.task-2-more-layout {
              display: flex;
            }

            >.task-2-more-layout:hover {
              display: flex;
            }

            >.task-2-more-layout {
              position: absolute;
              display: none;
              flex-direction: column;
              // justify-content: center;
              // align-items: center;
              right: 8px;
              top: 22px;
              width: 120px;
              padding: 20px 0;
              background-color: #fff;
              box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

              >span {
                padding: 5px 10px 5px 5px;
                cursor: default;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 100;

                &:hover {
                  background-color: #ebecf0;
                }
              }
            }
          }

          .task-2-add-new {
            margin-bottom: 30px;
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px dashed #ccc;
            border-radius: 3px;
            height: 150px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .task-2-chat {
    z-index: 1000;
    position: fixed;
    height: 520px;
    width: 600px;
    background-color: #fff;
    box-shadow: rgba(51, 51, 51, 0.24) 0px 4px 12px 0px;
    display: flex;
    flex-direction: column;

    .task-2-chat-header {
      display: flex;
      padding: 10px;
      align-items: center;
      border-bottom: 1px solid #e2e8f0;

      .task-2-close-btn {
        color: #a0a0a0;
      }

      .task-2-title {
        margin: 0 auto;
        cursor: default;
      }
    }

    .task-2-chat-body {
      display: flex;
      flex-direction: column;
      height: calc(100% - 60px);
      overflow-y: auto;
      @extend .sleek-scrollbar;
      margin: 10px 5px 0 10px;
      padding-bottom: 60px;

      .span-0-7 {
        font-weight: bold;
        font-size: 14px;
        opacity: 0.7;
      }

      >div:nth-child(1) {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .task-2-chat-assigned-to {
          display: flex;
          margin: 10px 0 30px;
          align-items: center;
          padding: 0 10px;

          >span:nth-child(1) {
            @extend .span-0-7;
            margin-right: auto;
          }

          .ts-awaiting-btn {
            @extend .button-common2;
            font-size: 12px;
            border: 1px solid #a0a0a0;
            opacity: 0.8;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #8b8b8b;
            cursor: pointer;

            &:hover {
              background-color: #a0a0a0;
              color: #fff;
            }

            > :nth-child(1) {
              margin-right: 7px;
            }

            svg {
              height: 16px;
              width: 16px;
            }
          }

          .ts-accepted-btn {
            @extend .button-common2;
            font-size: 12px;
            border: 1px solid #a0a0a0;
            opacity: 0.8;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #8b8b8b;

            &:hover {
              background-color: #a0a0a0;
              color: #fff;
            }

            > :nth-child(1) {
              margin-right: 7px;
            }
          }

          .ts-pending-btn {
            @extend .button-common2;
            font-size: 12px;
            background-color: #a0a0a0;
            color: #fff;
          }

          .ts-mark-completed-btn {
            @extend .button-common2;
            font-size: 12px;
            border: 1px solid #2569a1;
            opacity: 0.8;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #2569a1;
            cursor: pointer;

            &:hover {
              background-color: #2569a1;
              color: #fff;
            }

            > :nth-child(1) {
              margin-right: 10px;
            }

            svg {
              height: 16px;
              width: 16px;
            }
          }

          .ts-approve-as-complete-btn {
            @extend .button-common2;
            font-size: 12px;
            background-color: #9ca706;
            color: #fff;
            opacity: 0.8;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            > :nth-child(1) {
              margin-right: 10px;
            }

            svg {
              height: 16px;
              width: 16px;
            }
          }

          .ts-complete-btn {
            background-color: #23b654;
            color: #fff;
            cursor: text;
            @extend .button-common2;
            font-size: 12px;
          }

          .ts-under-review-btn {
            background-color: #9ca706;
            color: #fff;
            cursor: text;
            @extend .button-common2;
            font-size: 12px;
            margin-left: 10px;
          }
        }

        >div:nth-child(2) {
          display: flex;

          >div:nth-child(1) {
            padding-left: 20px;
          }

          >div:nth-child(2) {
            display: flex;
            font-family: Inter, Roboto, Arial, sans-serif;
            align-items: center;

            >span {
              font-size: 13px;
              font-weight: bold;
              opacity: 0.7;
            }

            >.assigned-to-details {
              position: relative;

              >span {
                font-size: 13px;
                font-weight: bold;
                opacity: 0.7;
                cursor: default;
              }

              >.assigned-to-others-text {
                color: blue;
                margin-left: 5px;
              }

              >.assigned-to-others-text:hover+.assigned-to-others-layout {
                display: block;
              }

              >.assigned-to-others-layout:hover {
                display: block;
              }

              >.assigned-to-others-layout {
                display: none;
                position: absolute;
                top: 22px;
                left: -95px;
                border: 1px solid #fefefe;
                background-color: #fff;
                width: 180px;
                max-height: 150px;
                overflow-y: auto;
                @extend .sleek-scrollbar;
                padding: 10px 10px 0;
                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
                z-index: 2;

                >div {
                  display: flex;
                  width: 100%;
                  align-items: center;
                  margin-bottom: 15px;

                  >img {
                    height: 2rem;
                    width: 2rem;
                    border-radius: 50%;
                    margin-right: 10px;
                  }

                  >span {
                    font-size: 13px;
                    font-weight: bold;
                    opacity: 0.7;
                    font-family: Inter, Roboto, Arial, sans-serif;
                  }
                }
              }
            }
          }
        }
      }

      >div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        >div {
          display: flex;
          flex-direction: column;

          >span:nth-child(1) {
            margin-bottom: 3px;
            @extend .span-0-7;
          }

          >span:nth-child(2) {
            font-size: 12px;
            opacity: 0.8;
            margin-right: 10px;
          }
        }
      }

      >div:nth-child(3) {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        >span:nth-child(1) {
          margin-bottom: 3px;
          @extend .span-0-7;
        }

        >span:nth-child(2) {
          font-size: 13px;
          opacity: 0.8;
          height: 45px;
          overflow-y: auto;
          @extend .sleek-scrollbar;
          // background-color: #fcfcfc;
          padding: 3px;
        }
      }

      >div:nth-child(4) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        background-color: #f5f9fc;
        font-weight: bold;
        margin-bottom: 20px;

        >span:nth-child(1) {
          height: 40px;
          display: flex;
          align-items: center;
        }

        .gallery-icon {
          height: 20px;
          width: 20px;
          opacity: 0.6;
          cursor: pointer;
          color: #ffc107;
        }
      }

      >div:nth-child(5) {
        display: flex;
        flex-direction: column;

        >.single-comment {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          padding-bottom: 10px;
          border-bottom: 1px solid rgb(248, 248, 248);

          >div:nth-child(1) {
            display: flex;
            margin-bottom: 10px;

            >img:nth-child(1) {
              height: 40px;
              width: 40px;
              border-radius: 50%;
              margin-right: 15px;
            }

            >div:nth-child(2) {
              display: flex;
              flex-direction: column;

              >span:nth-child(1) {
                font-size: 14px;
                font-weight: bold;
              }

              >div:nth-child(2) {
                font-size: 12px;

                >span:nth-child(1) {
                  margin-right: 10px;
                  text-transform: uppercase;
                }
              }
            }
          }

          >.file-detail-flex {
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 5px;
            padding-right: 5px;
          }

          >.only-comment {
            font-size: 14px;
            opacity: 0.9;
            white-space: pre-wrap;
          }
        }

        >.single-comment:last-child {
          margin-bottom: 80px;
        }
      }
    }
  }

  .task-empty-container {
    @include svg-layout(300px, 300px);

    >span {
      font-size: 18px;
    }

    >img {
      object-fit: contain;
    }
  }
}

.custom-select-layout {
  display: flex;
  margin-right: 40px;
  align-items: center;

  select {
    background-color: $carton-color;
    border: none;
    border-radius: 3px;
    margin-left: 15px;
    outline: none;
    opacity: 0.8;
    height: 30px;
    padding: 0 15px;
    font-family: Inter, Roboto, Arial, sans-serif;
    font-weight: bold;
  }
}

.upload-file {
  width: 45%;

  // .n-file{
  //   height: 400px;
  // }

  .file-message {
    width: 100%;
    height: 100px;
    margin-bottom: 40px;
    resize: none !important;
  }
}

.pj-card {
  max-width: 20rem;
  min-width: 20rem;
  border-radius: 0.25rem;
  box-shadow: 0px 9px 60px 0px #99A3B721;
  background-color: white;
  word-break: break-all;

  font-family: Inter, Roboto, Arial, sans-serif;

  .widget-title {
    font-weight: bold !important;
  }

  .widget-subbody {
    font-size: 14px !important;
  }

  .widget-subbody-header {
    font-weight: bold !important;
    opacity: 0.9;
  }

  .n-found {
    font-weight: normal !important;
  }
}

.full-centre {
  height: 470px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-pdf-icon {
  color: rgb(201, 51, 51);
}

.file-word-icon {
  color: rgb(70, 70, 255);
}

.file-excel-icon {
  color: rgb(53, 212, 53);
}

.file-other-icon {
  color: rgb(177, 177, 177);
}

.file-info-text {
  color: rgb(101, 101, 245);
}

@keyframes rota1 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rota2 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.2;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

.rec {
  width: $size;
  height: $size;
  border: $bdw solid $gray2;
}

.circular-border {
  border-radius: 50%;
  margin-left: auto;
}

.spinner2 {
  animation: rota1 1s infinite;
  border-bottom-color: $point;
}

.spinner3 {
  animation: rota1 1s linear infinite;
  border-width: ($size / 2);
  border-top-color: $point;
  border-bottom-color: $point;
}

.fl-size {
  height: 22px;
  width: 22px;
}

.circle-icon {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.ch-awaiting-btn {
  color: #a0a0a0;
}

.ch-mark-completed-btn {
  color: #2569a1;
}

.ch-approve-as-complete-btn {
  color: #9ca706;
}

.ch-complete-btn {
  color: #23b654;
  cursor: text;
}

.ch-under-review-btn {
  color: #9ca706;
}

.load-file-icon {
  height: 20px;
  width: 20px;
  color: #ccc;
  margin-left: auto;
}

.empty-icon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.task-2-card:first-child {
  // margin-top: 10px;
}

.task-2-card {
  z-index: 101;
  display: flex;
  width: 100%;
  // background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  // margin-bottom: 20px;
  // margin-top: 20px;
  padding: 10px 7px 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  // border-top: 5px solid rgb(255, 81, 0);

  .task-2-progress-bars {
    display: flex;
    background-color: rgb(245, 245, 245);
    border-radius: 10px;
    height: 15px;
    margin-bottom: 5px;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;

    .task-2-awaiting {
      border: 3px solid #a0a0a0;
    }

    .task-2-complete {
      border: 3px solid #23b654;
    }

    .task-2-incomplete {
      border: 3px solid #2569a1;
    }

    .task-2-under-review {
      border: 3px solid #9ca706;
    }

    .sm-width {
      width: 24%;
      border-radius: 50px;
      margin: 5px 0 7px;
    }
  }

  .sm-width:not(:first-child) {
    margin-left: 5px;
  }

  .task-2-card-name {
    margin-bottom: 10px;
    position: relative;
    display: flex;

    .task-2-card-name-text {
      width: calc(100% - 20px);
      cursor: pointer;
      min-height: 30px;
    }

    .task-2-card-name-icon {
      display: none;
    }

    &:hover .task-2-card-name-icon {
      display: inline;
      position: absolute;
      right: 3px;
      top: 3px;
      height: 13px;
      width: 13px;
      color: #a0a0a0;
      cursor: pointer;
    }

    .task-2-card-name-textarea {
      width: calc(100% - 20px);
      border-radius: 3px;
      padding: 5px;
      line-height: 16px;
      background-color: #f7f7f7;
      color: #000000;
    }
  }

  .task-2-card-detail {
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 30px;
    position: relative;
    display: flex;

    .task-2-card-detail-text {
      width: calc(100% - 20px);
      cursor: pointer;
      min-height: 30px;
    }

    .task-2-card-detail-icon {
      display: none;
    }

    &:hover .task-2-card-detail-icon {
      display: inline;
      position: absolute;
      right: 3px;
      top: 3px;
      height: 13px;
      width: 13px;
      color: #a0a0a0;
      cursor: pointer;
    }

    .task-2-card-detail-textarea {
      width: calc(100% - 20px);
      border-radius: 3px;
      padding: 5px;
      line-height: 16px;
      background-color: #f7f7f7;
      color: #000000;
    }
  }

  .task-2-bottom {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;

    >div:nth-child(1) {
      padding-left: 30px;
      @include avatar-layout(30px, 30px, -22px, 22px);

      .avatar .av-alternative {
        @include avatar-h-w(30px, 30px);
        border-radius: 50%;
        background-color: #808080;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: start;
        font-size: 16px;
        padding-left: 8px;
        width: unset;
      }
    }

    >div:nth-child(2) {
      display: flex;
      align-items: center;
      color: #c3c3c3;

      >div {
        display: flex;
        font-size: 12px;
        align-items: center;

        >.task-2-icon {
          height: 15px;
        }
      }

      >div:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
}

.scroll-bar {
  @extend .sleek-scrollbar;
}

.p-cursor {
  cursor: pointer;
  margin-right: 5px;
}

.task-2-sticky {
  background-color: #ebecf0;
  align-items: center;
  justify-content: space-between;
  padding: 13px 16px;

  /*animation: fadeIn ease 0.4s;
	-webkit-animation: fadeIn ease 0.4s;
	-moz-animation: fadeIn ease 0.4s;
	-o-animation: fadeIn ease 0.4s;
	-ms-animation: fadeIn ease 0.4s;
	*/

  margin-top: -20px;
  margin-left: -10px;
  position: sticky;
  width: 300px;
  top: 0;
  z-index: 5;
  display: none;
  border-radius: 5px 5px 0px 0px;
  // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

  >.project-2-name {
    width: 250px;
    font-weight: bold;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  >.task-2-more-icon {
    // color: rgb(184, 184, 184);
  }

  >.task-2-more-icon:hover+.task-2-more-layout {
    display: flex;
  }

  >.task-2-more-layout:hover {
    display: flex;
  }

  >.task-2-more-layout {
    z-index: 1000;
    position: absolute;
    display: none;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    right: 10px;
    top: 30px;
    width: 120px;
    padding: 20px 0;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

    >span {
      padding: 5px 10px 5px 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 100;

      &:hover {
        background-color: #ebecf0;
      }

      >a {
        color: #000;
      }
    }
  }
}

.fade-in {
  animation: fadeIn ease 1.2s;
  -webkit-animation: fadeIn ease 1.2s;
  -moz-animation: fadeIn ease 1.2s;
  -o-animation: fadeIn ease 1.2s;
  -ms-animation: fadeIn ease 1.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.color-box,
.task-move-box {
  z-index: 10;
  box-shadow: 0 3.2px 7.2px 0 rgba(0, 0, 0, 0.133),
    0 0.6px 1.8px 0 rgba(0, 0, 0, 0.11);

  p {
    // width: fit-content;
    cursor: pointer;
  }

  p:hover,
  .close:hover {
    color: $fex-color;
  }
}

.color-circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
}

.project-tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: #596a73;
  color: #fff;
  text-align: left;
  border-radius: 3px;
  padding: 2px 5px;
  position: absolute;
  z-index: 1;
  top: 28px;
  left: 0px;
  opacity: 0;
  transition: opacity 0.5s, padding 0.3s;
  font-size: 12px;
}

.project-2-name:hover .project-tooltiptext {
  visibility: visible;
  opacity: 1;
  padding: 5px 10px;
}

.shadow-one {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.226);
}

.cloned-task-2-card {
  opacity: 0.6;
}

.test-p {
  +div {
    display: none !important;
  }
}

@media (max-width: 960px) {
  .task-1-layout {
    margin-left: 0;
  }
}