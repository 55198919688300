@import "./global.scss";

#mentionHolder {
  height: fit-content;
  width: fit-content;
}

#mention {
  background: #ffffff;
  z-index: 10000 !important;
  position: fixed;
  width: 21.625rem;
  height: fit-content;
  // max-height: 27.0625rem;
  bottom: 0;
  padding: 0.5rem;
  padding-right: 0;
  padding-bottom: 0.75rem;
  border-radius: 0.5rem;
  box-shadow: 0px 5px 12px 0px #B2BDD336;
  cursor: default;


  .mention-holder {}

  .search-bar {
    background: $primary-light-blue-20;
    height: 2.375rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem !important;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    margin-right: 0.5rem;

    input {
      border: none;
      background: inherit;
    }

    svg {
      width: 1.2rem !important;
      height: 1.2rem !important;
    }
  }

  .users-list {
    max-height: 25rem;
    height: fit-content;
    overflow-y: auto;
    overflow-x: hidden;
    @extend .sleek-scrollbar;

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar {
      box-shadow: none !important;
      border: none;
      background-color:
        #DCECF7 !important;
      width: 0.375rem !important;
      height: 0.375rem !important;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: none;
    }

    p {
      margin: 0;
      font-weight: 600;
      font-size: 0.875rem !important;
      margin-left: 0.5rem;
      color: $base-grey-80;
      margin-bottom: 0.25rem;
      text-transform: capitalize;
    }

    .user-holder {
      padding: 0.5rem;
      align-items: center;
      line-height: 1.24rem;


      svg.invited-icon-small {
        right: -0.6rem;
        width: 1rem !important;
        height: 1rem !important;
        right: -0.5rem !important;
      }

      .image {
        width: 2rem;

        img {
          margin: 0;

        }

        .user-image {
          border: none;
        }
      }
    }

    &:first-child {
      padding-top: 0;
    }

    .user-details {
      .name {
        font-weight: 600;
        display: block;
        color: $base-grey-100;
        font-size: 0.875rem !important;
      }

      .user-name {
        color: $base-grey-40;
        font-size: 0.75rem !important;
      }


    }

  }
}