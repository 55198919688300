@import "./global.scss";

form {
  ::placeholder {
    color: #677888;
    font-size: 0.875rem;
  }

  .form-group {
    margin-bottom: 1.5rem !important;
    position: relative;
  }

  label {
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 100%;
  }

  .form-check-label {
    margin: 0;
  }

  .form-control {
    padding: 0.75rem;
    border: 1px solid #A7B9CB;
    font-weight: 400;
    height: auto;
    border-radius: 0.5rem;
  }

  textarea {
    height: 7.25rem;
    resize: none;
  }

  select {

    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-image: url('');
    background-position: 100% center;
    background-repeat: no-repeat;
    outline: none;
  }

  .date-duration {
    width: 100%;
    justify-content: space-between;

    .starting-date {
      // width: 15.9375rem;
      width: 12.8125rem;
      position: relative;
    }

    .set-duration,
    .set-color {
      width: 16rem;
    }

    .duration {
      width: 5.8125rem;
    }

    .month {
      width: 9.6875rem;
    }
  }

  .date-time {
    background-color: red;
  }

  .form-icon {
    position: absolute;
    top: 2.8rem;
    right: 0.8rem;
    pointer-events: none;
    cursor: pointer;
  }

  .chevron-down {
    position: absolute;
    right: 0.8rem;
    top: 1.2rem;
    color: #97A3B0;
    pointer-events: none;
  }

  .checkbox {
    font-size: 0.875rem;
    color: #97A3B0;
  }

  .check-form {
    padding: 0.5rem 0 0.5rem 0;

  }
}

.form-progress {
  width: 35%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  span {
    font-size: 1rem;
    font-weight: 600;
  }

  >div {
    position: relative;
    //margin-top: 0.6rem;
    //margin-bottom: 0.6rem;

    .divider {
      position: absolute;
      width: 100%;
      border: 2px solid #F2F7FC;
      top: 50%;
      z-index: 1;
    }
  }

  svg {
    z-index: 2;
    pointer-events: fill;
  }
}

.task-modal {
  width: 36.4375rem !important;
  // height: 42rem !important;
  height: 36.125rem !important;
  max-width: none !important;
  max-height: none !important;

  .modal-content {
    border: none;
    height: 36.125rem !important;
  }

  .modal-header {
    border-bottom: none;
    align-items: center;
    padding: 1rem 1rem 1.5rem 1.5rem;

    .modal-title,
    h4 {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 100%;

    }
  }

  .modal-body {
    padding: 1.5rem;
    padding-top: 0.5rem;
    @extend form;

    form {
      textarea {
        resize: none;
      }

    }

    #set-color {
      width: 16rem !important;
    }
  }

  .modal-footer {
    height: 6.125rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem 1.5rem 1rem 1.5rem;
    border: none;

    font-weight: 600;

    * {
      margin: 0;
    }

    .btn {
      padding: 0.875rem 1.75rem;
      font-weight: 600;
      font-size: 0.875rem;
      min-width: 7.375rem;
    }

    .form-progress {
      //width: 6.5rem;
    }

    .btn-primary {}

    .btn-outline-primary {}
  }
}

.assign-modal {
  width: 36.4375rem !important;
  height: 41.75rem !important;
  max-width: none !important;
  max-height: none !important;

  .modal-content {
    border: none;
  }

  .modal-header {
    border-bottom: none;
    align-items: center;
    padding: 1rem 1rem 1.5rem 1.5rem;

    .modal-title,
    h4 {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 100%;

    }
  }


  .modal-body {
    padding: 0;

    .search {
      padding: 0 1.5rem 1.375rem 1.5rem;

      .search-box {
        // width: 33.4375rem;
        width: 100%;
        height: 3.125rem;
        border: 1px solid #A7B9CB;
        padding: 0.75rem;
        border-radius: 0.5rem;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          border: none;
          height: 95%;
          width: 80%;
          font-size: 0.875rem !important;
        }

        .search-icon {
          color: #788898;
        }
      }
    }

    .user-wrapper {
      height: 31.8rem;

      .action {
        display: grid;
        grid-template-columns: 2.5rem auto;
        padding-right: 1.6rem;
        padding-left: 1.6rem;
        padding-bottom: 1.625rem;
      }

      .user-list {
        // width: 34.4375rem;
        height: 22.625rem;
        // 34.4375
        margin-right: 0.5rem;
        padding-left: 1.6rem;
        overflow-y: auto;
        overflow-x: hidden;

        @extend .sleek-scrollbar;
        @extend .white-scroll;

        .user {
          display: grid;
          grid-gap: 0;
          grid-template-columns: 2.5rem 4rem 19.25rem auto;
          width: 100%;
          align-items: center;
          margin-bottom: 1rem;

          .grid-item {
            font-size: 0.875rem !important;

            svg {
              * {
                pointer-events: all
              }
            }



            .checked {
              // color: $primary;
            }

            .not-checked {
              color: #677888;
            }

            img {
              border-radius: 50%;
              width: 3rem;
              height: 3rem;
            }

            .name {
              color: #102E51;
              font-weight: 600;
              line-height: 160%;
              font-size: 0.875rem !important;
            }

            .email {
              color: #97A3B0;
              line-height: 160%;
              font-size: 0.875rem !important;
            }

            .is-leader {
              width: 6.6875rem;
              height: 2.375rem;
              background: #F2F7FC;
              display: block;
              border-radius: 100px;
              padding: 0.5rem 1rem;
              padding-right: 0;
              cursor: pointer;

              * {
                display: block;
                line-height: 160%;
              }

              display: flex;
              align-items:center;

              :nth-child(1) {
                font-size: 0.875rem !important;
                font-weight: 500;
                margin-right: 9px;
              }

              :nth-child(2) {
                color: #788898;
                // font-size: 0.6rem !important;
                margin-top: 1px;
              }
            }

            .not-leader {
              color: $primary;
              font-size: 0.875rem !important;
              padding-left: 0.5rem;
              cursor: pointer;
            }
          }
        }
      }

    }


  }

  .modal-footer {
    height: 6.125rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem 1.5rem 1rem 1.5rem;

    * {
      margin: 0;
    }

    .form-progress {
      // width: 6.5rem;
    }

    .btn {
      padding: 0.875rem 1.75rem;
      font-weight: 600;
      font-size: 0.875rem;
      min-width: 7.375rem;
    }

    .btn-primary {}

    .btn-outline-primary {}
  }

}

.todo-modal {
  width: 36.4375rem !important;
  max-width: 100vw !important;
  font-size: 0.875rem !important;

  .modal-header {
    border-bottom: none;
    align-items: center;
    padding: 1rem 1rem 1.5rem 1.5rem;

    .modal-title,
    h4 {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 100%;

    }
  }

  table {
    tr {
      td:first-of-type {
        width: 6.5rem;
        color: #4B5458;
      }

      td {
        padding: 0.5rem 0;
      }

      .assigned-users {
        display: flex;

        .profile-image-holder {
          margin-left: -0.8rem;
          // border: 1px solid #F2F7FC;
        }

      }
    }
  }

  .modal-footer {
    justify-content: space-between;

    .btn {
      padding: 0.875rem 0rem;
      font-weight: 600;
      font-size: 0.875rem;
      min-width: 7.375rem;
    }
  }
}

@media only screen and (max-width: 960px) {
  form {
    .date-duration {
      .starting-date {
        width: 12rem;
      }
    }
  }

  .task-modal,
  .assign-modal {
    width: 96vw !important;

    .modal-content {
      border: none;
    }

    .modal-footer {
      height: auto;
      position: relative;

      .form-progress {
        margin-bottom: 1rem;
      }
    }
  }

  .task-modal {
    .modal-content {
      height: fit-content !important;
    }

  }
}