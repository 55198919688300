@import "./global.scss";


.btn-warning {
  background: $warning;
  border: 1px solid $warning;
}

.btn-danger {
  background: $danger;
  border: 1px solid $warning;
}

.btn-custom-success {
  background-color: $success;
  color: #fff;
}

.text-lighter {
  color: $lighter;
}

.board-section {
  background-color: #E8F0F6;
  background-repeat: no-repeat;
  // height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

main {
  color: #111111;
  position: relative;
  z-index: 1;
  // padding: 0 32px 0 32px;
  // width: 95%;
  // width: 86rem;
  width: 100%;
  margin: auto;

  // margin-top: -3.875rem;
  // margin-top: -4.1rem;


  .nav-continued {
    width: 100%;
    height: 4.0625rem;
    background-color: #012E54;
    z-index: -1;
    top: -2px;
    right: 0;
  }


  .toolbar {
    // width: 1377px;
    // width: 86rem;
    justify-content: space-between;
    width: calc(100vw - 4rem);
    margin: auto;
    box-shadow: 0px 8px 1rem 0px #bdc9d728;
    // height: 6rem;
    //margin-top: -50%;
    border-radius: 6px;
    margin-bottom: 1rem;
    padding: 1rem 1.5rem;
    height: 5.9rem;
    position: relative;

    >div {
      height: 100%;
    }

    .actions {
      width: 29.5rem;
      padding-right: 1.5rem;
      padding-top: 0.6rem;
      z-index: 2;
      background-color: $base-white;
      flex-grow: 1;

      .project-select {
        width: 13.125rem;
        font-weight: 700;
        font-size: 1rem;
        border: none;
        margin-left: -4px;
        background: #ffffff;
      }

      .date-created {
        font-weight: 400;
        color: $base-grey-60;
        font-size: 0.875rem !important;
        line-height: 22px;
      }

      .buttons,
      .settings {
        display: flex;
        align-items: center;

        .btn {
          padding: 9px 1rem;
          border-radius: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 0.4rem 0 0.4rem;
        }

        .button-light-custom {
          background: #F2F7FC;
          // color: #788898;
          color: #4B5458;
          width: 2.75rem;
          height: 2.75rem;
          padding: 0;
          font-size: 1rem;
          border: 1px solid #F2F7FC;



          &.active {
            color: $primary;
            border: 1px solid $primary;
          }

        }

        .btn-outline-custom-primary {
          background: #fff;
          color: $primary;
          border: 1px solid $primary;
          font-size: 1rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          width: 5.9rem;

          &:focus,
          &:hover {
            background: $primary;
            color: #fff;
          }

          svg {
            margin-right: 0.25rem;
          }
        }

        svg {
          height: 1.2rem;
          width: 1.3rem;
        }

        .menu {
          // width: 10.25rem;
          // height: 10.875rem;
          padding: 0.25rem;
          width: 17.8125rem;
          background: #fff;
          color: #323232;
          border-radius: 2px;
          font-style: meduim;
          line-height: 22px;
          box-shadow: 0px 8px 1rem 0px #bdc9d728;
          z-index: 20;
          transform: translate(-60px, 50px) !important;
          padding: 0rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 1.5rem;

          &.project-dropdown-menu {
            width: 10.25rem;
            height: fit-content;
          }


          .dropdown-item {
            font-size: 1rem;
            font-weight: 500;
            padding: 0.6rem 1rem;
            display: flex;
            align-items: center;
            width: 100%;
            z-index: 1;

            svg {
              color: $light;
              margin-right: 0.5rem;
            }

            .btn {
              svg {
                color: inherit;

              }
            }
          }

        }

      }
    }

    .members {
      width: 17.3125rem !important;
      padding: 0 1.5rem 0 1.5rem;
      z-index: 2;
      background-color: $base-white;

      .desc {
        font-weight: 600;
        font-size: 1rem;
      }

      img,
      .user-image {
        border-radius: 50%;
        border: 2px solid #fff;
        height: 2.5rem;
        width: 2.5rem;
      }

      .profile-image-holder {
        margin-right: -11px;
      }




      svg {
        // width: 2rem;
      }
    }

    .project-progress {
      // height: 37.7rem;
      width: 28.4375rem;
      padding-left: 1.5rem;
      z-index: 1;


      >div {
        width: 14.3125rem;
      }


      .progress {
        height: 6px;
        width: 100%;
        background: #CED8E1;


        .progress-bar {
          background-color: #0ACCA9;
        }
      }

      .content {
        padding-left: 1.125rem;
        width: 10.25rem;


        &:first-child {}

        &:nth-child(3) {
          // padding-bottom: 0.8rem;
          margin-top: -0.6rem;
        }

        span {
          color: #788898;
          font-size: 0.875rem !important;
          font-weight: 500;
          line-height: 1.0625rem;
          white-space: nowrap;
          display: flex;
          align-items: center;


          svg {
            display: inline;
            width: 14px;
            height: 14px;
            // margin-right: 0.6rem;
          }
        }
      }



    }


    .settings {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.75rem;
      padding-left: 2rem;

      .btn {
        padding: 9px 1rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-left: 1.5rem;
        // margin: 0 0.4rem 0 0.4rem;
      }

      .button-light-custom {
        background: #F2F7FC;
        color: #454E53;
        width: 2.75rem;
        height: 2.75rem;
        padding: 0;
        font-size: 1rem;
        border: 1px solid #F2F7FC
      }

      .btn-outline-custom-primary {
        background: #fff;
        color: $primary;
        border: 1px solid $primary;
        font-size: 1rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        width: 5.9rem;

        &:focus,
        &:hover {
          background: $primary;
          color: #fff;
        }

        svg {
          margin-right: 0.25rem;
        }
      }

      svg {
        height: 1.2rem;
        width: 1.3rem;
        color: #454E53;
      }

      .label {
        margin-bottom: 0;
        justify-content: space-between !important;
        overflow: hidden !important;
        transition: all 0.2s;
        transition-timing-function: ease;
        position: relative;

        input {
          background-color: inherit;
          border: inherit;
          width: 25.4375rem;
          margin-left: 2rem;
        }
      }

    }

    .progress-wrapper {
      display: flex;
      width: 38rem;
      overflow: hidden;
      justify-content: flex-end;
    }

    &.show-search {
      .progress-wrapper {
        position: relative;
      }

      .project-progress {
        // display: none !important;
        margin-right: 1rem;
      }

      .settings {
        .label {
          width: 31.9375rem;
          padding-left: 1rem;
          padding-right: 1rem;
        }

      }
    }

    &.hide-search {
      .settings {
        label {

          input,
          .close-icon {
            display: none;
          }
        }

      }
    }
  }



  [data-rbd-droppable-id="all-columns"] {
    // height: 41.2rem;
    display: flex;
    background: transparent;
    // height: calc(100vh - 11.7rem);
    height: calc(var(--vh, 1vh) * 100 - 11.7rem);
    width: calc(100%);
    overflow: hidden;
    overflow-x: auto;
    @extend .sleek-scrollbar;


    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar {
      box-shadow: none !important;
      border: none;
      background-color: #D8E6F5 !important;
      width: 6px !important;
      height: 6px !important;
      padding-bottom: 0.5rem !important;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: none;
      background-color: #A7B9CB;
    }

    >div {
      &:first-child {
        margin-left: 2rem;
      }

      &:last-child {
        margin-right: 2rem;
      }
    }


    .stage {
      width: 20.5rem;
      min-width: 20.5rem;
      // height: fit-content;
      // max-height: 42rem;
      display: flex;
      flex-direction: column;
      max-height: 98.5%;
      // background: #F6FAFD;
      background: #F3F5F7;
      box-shadow: 0px 8px 1rem 0px #6778881A;
      margin-right: 1.35rem;
      border-radius: 0.375rem;

      .stage-header {
        padding: 1rem 1.4rem;
        font-weight: 700;
        font-size: 1rem;
        line-height: 140%;
        padding-bottom: 0;

        .stage-title {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .toggle-btn {
          border: none;
          background: inherit;
          color: $base-grey-60;


          &:focus,
          &:active {
            // background-color: #e5e9ec;
          }

        }

        input {
          font-weight: inherit;
          // border: 1px solid #A7B9CB;
          border: none;
          height: auto;
          margin-left: -2px;
          margin-top: -1px;
          // border-radius: 0.5rem;
          //padding: 1rem 0 1rem 0;
        }

        .list-menu {
          width: 9rem;
          background: #fff;
          z-index: 2;
          box-shadow: 0px 4px 1rem 0px #B8C5C540;
          padding: 0.25rem;

          .dropdown-item {
            font-size: 0.875rem;
            font-weight: 500;
            padding: 0.6rem 0.9rem;
            display: flex;
            align-items: center;
            width: 100%;

            svg {
              color: $light;
              font-size: 0.875rem !important;
              margin-right: 4px;
              width: 1.125rem;

            }
          }
        }
      }

      .stage-body {
        overflow-y: auto;
        overflow-x: hidden;
        // overflow-y: overlay;
        // min-height: fit-content;
        // max-height: 34rem;
        margin: 0 0.5rem 0 0.5rem;
        border-radius: 0.375rem;
        // background: #F6FAFD;
        // background: #F3F5F7;
        @extend .sleek-scrollbar;

        margin-top: 1rem;
        // z-index: 1;

        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar {
          box-shadow: none !important;
          border: none;
          background-color: #DCECF7 !important;
          width: 0.375rem !important;
          height: 0.375rem !important;
        }

        &::-webkit-scrollbar-thumb {
          box-shadow: none;
        }

        >[data-rbd-draggable-id] {
          margin: 0 0.5rem 0.5rem 0.5rem;
          margin-bottom: 0.5rem;
        }

        .stage-card {
          border-radius: 0.375rem;
          // margin-bottom: 0.5rem;
          background: #fff;
          // box-shadow: 0px 0px 8px 0px #5C758C2E;
          // box-shadow: 0 1px 0 rgba(9, 30, 66, .25);

          border: 1px solid #CED8E1;
          overflow: hidden;



          .stage-image {
            max-height: 12rem;
            width: 100%;
            overflow: hidden;

            /*   padding: 1rem;
            padding-bottom: 0;
            height: 10rem;
 */


            img {
              border-top-left-radius: 0.375rem;
              border-top-left-radius: 0.375rem;

              /*    width: 100%;
              height: 100%;
              object-fit: cover; */
            }

            .image-holder {
              background: $light-blue-2;
              // border-radius: 0.25rem;
            }

            &:first-child {
              // min-height: 12rem;
              //  background: $light-blue-2;
            }
          }

          .stage-content {
            padding: 1rem;

            .title {
              font-weight: 500;
              font-size: 1rem;
              line-height: 1.19rem;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .toggle-btn {
              border: none;
              padding: 0 5px 0 5px;

              &:focus,
              &:active {
                // background-color: #e5e9ec !important;
              }
            }

            .invited-icon {
              width: 1rem !important;
              height: 1rem !important;
              right: -0.5rem !important;
              position: absolute;
            }

            .task-menu {
              z-index: 10;
              background: #fff;
              // width: 8.5rem;
              // height: 108px;
              box-shadow: 0px 4px 1rem 0px #B8C5C540;
              position: fixed !important;
              padding: 0.25rem;


              .dropdown-item {
                font-size: 0.875rem !important;
                color: #323232;
                font-weight: 500;
                padding: 7px 14px;
                display: flex;
                align-items: center;

                svg {
                  color: $light;
                  font-size: 0.875rem !important;
                  margin-right: 4px;
                }
              }
            }

            p {
              color: #788898;
              padding: 0.4rem 0 0.56rem 0;
              line-height: 160%;
              font-size: 0.875rem !important;
            }
          }

          .members {
            z-index: 1;

            img,
            .add-user,
            .user-image {
              height: 2rem !important;
              width: 2rem !important;
              min-width: 2rem !important;
              border-radius: 50%;
              border: 2px solid #fff;
              // z-index: 2;
            }

            .add-user {
              border: none;
            }

            .profile-image-holder {
              margin-right: -0.6rem;
            }
          }




          .filler {
            flex-grow: 1;
            height: 1.5rem;
          }

          button {
            padding: 0.375rem 0.5rem;
            display: flex;
            align-items: center;
            color: #fff;
            border: 1.5px solid #FFFFFF;


            span {
              font-size: 0.75rem !important;
            }

          }

          .btn-danger {
            min-width: 2.7rem;
            height: 1.7rem;
            border: none;
          }

          .btn-date {
            width: 4.8125rem;
            height: 1.75rem;
            justify-content: space-between;
            white-space: nowrap;
            border-radius: 0.25rem;

            svg {
              // margin-right: 0.45625rem;
            }
          }

          .btn-date.btn-outline-secondary {
            color: #788898;
            border: 1px solid #788898;

            &:hover {
              background: initial;
            }
          }

          svg {
            /*height: 1rem;
            width: 1rem;
            */
            width: 1.125rem;
          }


        }
      }

      .stage-footer {
        margin: 0.5rem;
        background: inherit;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-size: 0.875rem;
          color: $base-grey-60;
          border: 1px dashed #DEE3EA;
          border-radius: 0.5rem;
          font-weight: 600;

          svg {
            margin-right: 5px;
            width: 7%;
            // height: auto;
          }
        }
      }
    }

    &:first-child {
      .stage {
        background: red;
      }
    }

    .add-new-list {
      width: 20.4rem;
      min-width: 20.4rem;
      margin: 0;
      background: inherit;

      .open-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 1rem;
        color: $primary;
        border: 1px solid $primary;
        // border: 1px dashed #DEE3EA;
        background: #D8E6F5;
        border-radius: 6px;
        font-weight: 600;

        &:hover {
          background-color: darken($color: #D8E6F5, $amount: 5);
        }

        svg {
          margin-right: 5px;
        }
      }

    }


    .one {
      border-top: 4px solid #FE5B52;
    }

    .two {
      border-top: 4px solid #3F96D1;
    }

    .three {
      border-top: 4px solid #FFB147;
    }

    .four {
      border-top: 4px solid #0ACCA9;
    }

    :last-child {
      // margin-right: 0;
    }
  }

}


.onboard {
  position: fixed;
  bottom: 0;
  z-index: 1000000;
  background: #ffffff;
  width: 23.5625rem;
  height: 32.5rem;
  padding: 1.5rem;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 0.25rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .onboard-header {
    display: flex;
    justify-content: space-between;
    text-align: start;

    .page {
      color: #454E53;
    }

    .skip {
      color: #3F96D1;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .image {
    margin-top: 2rem;
  }

  .title {
    margin-top: 2rem;
    color: #102E51;
    font-size: 1.25rem !important;
    margin-bottom: 0.75rem;
    line-height: 100%;
    font-weight: 600;
  }

  .desc {
    color: #5D696F;
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 160%;
  }

  button {
    padding: 0.875rem 1.75rem;
    background: #3F96D1;
    color: #ffffff;
    border: none;
    margin-top: 2rem;
    border-radius: 0.5rem;
  }

  button.next {
    margin-right: 0.75rem;
    background: #ffffff;
    color: #3F96D1;
    border: 1px solid #3F96D1;
  }
}

#project-selector {
  width: 31.0625rem;
  // height: 21.625rem;
  height: fit-content;
  position: fixed;
  z-index: 10000;
  max-height: calc(var(--vh, 1vh) * 100 - 11.7rem);
  background: $base-white;
  left: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0px 5px 12px 0px #B2BDD336;
  padding: 1.5rem 0 1.5rem 0.25rem;
  transition: display 1s;
  transition-timing-function: ease;
  margin-top: -0.75rem;

  .search {
    background-color: #F2F7FC;
    border-radius: 0.5rem;
    height: 2.5rem;

    input {
      background-color: inherit;
    }
  }

  >div {
    overflow-y: auto;
    max-height: 21.625rem;
    margin-right: 0.25rem;
    @extend .sleek-scrollbar;
    @extend .white-scroll;
    overflow: overlay;
  }

  label {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0;
    padding: 0.36rem 0;
    padding-left: 1.25rem;
    cursor: pointer;
    border-radius: 0.25rem;

    &:hover {
      background-color: $primary-light-blue-20;
    }

    .image-holder {
      width: 3.125rem;
      height: 3.125rem;
      background-color: #F2F7FC;
      border-radius: 0.25rem;
    }


    p {
      margin: 0;
      line-height: 1.4rem;
    }

    .project-name {
      font-weight: 600;
      font-size: 1rem;
      width: 22rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .created-at {
      font-size: 0.75rem !important;
      color: #677888;

    }
  }

}


#search-results {
  width: 37.1875rem;
  height: fit-content;
  max-height: calc(var(--vh, 1vh) * 100 - 11.7rem);
  background: $base-white;
  position: fixed;
  right: 2rem;
  // left: 0;
  z-index: 10000;
  margin-top: -0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0px 5px 12px 0px #B2BDD336;
  padding: 1.5rem;
  padding-right: 0.5rem;
  overflow: hidden;

  .info-text {
    font-weight: 500;
    font-size: 1rem;
    color: $base-grey-60;

    span {
      color: $base-grey-100;
    }
  }

  .all-search-holder {
    max-height: calc(var(--vh, 1vh) * 100 - 15rem);
    padding-bottom: 0.5rem;
    @extend .sleek-scrollbar;
    @extend .white-scroll;
  }

  .tasks {
    .content {
      width: 18.625rem;
      overflow: hidden;
    }
  }

  .projects,
  .tasks {
    p {
      margin: 0;
      line-height: 160%;
    }

    cursor: pointer;
    padding-right: 1rem;

    .title {
      font-size: 1rem !important;
      font-weight: 500;
      margin-bottom: 1.5rem;
      color: $base-grey-60;
    }

    .item {
      gap: 1rem;
      align-items: center;
      margin-left: 2px;
    }

    .image-holder {
      background: #F2F7FC;
      width: 5.5rem;
      height: 5.5rem;
      border-radius: 0.3125rem;
    }

    .project-title {
      font-weight: 600;
      font-size: 1.25rem !important;
      margin-bottom: 0.5rem;

      width: 25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .created-at {
      font-size: 1rem !important;
      margin: 0;
      color: $base-grey-60;

      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .projects {
    .item {
      margin-bottom: 0.75rem;
    }
  }

  .tasks {
    margin-top: 1.5rem;

    .item {
      margin-bottom: 0.75rem;
    }

    .image-holder {
      width: 10.75rem;
      height: 5.5rem;
    }

    .no-image {
      box-shadow: 0px 0px 4.602006435394287px 0px #5C758C2E;
      padding: 0.575rem;
    }

    .holder-title {
      font-size: 0.625rem !important;
      font-weight: 500;
      margin-bottom: 0.25rem;
    }

    .holder-content {
      font-size: 0.5rem;
      color: $base-grey-80
    }

    .btn-t-date {
      width: 2.75rem !important;
      height: 1.0625rem;
      font-size: 0.375rem !important;
      display: flex;
      align-items: center;
      padding: 0;
      border-radius: 2px;
      justify-content: center;
      margin-top: 0.875rem;

      svg {
        width: 0.375rem;
      }
    }
  }

  .no-search-results {
    width: 27.5rem;
    margin: auto;
    font-size: 1rem;
    color: $base-grey-60;
    font-weight: 500;
  }


}

.move-menu {
  z-index: 10;
  background: #fff;
  // width: 8.5rem;
  // height: 108px;
  padding: 0.25rem;
  box-shadow: 0px 4px 1rem 0px #B8C5C540;
  position: fixed !important;
  padding: 0.25rem;


  .dropdown-item {
    font-size: 0.875rem !important;
    color: #323232;
    font-weight: 500;
    padding: 7px 14px;
    display: flex;
    align-items: center;

    svg {
      color: $light;
      font-size: 0.875rem !important;
      margin-right: 4px;
    }
  }
}

.board-settings-tooltip {
  position: fixed;
  width: 18rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  top: 10.5rem;
  right: 1.5rem;
  background-color: $primary;
  color: #fff;
  z-index: 100000;
  border-radius: 0.5rem;
  min-height: 3rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    right: 2.25rem;
    top: -0.8rem;
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid $primary;
    clear: both;
  }


  svg {
    color: #fff;
  }

  button {
    border-radius: 50%;


  }
}

@media only screen and (max-width: 960px) {
  main {
    .nav-continued {
      display: none;
    }

    .project-slider {
      width: 15.9375rem;
      height: calc(var(--vh, 1vh) * 100);
      z-index: 100;
      position: absolute;
      top: 0;
      transition: 0.5s;

      &.show {
        margin-left: 0rem;
      }

      .content {
        display: block;
        position: inherit;
        width: inherit;
        height: inherit;
        background: #ffffff !important;
        // z-index: 2;
        transition: 0.3s;
        overflow-y: auto;
        overflow-x: hidden;
        //

        .holder {
          height: calc(100vh - 4rem);
          overflow-y: auto;
        }

        .m-buttons {
          display: flex;
          justify-content: space-between;

          .btn {
            padding: 9px 1rem;
            border-radius: 0.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 1rem 0 0;
          }

          .button-light-custom {
            background: #F2F7FC;
            color: #788898;
            width: 2.25rem;
            height: 2.25rem;
            padding: 0;
            font-size: 1rem;
            border: 1px solid #F2F7FC;

            svg {
              width: 55%;
            }
          }

          .close-icon {
            color: #677888;
            font-size: 12px;
          }

        }

        .project-select {
          margin-top: 1.5625rem;
          width: 13.0625rem;
          font-weight: 700;
          font-size: 1rem;
          border: none;
          margin-left: -4px;
          background: #ffffff;
        }

        .date-created {
          font-weight: 400;
          color: $base-grey-60;
          font-size: 0.875rem !important;
          line-height: 22px;
        }

        .project-details-btn {
          color: #2A7BB1;
          font-weight: 600;
          font-size: 0.875rem;
          margin-top: 0.75rem;
        }

        .m-progress,
        .m-lead,
        .m-team {
          padding: 1rem;

          .title {
            margin-bottom: 0.5rem;
            font-weight: 600;
            font-size: 0.875rem !important;
          }

          .react-sweet-progress-symbol {
            color: #40D886;
            font-weight: 500;
            font-size: 0.875rem !important;
            white-space: nowrap;
          }

          .content {
            padding-left: 0.625rem;
            width: 10.25rem;

            &:first-child {}

            &:nth-child(3) {
              // padding-bottom: 0.8rem;
              margin-top: -0.6rem;
            }

            span {
              color: #788898;
              font-size: 0.875rem !important;
              font-weight: 500;
              line-height: 1.0625rem;
              white-space: nowrap;
              display: flex;
              align-items: center;


              svg {
                display: inline;
                width: 14px;
                height: 14px;
                // margin-right: 0.6rem;
              }
            }
          }

          .user-image {
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
          }
        }

        .actions {
          .share-title {
            font-weight: 500;
          }

          .btn-outline-primary {
            // color: #3F96D1 !important;
          }
        }

      }

      &.hide {
        width: 0;
        margin-left: -15.9375rem;
      }

      .overlay {
        width: calc(100vw + 15.9375rem);
        height: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
        background: rgba(14, 29, 47, 0.4) !important;
      }
    }


    .overlay {
      height: 100%;
      flex-grow: 1;
    }


    .toolbar {
      width: 100vw !important;
      padding: 1rem;
      height: 3.375rem;
      border-radius: unset;

      .progress-wrapper {
        width: 3rem;
      }



      .actions {
        padding: 0;

        &:first-child {
          // width: fit-content;
        }

        .project-select {
          width: 12.4375rem;
          margin-left: 1.125rem;
        }

        &.border-right {
          border: none !important;
        }

        .date-created {
          display: none;
        }

        .buttons {
          .btn-outline-custom-primary {
            width: 1.5rem;
            height: 1.5rem;
            padding: 0;
            background: $primary;

            svg {
              margin: 0;
              color: #ffffff;
            }
          }


        }
      }

      .settings {
        .button-light-custom {
          width: 1.5rem;
          height: 1.5rem;
          padding: 0;
          margin-left: 1.125rem;

          svg {
            margin: 0;
          }
        }
      }
    }

    [data-rbd-droppable-id="all-columns"] {
      // height: calc(100vh - 9.3rem);
      height: calc(var(--vh, 1vh) * 100 - 9.3rem);
      z-index: 1;


      >div {
        &:first-child {
          margin-left: 1rem;
        }

        &:last-child {
          margin-right: 1rem;
        }
      }

      .stage {
        margin-right: 1rem;
      }
    }
  }

  .hide-on-mobile {
    display: none !important;
  }

  .board-settings-tooltip {
    top: 8.5rem;
    right: 0rem;

    &::after {

      right: 0.875rem;
    }
  }
}