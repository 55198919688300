.widget-subtitle {
  color: #677888;
  font-size: 80%;
  font-weight: 500;
  margin-bottom: 1rem;
}

.widget-subtitle b {
  color: #000000;
}

.widget-body p {
  color: #4b5458;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0;
  margin-bottom: 1rem;
  /* width:266 
   height: 4rem; */
  white-space: pre-line;
}

.widget-title {
  font-weight: 600;
  line-height: 160%;
  font-size: 1rem;
  margin: 0 0 5px;
  word-break: break-word;
  /* max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;*/
}

.widget {
  background-color: #fff;
  border-radius: 0.25rem;
  margin-bottom: 30px;
  padding: 20px;
  position: relative;
}

.widget-subbody {
  margin-bottom: 15px;
}

.widget-subbody-header {
  /* font-family: 'CircularStd', sans-serif;
    font-size: .9375rem; */
  color: #1f1f1f;
  font-weight: 400;
  line-height: 1.5;
  box-sizing: border-box;
}

.widget-subbody-text {
  color: #8e8e8e;
  line-height: 1.5;
}

.widget-subbody-space {
  color: #8e8e8e;
  line-height: 1.5;
  min-height: 30px;
}

/*.action-icon:hover + */

.dropdown-menu {
  visibility: hidden;
  border: none;
  box-shadow: 0px 4px 1rem 0px #b8c5c540;
}

.dropdown-menu:hover {
  visibility: visible;
}

.dropdown:focus-within .dropdown-menu {
  visibility: visible;
  display: inline;
}

a {
  color: #000000;
}
