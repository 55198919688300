.view-file {
  background: #0e1d2f8a;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 16000;
  width: 100%;
}

.view-file .img {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  // padding: 0 0.5em;
  height: 100%;
  width: 90%;
  flex-direction: column;

  iframe,
  .pdf-holder {
    border-radius: inherit;
    background-color: inherit;
  }

  .pdf-holder {
    width: 100%;
    background-color: #525659;

    .pdf-loader {
      background-color: #1e6abb;
      width: 0%;
    }

    .print-btn {
      color: white;
      background-color: #323639;
    }

    .nav-input {
      color: white;
      background-color: #323639;
      text-align: center;
      border: 1px solid #525659;
      width: 2rem;
    }

    .nav-input:focus {
      border: 2px solid #525659;
    }

    .info {
      position: -webkit-sticky;
      /* Safari */
      position: sticky;
      top: 0;
      /* height: 3.5rem; */
      width: 100%;
      z-index: 1;
      background-color: #323639;
      color: white;
    }

    .holder {
      overflow: auto;
      width: calc(100%);
      height: calc(100vh - 4rem);
      margin: auto;
      padding: 0 1rem 1rem 1rem;
      margin-top: 1rem;

      .react-pdf__Page {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

.close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #f3f5f7;
  border: 1px solid #677888;
  color: #000;
  padding: 0.5rem;
  border-radius: 50%;
  z-index: 10;
}

.vue-pdf-main {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 992px) {
  .view-file .img {
    width: calc(100vw - 1rem);
  }
}