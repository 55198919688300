.sign-up-modal {
  width: 35.375rem !important;
  height: 47rem !important;
  max-width: none !important;
  max-height: none !important;
  font-family: 'DM Sans', Inter, Roboto, Arial, sans-serif !important;

  .modal-content {
    // height: inherit;
    border: none;
    border-radius: 0.5rem;

    #close-btn {
      color: #788898;
      background: #F2F7FC;
      border: none;
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;

      &:hover {
        filter: brightness(90%);
      }
    }

    .modal-body {
      padding: 2rem 3.5rem;
      padding-bottom: 2.9375rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo {
        margin-bottom: 2.5rem;
      }

      h2 {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.5rem;
        margin-bottom: 2rem;
      }

      .social-btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        gap: 1rem;

        .btn {
          flex-grow: 1;
          height: 3.625rem;
          border: 1px solid #677888;
          border-radius: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          font-size: 1rem;

          span {
            margin-left: 1rem;
          }
        }
      }

      .or {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
        color: #4B5458;

        hr {
          flex-grow: 1;
          background: #A7B9CB;
        }
      }

      form {
        width: 100%;

        .form-group {
          position: relative;
          margin-bottom: 1.5rem;



          label {
            margin-bottom: 0.5rem;
            color: #677888;
            font-weight: 700;
            line-height: 140%;
          }
        }

        .form-control {
          padding: 1.125rem;
          border: 1px solid #A7B9CB
        }

        .password-eye {
          position: absolute;
          top: 1.1875rem;
          right: 1.25rem;
          padding: 0;
          margin: 0;
        }
      }

      .other-actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
        line-height: 140%;

        label {
          display: flex;
          align-items: center;
          font-weight: 400;
        }

        a {
          color: #0BA68A;
        }
      }

      .btn.sign-in {
        margin-top: 2.375rem;
        width: 100%;
        background: #0ACCA9;
        height: 3.625rem;
        color: #ffffff;
      }

      .more-actions {
        font-weight: 400;
        margin-top: 2.5rem;
        line-height: 140%;

        a {
          font-weight: 700;
          color: #0BA68A;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .sign-up-modal {
    width: calc(100vw - 1rem) !important;

    .modal-content {
      .modal-body {
        padding: 1.5rem;
      }
    }
  }
}