.page-top-bar {
  input.form-control {
    min-height: 3rem !important;
    background-color: #F2F7FC;
    color: #677888;
    border: none;
    padding: 1rem;
    font-weight: 500;

    &:focus {
      background-color: #F2F7FC;
    }

  }
}

.performance-section {
  display: flex;
  // height: calc(100vh - 185px);
  width: 100%;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;



  .performance-progress {
    width: 120px;
  }

  >.performance-header {
    display: grid;
    grid-template-columns: 30% 10% 10% 14% 18% 18%;
    margin-bottom: 10px;

    >span {
      font-weight: bold;
    }

    >span:not(:nth-child(1)) {
      text-align: center;
    }

    >span:nth-child(1) {
      margin-left: 30px;
    }
  }

  >.perfomance-body {
    // flex: 1;
    // overflow-y: auto;
    // background-color: #fff;
    border-radius: 3px;
    height: 100%;

    >div:nth-child(even) {
      //  background-color: #fafafa;
    }

    >.performance-single-row {
      display: grid;
      grid-template-columns: 30% 10% 10% 14% 18% 18%;
      min-height: 4.25rem;
      align-items: center;
      background: #fff;
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      font-size: 0.875rem;


      >div:nth-child(1) {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 1rem;

        >img {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          margin: 0 10px 0 20px;
          font-size: 0;
          background-color: #DCECF7;
        }

        >.av-alternative {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          background-color: #DCECF7;
          color: #5D696F;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 17px;
          margin: 0 10px 0 20px;
        }
      }

      >div:not(:nth-child(1)) {
        display: flex;
        justify-content: center;
      }
    }

    .no-performance {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      flex-direction: column;
      text-align: center;


      >img {
        height: 150px;
        width: 300px;
        object-fit: contain;
      }

      >h3 {
        font-size: 17px;
        margin-top: 30px;
      }
    }
  }
}

.project-performance-section {
  >.performance-header {
    grid-template-columns: 30% 10% 14% 18% 18%;


  }

  >.perfomance-body {

    >.performance-single-row {

      grid-template-columns: 30% 10% 14% 18% 18%;
    }

  }
}

/*
input.form-control {
  background-color: #F2F7FC;
  color: #677888;
  border: none;
  padding: 1rem;
  font-weight: 500;
}*/

@media print {

  .header,
  .sd-cs,
  .hide-on-print {
    display: none !important;
  }

  .page-wrapper {
    width: 100vw;
    margin-left: 0;
  }
}

@media only screen and (max-width: 960px) {
  .performance-holder {
    width: calc(100vw - 4rem);
    overflow-x: auto;

    .performance-section {
      width: calc(100vw * 2);


    }

    .no-performance {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      flex-direction: column;
      text-align: center;


      >img {
        height: 150px;
        width: 300px;
        object-fit: contain;
      }

      >h3 {
        font-size: 17px;
        margin-top: 30px;
      }
    }


  }
}