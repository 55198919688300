@import "./global.scss";

.stage-card {
          border-radius: 0.375rem;
          // margin-bottom: 0.5rem;
          background: #fff;
          // box-shadow: 0px 0px 8px 0px #5C758C2E;
          // box-shadow: 0 1px 0 rgba(9, 30, 66, .25);

          border: 1px solid #CED8E1;
          overflow: hidden;
          padding-bottom: 15px;
          margin-bottom: 10px;



          .stage-image {
            max-height: 12rem;
            width: 100%;
            overflow: hidden;

            /*   padding: 1rem;
            padding-bottom: 0;
            height: 10rem;
 */


            img {
              border-top-left-radius: 0.375rem;
              border-top-left-radius: 0.375rem;

              /*    width: 100%;
              height: 100%;
              object-fit: cover; */
            }

            .image-holder {
              background: $light-blue-2;
              // border-radius: 0.25rem;
            }

            &:first-child {
              // min-height: 12rem;
              //  background: $light-blue-2;
            }
          }

          .stage-content {
            padding: 1rem;
            padding-bottom: 0px;
            margin-bottom: 12px;

            .title {
              font-weight: 500;
              font-size: 1rem;
              line-height: 1.19rem;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .toggle-btn {
              border: none;
              padding: 0 5px 0 5px;

              &:focus,
              &:active {
                // background-color: #e5e9ec !important;
              }
            }

            .invited-icon {
              width: 1rem !important;
              height: 1rem !important;
              right: -0.5rem !important;
              position: absolute;
            }

            .task-menu {
              z-index: 10;
              background: #fff;
              // width: 8.5rem;
              // height: 108px;
              box-shadow: 0px 4px 1rem 0px #B8C5C540;
              position: fixed !important;
              padding: 0.25rem;


              .dropdown-item {
                font-size: 0.875rem !important;
                color: #323232;
                font-weight: 500;
                padding: 7px 14px;
                display: flex;
                align-items: center;

                svg {
                  color: $light;
                  font-size: 0.875rem !important;
                  margin-right: 4px;
                }
              }
            }

            p {
              color: #788898;
              padding: 0;
              line-height: 160%;
              font-size: 0.875rem !important;
            }

            p:last-child {
              color: #788898;
              padding: 0;
              line-height: 100%;
              font-size: 0.875rem !important;
            }
          }

          .members {
            z-index: 1;

            img,
            .add-user,
            .user-image {
              height: 2rem !important;
              width: 2rem !important;
              min-width: 2rem !important;
              border-radius: 50%;
              border: 2px solid #fff;
              // z-index: 2;
            }

            .add-user {
              border: none;
            }

            .profile-image-holder {
              margin-right: -0.6rem;
            }
          }




          .filler {
            flex-grow: 1;
            height: 1.5rem;
          }

          button {
            padding: 0.375rem 0.5rem;
            display: flex;
            align-items: center;
            color: #fff;
            border: 1.5px solid #FFFFFF;


            span {
              font-size: 0.75rem !important;
            }

          }

          .btn-danger {
            min-width: 2.7rem;
            height: 1.7rem;
            border: none;
          }

          .btn-date {
            width: 4.8125rem;
            height: 1.75rem;
            justify-content: space-between;
            white-space: nowrap;
            border-radius: 0.25rem;

            svg {
              // margin-right: 0.45625rem;
            }
          }

          .btn-date.btn-outline-secondary {
            color: #788898;
            border: 1px solid #788898;

            &:hover {
              background: initial;
            }
          }

          svg {
            /*height: 1rem;
            width: 1rem;
            */
            width: 1.125rem;
          }


        }