@import "./global.scss";

.project-detail-content {
  width: 56.75rem;
  min-height: 41rem;
  max-width: none !important;
  max-height: none !important;

  .modal-content {
    border: none;
    min-height: 41rem;
    height: auto !important;
  }

  .btn {
    padding: 0.5rem 1rem;
    font-weight: 600;
    font-size: 0.875rem;
  }

  .modal-header {
    padding: 1rem 1rem 1rem 1.5rem;
    border-bottom: none;

    .h4 {
      font-size: 1.25rem;
      font-weight: 700 !important;
    }

    #close-btn {
      color: $base-grey-60;
      background: #F2F7FC;
      border: none;
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .modal-body {
    padding: 1.5rem;
    padding-top: 1rem;

    .project-header {
      .title {
        h1 {
          font-weight: 700;
          font-size: 1.5rem;
          line-height: 29px;
          margin-bottom: 0.5rem;
        }

        span {
          font-size: 0.875rem;
          color: #97A3B0;
        }

        :nth-child(2) {
          margin-right: 1.375rem;
          line-height: 22px;
        }
      }


    }

    .project-details {
      white-space: pre-line;

      >div {
        width: 29rem;
        font-size: 0.875rem;

        p {
          width: 26.1875rem;
          color: #231F20;
        }

        .members {
          margin-bottom: 1.75rem;

          >div {
            margin-right: 2rem;
          }

          .title {
            color: #231F20;
            font-weight: 600;
            font-size: 0.875rem;
          }

          img,
          .user-image {
            border-radius: 50%;

            border: 2px solid #FFFFFF;
          }

          .profile-image-holder {
            margin-right: -10px;

          }
        }
      }

      p {
        color: #231F20;
        font-size: 0.875rem;
        width: 100%;
        line-height: 150%;
      }

      .desc {
        // width: 27.5625rem;
        flex-grow: 1;
        display: grid;
        grid-template-columns: 52% 48%;
        // min-height: 12rem;

        div {
          // width: 13.0625rem;
          height: max-content;
          min-height: 3.25rem;
          margin-bottom: 1rem;
          line-height: 160%;
        }

        p {
          font-size: 1rem;
          color: #97A3B0;
          margin: 0;
          width: fit-content;
          max-width: fit-content;
        }

        span {
          color: #102E51;
          font-weight: 600;
          font-size: 1rem;
          display: block;
          width: 95%;
        }
      }
    }

    .project-attachments {
      padding: 1.25rem 0 1rem 0;

      h2 {
        font-size: 1rem;
        font-weight: 600;
        line-height: 100%;
      }
    }

    .project-files {

      height: 7rem;
      overflow: auto;
      overflow-x: hidden;
      @extend .sleek-scrollbar;

      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar {
        background-color: #fff !important;
        box-shadow: none;
      }



      .files {
        display: grid;
        // grid-template-columns: 13.0625rem 13.0625rem 13.0625rem 13.0625rem;
        grid-template-columns: 12.8rem 12.8rem 12.8rem 12.8rem;
        grid-gap: 0.5rem;
        padding-right: 0.5rem;


        .grid-item {
          border: 1px solid #A7B9CB;
          height: 3.25rem;
          border-radius: 0.375rem;
          padding: 0.25rem 0.5rem 0.25rem 0.958125rem;
          padding-right: 12.69px;
          position: relative;

          >div {

            .image-holder {
              margin-right: 0.708125rem;
              width: 1.833125rem;
              height: 1.833125rem;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .file-icon {
              margin-right: 0.708125rem;
              width: 1.833125rem;
            }

            .file-details {
              white-space: nowrap;

              :nth-child(1) {
                font-size: 0.875rem;
                font-weight: 600;
                color: #231F20;
              }

              :nth-child(2) {
                color: #788898;
                font-size: 0.75rem !important;
              }
            }
          }

          .close,
          .download {
            color: #788898 !important;
            // width: 0.664375rem;
            font-size: 1rem;
            margin-right: -2px;
            cursor: pointer;
          }

          .react-sweet-progress-circle {
            cursor: pointer;
          }

          .cancel {
            color: $base-grey-100;
            //font-size: 0.65rem !important;
            // cursor: pointer;
            font-weight: 500;
            white-space: nowrap;
          }

          /*.cancel {
            visibility: hidden;

            &::after {
              content: 'x';
              visibility: visible;
              margin-right: 30px;
            }
          }
          */

        }
      }
    }

  }

  .modal-content {
    border-radius: 0.5rem;
    height: 41rem;
    overflow: hidden !important;
  }

  .update-modal-footer {
    align-items: center;
    justify-content: center;
    padding-bottom: 1.5rem;
  }

  .grand-btn {
    padding: 0.875rem 1.75rem;
    background: $primary;
    font-size: 1rem !important;
  }

}

@media only screen and (max-width: 960px) {
  .project-detail-content {
    width: 96vw;

    .modal-body {

      .project-details {
        >div {
          width: 100%;
          border: none !important;
          padding: 0 !important;

          p {
            width: 100%;
          }
        }

      }

      .project-files {
        overflow: auto;
        height: fit-content;
      }

    }
  }
}