$fex-color: #2569a1;

.modal-assign-task {
	width: 40% !important;

	.assign-task-content {
		height: 500px;
		padding: 0 10px;
	}

	.m-h-between {
		display: flex !important;
		justify-content: space-between;
		align-items: center;
		height: 45px;
		padding: 0 30px;
		background-color: #0a6db9;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
		color: #fff;
		font-size: 1.25rem;
	}

	.spec-input {
		height: 35px !important;
	}

	.mg-bottom-20 {
		margin-bottom: 20px;
	}
}

.users-list {
	height: 320px;
	overflow-y: auto;

	.user-info {
		display: grid;
		grid-template-columns: 15% 80% 5%;
		align-items: center;
		padding: 5px 20px;
		cursor: default;

		&:hover {
			background-color: #f4f4f4;

			>.user-init {
				background-color: #fff;
			}
		}

		>.user-desc {
			height: 40px;
			width: 40px;
			border-radius: 50%;
		}

		>.user-init {
			background-color: #f4f4f4;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 16px;
		}

		>input[type='checkbox'] {
			height: 15px;
			width: 15px;
		}
	}

	.none-found {
		text-align: center;
		margin-top: 30px;
	}
}

.modal-add-task {
	width: 40% !important;

	.add-task-content {
		height: 550px;
	}

	.task-detail {
		height: 50px;
		resize: none !important;
	}

	.w-100 {
		input {
			width: 100% !important;
			background-color: #f2f2f2;
		}
	}

	.dv-ts-details {
		margin-bottom: 110px;
	}

	.add-task-controls {
		span:not(.slider):not(.back):not(.sh-only) {
			width: 85px;
		}

		span:not(.slider):not(.sh-only) {
			height: 40px;
		}

		span {
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: default;
			border-radius: 3px;
		}

		.bg-fx {
			background-color: $fex-color;
			color: #fff;
		}

		.bo-fx {
			border: 1px solid $fex-color;
			color: $fex-color;
		}

		.back {
			margin-right: auto;
			margin-left: 45px;
			@extend .bg-fx;
			color: #fff;
			width: 60px;
		}

		.cancel {
			@extend .bo-fx;
		}

		.next {
			@extend .bg-fx;
			margin-left: 40px;
		}

		.in-active {
			@extend .bg-fx;
			opacity: 0.5;
			margin-left: 40px;
			pointer-events: none;
		}

		.add {
			@extend .bg-fx;
			margin-left: 40px;
		}

		.add-task-dv-switch {
			margin: auto;
			display: flex;
			flex-direction: column;
			align-items: center;

			.sh-only {
				font-size: 11px;
			}
		}
	}

	.dv-ts-users {
		.u-list {
			height: 400px;
			margin-bottom: 10px;
		}
	}
}

.send-invite {
	background-color: $fex-color;
	padding: 7px 15px;
	border-radius: 3px;
	color: #fff;
}

.spinner-layout {
	height: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.assign-task-layout {
	display: flex;
	margin-top: 30px;
	padding: 0 20px;

	>.assign-task-dv-switch {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-right: auto;

		.sh-only {
			font-size: 11px;
		}
	}

	>.assign-task-btn {
		background-color: $fex-color;
		color: #fff;
		border-radius: 3px;
		padding: 5px 30px;
		font-size: 16px;
		cursor: default;
		display: flex;
		align-items: center;
	}

	>.in-active-assign {
		opacity: 0.5;
		pointer-events: none;
	}
}