$fex-color: #2569a1;
$primary: #3F96D1;
$warning: #FACF61;
$danger: #FE5B52;
$light: #788898;
$lighter: #677888;
$success: #61DB99;
// ---
$base-grey-60: #788898;
$base-white: #FFFFFF;
$base-grey-40: #A7B9CB;
$base-grey-100: #111111;
$tersiary-red: #FE5B52;
$base-grey-80: #5D696F;
$base-grey-30: #CED8E1;
$primary-light-blue-20: #F2F7FC;
$primary-light-blue-40: #DCECF7;
$tersiary-yellow: #FACF61;
$light-blue-1: #D2E7FF;
$light-blue-2: #D8E6F5;
// ----

@function vh($quantity) {
  @return calc(var(--vh, 1vh) * $quantity);
}

* {
  //  @extend .sleek-scrollbar;
  border-color: #CED8E1;
}

body {
  overflow: hidden;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.border {
  color: #A7B9CB;
  border-color: #A7B9CB;
}

.global-backdrop,
.modal-backdrop {
  background: rgba(14, 29, 47, 0.54);

  /*&+.modal-backdrop {
    display: none;
  }*/

  &.show {
    opacity: 1;
  }

  &.max-z-index {
    z-index: 10050;

    &+.modal {
      z-index: 10052 !important;

      // @extend .sleek-scrollbar;
    }
  }

  &.max-z-index-2 {
    z-index: 10060;

    &.blur {
      backdrop-filter: blur(1px);
    }

    &+.modal {
      z-index: 10062 !important;
    }
  }
}


.picker-dialog-bg {
  z-index: 20000 !important;
}

.picker-dialog {
  z-index: 20001 !important;
}

/*.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  @extend .sleek-scrollbar;
}
*/

textarea {
  @extend .sleek-scrollbar;
  resize: none;
}

.Toastify {
  .Toastify__toast-container {
    // background: #0a6db9;
    width: 26.3125rem;
    z-index: 10080 !important;
  }

  .Toastify__toast-body {
    padding: 0;
    margin: 0;

    .content {
      .icon {
        margin-right: 1.2rem;
      }

      .title {
        font-size: 1rem;
      }

      .message {
        color: $base-grey-40;
        font-size: 0.875rem;
        word-break: break-all;
      }
    }
  }

  .Toastify__toast.Toastify__toast--success {
    background: #2C2C2C;
    margin: 0;
    padding: 1rem 1.1875rem;
    min-height: auto;
    width: 26.3125rem;
    margin-bottom: 5px;
    border-radius: 0.375rem;
    // align-items: center;
  }
}

.rdtPicker {
  right: 0 !important;
  // top: 50px !important;
  top: -272px !important;
  width: 100% !important;
}

.btn-outline-primary {
  color: $primary;
  border-color: $primary;

  &:hover,
  &:active,
  &:visited {
    background: $primary;
    color: #fff;
  }
}

.white-scroll {

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none;
    width: 0.375rem !important;
    height: 0.375rem !important;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: none;
  }
}

.sleek-scrollbar {
  /*scrollbar-color: #A7B9CB #D8E6F5;
  scrollbar-width: thin;
  scrollbar-highlight-color: red;
  */
  z-index: inherit;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px #ddd;
    box-shadow: inset 0 0 4px #ddd;
    border-radius: 50px;
    background-color: #D8E6F5;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #A7B9CB;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    -webkit-box-shadow: inset 0 0 4px #ddd;
    box-shadow: inset 0 0 4px #ddd;
    background-color: #A7B9CB;
  }
}

.spec-input {
  height: 30px !important;
}

.spec-btn {
  background-color: #2569a1 !important;
  color: #fff;
}

.m-h-between {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  // height: 45px;
  padding: 0 1.5rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  color: #000000;
  font-size: 1.25rem;
}

.modal-lg {
  width: 58% !important;
}

.modal-md {
  width: 45% !important;
}

.bg-img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #d6d6d6;
  display: flex;
  justify-content: center;
  align-items: center;

  :nth-child(1) {
    color: #6d6d6d;
  }
}

.r-img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  border: 1px solid #e4e4e4;
  object-fit: cover;
}

.fm-input1 {
  width: 100% !important;

  &::placeholder {
    color: #677888;
  }

  // background-color: #f2f2f2;
}

.invisible {
  visibility: hidden;
}

.unchecked-box {
  height: 15px;
  width: 15px;
  background-color: #ccc;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  .check-mark-icon {
    height: 15px;
    width: 15px;
    color: #fff;
  }
}

.btn-show-invitation {}

.add-new-email-container {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  align-items: center;
}

.dy-text {
  margin-right: 10px;
}

.em-text {
  color: blue;
}

.yes-text {
  background-color: #2569a1;
  color: #fff;
  border-radius: 3px;
  padding: 10px 20px;
  margin-top: 15px;
}

.custom-height {
  overflow-y: auto;
  height: 100vh;
}

.custom-color-picker-holder {
  width: 14rem;
  height: fit-content;
  background: #fff;
  border-radius: 6px;
  z-index: 99;
  padding: 0.5rem;
  padding-bottom: 0;
  font-weight: 500;
}

.custom-color-picker {
  // height: 10rem;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  // box-shadow: 0px 8px 1rem 0px #bdc9d728;
  margin: 0.5rem 0 0.5rem 0;
  grid-gap: 0.5rem;
  justify-content: space-around;
  // margin-left: -6px;
}

.color-picker-contents {
  width: 14rem;
  height: fit-content;
  background: #fff;
  border-radius: 6px;
  z-index: 99;
  padding: 1.5rem;
  font-weight: 500;
  display: grid;
  grid-template-columns: auto auto auto auto;
  // box-shadow: 0px 8px 1rem 0px #bdc9d728;
  margin: 0.5rem 0 0.5rem 0;
  grid-gap: 1rem;
  margin-left: -6px;
}

.select-color-box {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  // border-radius: 0.5625rem;
  border-radius: 50%;
  color: $lighter;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.user-image {
  background: $primary-light-blue-40;
  color: $base-grey-80;
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-hover {
  border: 1px solid #ffffff;

  &:hover {
    border: 1px solid #3F96D1;
    box-sizing: border-box;
    width: initial;
  }
}

.bg-dark-blue {
  background-color: #012E54 !important;
}

.text-14 {
  font-size: 0.875rem !important;
}

.text-16 {
  font-size: 1rem !important;
}

.bg-primary-light-blue-20 {
  background-color: $primary-light-blue-20;
}

.message-focus {
  animation: blink 0.85s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.new-message {
  z-index: 1000;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: $base-white;
  position: absolute;
  bottom: 5.5rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    position: absolute;
    right: 0;
    top: -5px;
  }
}

mark {
  background-color: yellow;
}

.color-box-custom-2 {
  .picker-holder {
    border: 1px solid #A7B9CB;
    border-radius: 0.5rem;
    padding: 0.5rem 0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #A7B9CB;
    width: 5.75rem;
    height: 3rem;
  }
}

.after-box-custom {
  &::after {
    position: absolute;
  }
}

.no-click {
  pointer-events: none;
}

.tool-parent {

  &:hover {
    .label-tooltip {
      opacity: 1;
      position: absolute;
    }
  }
}

.label-tooltip {
  position: fixed;
  right: 0;
  left: 0;
  max-width: 18rem;
  height: fit-content;
  top: calc(-180% - 0.59em);
  background-color: $primary;
  color: $base-white;
  border-radius: 0.25rem;
  padding: 0.5rem;
  opacity: 0;
  transition: opacity 0.35s;
  transition-timing-function: ease;


  &::after {
    content: '';
    position: absolute;
    left: 1rem;
    bottom: -0.8rem;
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid $primary;
    clear: both;
  }
}

.general-menu {
  // width: 10.25rem;
  // height: 10.875rem;
  width: fit-content;
  background: #fff;
  color: #323232;
  border-radius: 2px;
  font-style: meduim;
  line-height: 22px;
  box-shadow: 0px 8px 1rem 0px #bdc9d728;
  z-index: 20;
  transform: translate(-60px, 50px) !important;
  padding: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  // padding-bottom: 1.5rem;

  &.general-dropdown-menu {
    min-width: 10.25rem;
    height: fit-content;
  }


  .dropdown-item {
    font-size: 0.875rem !important;
    font-weight: 500;
    padding: 0.6rem 1rem;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 1;

    svg {
      color: $light;
      margin-right: 0.5rem;
    }

    .btn {
      svg {
        color: inherit;

      }
    }
  }

}

.disabled-share {
  .bg {
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .content {
    z-index: 2;
  }
}

.old-msg-btn:disabled {
  background-color: red;
}


#close-btn {
  color: #788898;
  background: #F2F7FC;
  border: none;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    filter: brightness(85%);
  }
}


img {
  text-indent: -9999px
}

.username-tag {
  cursor: pointer;
}

.invited-icon {
  width: 1rem !important;
}

.file-more {
  // color: inherit;
  border: none;
  background: inherit;
  width: 1rem;
  margin-right: 2px;

  svg {
    width: 100% !important;
    color: #788898;
  }
}

.dropup {
  //  position: absolute;
  right: 0;
  top: 0;
  // background: #ffffff;
  background: transparent;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;


  .file-more.btn-primary {
    background-color: inherit;
    color: #A7B9CB;
    border: none;
    width: 1.6rem;
    min-width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;

    svg {
      transform: scale(0.8);
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.062);
    }

    &:active {
      background-color: inherit;
    }

  }

  .menu {
    z-index: 10;
    font-size: 0.875rem !important;
    background: #ffffff;
    box-shadow: 0px 4px 1rem 0px #B8C5C540;
    padding: 0.25rem;
    border-radius: 0.25rem;


    .dropdown-item {
      padding: 0.5rem 1rem;
      font-size: 0.875rem !important;
      font-weight: 500;
      margin: 0;
      border-radius: 0.25rem;
      line-height: unset;
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }

  }
}

.file-img {
  .overlay {
    display: none;
    height: 3rem;
    width: 100%;
    position: absolute;
    top: 0;
    background: linear-gradient(180deg, #000000 -141.67%, rgba(0, 0, 0, 0) 100%);

    z-index: 1;
  }

  .image-action {
    z-index: 3;

    .file-more.btn-primary {
      background: #F2F7FC;

      &:hover {
        background-color: #F2F7FC;
      }
    }
  }

  .dropup {
    display: none;
    z-index: 3;
  }

  .show.dropup {
    display: block;
  }

  &:hover {
    .dropup {
      display: block;
    }

    .overlay {
      display: unset;
    }
  }
}

.popover {
  font-family: Inter;
}

.avatar-menu {
  // z-index: 10;
  font-family: Inter;
  font-size: 0.875rem !important;
  background: #ffffff;
  // box-shadow: 0px 4px 1rem 0px #B8C5C540;
  box-shadow: rgba(57, 73, 76, 0.35) 0px 1px 6px 0px;
  padding: 0.25rem;
  border-radius: 0.25rem;
  width: fit-content;
  border: none;
  min-width: 10rem;
  z-index: 10070;

  /* &>.arrow {
    display: none;
  } */

  .item {
    padding: 0.5rem 1rem;
    font-size: 0.875rem !important;
    font-weight: 500;
    margin: 0;
    border-radius: 0.25rem;
    line-height: unset;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;

    &:hover {
      background-color: #e9ecef;
    }
  }

}

.avatar-badge {
  width: fit-content;
  position: absolute;
  bottom: 0;
  right: -2px;
}


@media only screen and (max-width: 960px) {


  .Toastify {
    .Toastify__toast-container {
      width: 96vw;
      margin-right: .5rem !important;
      margin-left: .5rem !important;
    }

    .Toastify__toast.Toastify__toast--success {
      width: 100%;
    }
  }

  .mobile-100-vw {
    width: 100vw;
  }
}