.file-upload-div{
    height: 154px;
}
.file-preview{
    height: 120px;
    width: 110px;
    margin-bottom: 5px;
    /*padding: 5px;*/
    overflow: hidden;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 110px;
    text-align: -webkit-center;
    text-align: center;
}

.custom-upload-btn {
    width: 100px;
    border: 1px solid gray;
    color: gray;
    background-color: white;
    padding: 2px 18px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
